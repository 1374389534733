import React from 'react'
import PropTypes from 'prop-types'
import checkImg from '../../assets/koodo/check.svg'
import errorImg from '../../assets/koodo/error.svg'

const ImageType = {
  success: checkImg,
  danger: errorImg
}

const ToastColorClasses = {
  success: 'bg-success-100',
  danger: 'bg-red-700',
}

const Toast = ({type, text, className, imgSize, textClassName, testId}) => {
  const toastColorClass = ToastColorClasses[type]
  return (
    <div className={`px-6 py-3 text-lg text-white rounded-lg flex flex-row items-start md:ml-2 ${className}`} data-testid={testId}>
      <img className={`mr-3 fill-current`} alt="Toast image" width={imgSize} height={imgSize} src={ImageType[type]} />
      <p className={`${textClassName}`}>{text}</p>
    </div>
  )
}

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']),
  text: PropTypes.string.isRequired,
  testId: PropTypes.string,
}

Toast.defaultProps = {
  type: 'success',
  className: '',
  imgSize: 24
}

export default Toast