import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import Button from '@apps/components/Button'
import BodyBold from '@apps/components/Typography/BodyBold'
import { useLocation } from 'react-router'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Heading1 from '@/apps/components/Typography/Heading1'
import Heading2 from '@/apps/components/Typography/Heading2'
import Body from '@/apps/components/Typography/Body'
import { useDispatch, useSelector } from 'react-redux'
import { getBySlug, getById, getImgByTitle } from '@apps/utils/contentful'
import { navigateToPath } from '@apps/utils/navigation'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'

const ProcessFailure = () => {
  const {
    common: {
      content: { pageContent, featureFlags } = {},
      responseError,
      quote: {
        quoteInfo = {}
      } = {}
    } = {}
  } = useSelector((state) => state)
  
  const consumerActivationPaths = new ConsumerActivationPaths()

  let history = useHistory()
  if (featureFlags && featureFlags[0]?.showSplashing === true) {
    history.push(consumerActivationPaths.Splash)
  }

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region
  } = useContext(ApplicationContext)

  const dispatch = useDispatch()
  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const [errorLink, setErrorLink] = useState(false)

  const location = useLocation()
  useEffect(() => {
    if (quoteInfo?.quoteState !== 'DELIVERED') {
      setErrorLink(true)
    } else {
      setErrorLink(false)
    }

    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)

    // Add event listener for back button
    const unlisten = history.listen((location, action) => {
      if (action === 'pop' || action === 'POP') {
        if (quoteInfo?.currentState) {
          navigateToPath(quoteInfo?.currentState, consumerActivationPaths, history, language, region)
        }
      }
    })

    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = "Your work will be lost."
    }
  
    window.addEventListener('beforeunload', handleBeforeUnload)
  
    // Cleanup function
    return () => {
      unlisten()
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

  }, [history, location, region, language, quoteInfo?.quoteState])

  const activatePage = getById(pageContent, 'transactionSummaryPage')[language]
  const handleNextClick = async () => {
    updatePaymentStore({
      [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: '',
      [PAYMENT_STATE.CC_INFO.EXPIRY_DATE]: '',
      [PAYMENT_STATE.CC_INFO.SECURITY_CODE]: '',
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: '',
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: '',
    })

    history.push(consumerActivationPaths.Payment)
  }


  return (
    <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-4 justify-center items-center mt-8">

      <Heading1 className="text-primary font-bold text-center mb-5 hidden md:block">
        {getBySlug(activatePage?.shortTextFields, 'processing-failure-title')?.value}
      </Heading1>

      <Heading2 className="text-primary font-bold text-center mb-5 block md:hidden">
        {getBySlug(activatePage?.shortTextFields, 'processing-failure-title')?.value}
      </Heading2>

      <Body className="text-center">
        {getBySlug(activatePage?.shortTextFields, 'processing-failure-message')?.value}
        &nbsp;
        {responseError?.code && (
          <strong>
            {getBySlug(activatePage?.shortTextFields, 'processing-failure-error-label')?.value}
              <span className="text-primary">{responseError?.code}</span>
          </strong>
        )}
      </Body>

      <div className="w-64 h-72 flex items-center justify-center">
        <img
          className="w-full h-full object-contain"
          alt={getImgByTitle(activatePage, 'processing-failure-image')?.altText}
          src={getImgByTitle(activatePage, 'processing-failure-image')?.url}
        />
      </div>

      {errorLink ? (
        <div className="md:w-4/12 w-3/4">
          <Button
            onClick={() => handleNextClick()}
            label={getBySlug(activatePage?.shortTextFields, 'processing-failure-bottom-message')?.value}
            className="w-full my-4 font-h4"
            testId="plans-nextStep"
          />
        </div>
      ) : null}

    </div>
  )
}

export default ProcessFailure
