import React, { useContext, useEffect, useState, useMemo } from 'react'
import ValidationCard from '@apps/components/Password/ValidationCard'
import Link from '@apps/components/Link'
import Loader, { SIZE } from '@apps/components/Loader'
import RadioButton from '@apps/components/RadioButton'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import {
  PHONE_NUMBER_STATE,
  updatePhoneNumberState
} from '@apps/redux/features/PhoneNumberSlice'
import Error from '@apps/assets/error.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getById, getBySlug } from '@apps/utils/contentful'
import Sites from '@sites/index'
import BodyError from '../Typography/BodyError'

const getPhoneNumberInFormat = (value) => {
  if (!value) return '(XXX) XXX - XXXX'
  // country code
  if (value.length === 11) {
    return `(${value?.substring(1, 4)}) ${value?.substring(4, 7)} - ${value?.substring(7)}`
  }
  return `(${value?.substring(0, 3)}) ${value?.substring(3, 6)} - ${value?.substring(6)}`
}

const MAX_CYCLES = 10
const CYCLE_AMOUNT = 3

const CreateNewNumber = ({ selectedNum }) => {
  const dispatch = useDispatch()
  const { userType } = useSelector((state) => state.common)
  const { pageContent } = useSelector((state) => state.common.content)
  const phoneNumberPageState = useSelector((state) => state.phoneNumberPage)

  const [showMore, setShowMore] = useState(true)
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('')
  const [currentNumber, setCurrentNumber] = useState(selectedNum)
  const [totalNumbers, setTotalNumbers] = useState([])
  const [reservedNumbers, setReservedNumbers] = useState([])
  const [isNumberLoading, setIsNumberLoading] = useState(false)
  const [activePhoneNumbers, setActivePhoneNumbers] = useState([])
  const [cycle, setCycle] = useState(1)
  const [reservedError, setReservedError] = useState('')
  const [isSetPhoneNumberLoading, setIsSetPhoneNumberLoading] = useState(false)

  const {
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language
  } = useContext(ApplicationContext)

  const { getPhoneNumbers, setPhoneNumber } = brandServices

  const pagePrefix = userType === Sites.dealer ? 'Dealer' : 'Consumer'
  const phoneNumberPageContent = getById(pageContent, `phoneNumberPage${pagePrefix}`)[language]
  const { shortTextFields } = phoneNumberPageContent || {}
  const textContent = useMemo(() => {
    return {
      newNumber: getBySlug(shortTextFields, 'newNumber').value,
      seeMore: getBySlug(shortTextFields, 'seeMore').value,
      refresh: getBySlug(shortTextFields, 'refresh').value,
      noNumber: getBySlug(shortTextFields, 'noNumber').value,
      reservedNumber: getBySlug(shortTextFields, 'reservedNumber').value
    }
  }, [shortTextFields])
  const { newNumber, seeMore, refresh, noNumber, reservedNumber: reservedNumberError } = textContent

  const phoneNumberState = useSelector((state) => state.phoneNumberPage)

  const {
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: provinceGroupId
  } = phoneNumberState

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  const getNumberOptions = () => {
    let cycleIndex = (cycle - 1) * CYCLE_AMOUNT

    if (cycle <= MAX_CYCLES && provinceGroupId && totalNumbers.length < MAX_CYCLES * CYCLE_AMOUNT) {
      setIsNumberLoading(true)
      getPhoneNumbers(provinceGroupId, CYCLE_AMOUNT).then((response) => {
        setIsNumberLoading(false)
        if (!response) {
          setCycle(1)
          return
        }
        const newTotal = [...totalNumbers, ...response]
        setTotalNumbers(newTotal)
        setActivePhoneNumbers(newTotal.slice(cycleIndex, cycleIndex + CYCLE_AMOUNT))
      })
    } else {
      if (cycle > MAX_CYCLES) {
        setCycle(1)
        cycleIndex = 0
      }
      setActivePhoneNumbers(totalNumbers.slice(cycleIndex, cycleIndex + CYCLE_AMOUNT))
    }
  }

  const formatNumberToPort = (rawNumber) => {
    let number

    if (/^\d+$/.test(rawNumber)) {
      if (rawNumber && rawNumber.length >= 11 && rawNumber.startsWith('1')) {
        number = rawNumber
      } else if (rawNumber && rawNumber.length >= 10 && rawNumber.length <= 11) {
        number = `1${rawNumber}`
      }
    }

    return number
  }

  useEffect(() => {
    if (cycle > 1) {
      getNumberOptions()
    }
  }, [cycle])

  const getRefreshOptions = () => {
    setCycle(cycle + 1)
    setActivePhoneNumbers([])
  }

  const handleSeeMore = () => {
    if (showMore) {
      setShowMore(false)
      getNumberOptions()
    } else {
      setSelectedPhoneNumber('')
      getRefreshOptions()
    }
  }

  useEffect(() => {
    setCurrentNumber(selectedNum)
    updatePhoneNumberStore({
      [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: selectedNum,
      [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: formatNumberToPort(selectedNum?.value)
    })
  }, [selectedNum])

  useEffect(() => {
    if (selectedPhoneNumber !== '') {
      setCurrentNumber(activePhoneNumbers[selectedPhoneNumber])
      updatePhoneNumberStore({
        [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]:
          activePhoneNumbers[selectedPhoneNumber],
        [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: formatNumberToPort(
          activePhoneNumbers[selectedPhoneNumber].value
        )
      })
    }
  }, [selectedPhoneNumber])

  const handleNumberSelect = async (index) => {
    const found = activePhoneNumbers[index]
    if (!reservedNumbers.includes(found)) {
      setIsSetPhoneNumberLoading(true)
      const isReserved = await setPhoneNumber(found)
      if (!isReserved) {
        setReservedError(reservedNumberError)
        setIsSetPhoneNumberLoading(false)
        return
      }
    }
    setReservedError('')
    setIsSetPhoneNumberLoading(false)
    setReservedNumbers([...reservedNumbers, found])
    setSelectedPhoneNumber(index)
  }

  const radioButtons = activePhoneNumbers.map((number, i) => {
    return (
      <div key={number.id} className="w-full px-4 font-h1 lg:w-1/3 lg:even:mx-4 lg:p-0">
        <RadioButton
          key={number.id}
          option={getPhoneNumberInFormat(number.value)}
          selected={i === selectedPhoneNumber || number.value === currentNumber.value}
          name={number.value}
          setSelectedIndex={handleNumberSelect}
          index={i}
          customStyles="w-full"
          testId="phoneNumber-newNumber"
        />
      </div>
    )
  })

  return (
    <div className="mt-12">
      <div className="space-y-2">
        {!currentNumber ? (
          <div className="flex items-center justify-center p-2 bg-white">
            <img className="h-4 my-2" src={Error} alt="" />
            <BodyError className="ml-1 mt-0.5 leading-4 ">{noNumber}</BodyError>
          </div>
        ) : (
          <>
            {reservedError && <BodyError>{reservedError}</BodyError>}
            <ValidationCard customImageStyles="w-3/4">
              <div className="flex flex-col items-center justify-between mx-1 mb-8 lg:flex-row">
                <div className="text-sm font-bold text-unselected-text font-h1">{newNumber}</div>
                {isSetPhoneNumberLoading ? (
                  <div className="flex flex-col items-center justify-between">
                    <Loader size={SIZE.SM} loading />
                  </div>
                ) : (
                  <div
                    className="text-lg font-bold font-h1"
                    data-testid="phoneNumber-selectedNumber"
                  >
                    {getPhoneNumberInFormat(currentNumber.value)}
                  </div>
                )}
                <div className="">
                  {currentNumber && (
                    <Link
                      tabIndex="0"
                      onClick={handleSeeMore}
                      className="text-sm font-bold underline font-h1 text-primary"
                      testId="phoneNumber-seeMore"
                    >
                      {showMore ? seeMore : refresh}
                    </Link>
                  )}
                </div>
              </div>
              {isNumberLoading ? (
                <div className="flex flex-col items-center justify-between py-5 mb-5">
                  <Loader loading />
                </div>
              ) : (
                !showMore && (
                  <div
                    v-for="item in items"
                    className="flex flex-col mb-6 lg:justify-between lg:flex-row"
                  >
                    {radioButtons}
                  </div>
                )
              )}
            </ValidationCard>
          </>
        )}
      </div>
    </div>
  )
}

export default CreateNewNumber
