export const isEmpty = (val) => {
  return !(typeof val !== 'undefined' && val !== '')
}

export const isNumber = (val) => {
  return (/^\d*\.?\d*$/.test(val))
}

export const regexForNumber = /\d/;
export const regexForUpperCase = /[A-Z]/;
export const regexForSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const regexForLowerase = /[a-z]/;
export const regexForSpace = /\s/;
export const regexForSameDigits = /^([0-9])\1*$/
