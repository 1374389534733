import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useLocale from '../../../../_engine/hooks/useLocale'
import Button from '@apps/components/Button'
import CreditCardProcessing from '@apps/components/CreditCardProcessing'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { ActivationContext } from '@apps/flows/activation/contexts/ActivationContext'
import { ACCOUNT_SETUP_STATE } from '@apps/redux/features/AccountSetupSlice'
import { PHONE_NUMBER_STATE } from '@apps/redux/features/PhoneNumberSlice'
import { PaymentContext,PAYMENT_STATE, updatePaymentStates,setPaymentInformation } from '@apps/redux/features/PayAndReviewSlice'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
// import { PaymentContext, PAYMENT_STATE } from '@apps/flows/activation/contexts/PaymentContext'
import { ContentContext } from '@apps/contexts/ContentContext'

import { getCardValidationParams } from '@apps/utils/requestPayloadHelper'
import Link from '@apps/components/Link'

import RegistrationPayment from '@apps/flows/activation/services/registerPaymentHelper'

import { PAYMENT_OPTIONS, VALID_AVS_CODES } from '@apps/utils/constants'

import useSite from '@engine/hooks/useSite'
import Sites from '@/sites'
import ConsumerActivationPaths from '@/sites/consumer/activation/ConsumerActivationPaths'
import ContentService from '@services/ContentService'
import DealerActivationPaths from '@/sites/dealer/activation/DealerActivationPaths'

const ActivateSIMSection = ({
  showAllErrors = () => {},
  handleSIMActivation,
  showActivatingPopup,
  setShowActivatingPopup,
  setIsProcessingPayment,
  isProcessingPayment,
  setIActivateButtonDisabled,
  paymentGateway,
  isActivateButtonDisabled,
  setIsActivateAllProcessing,
  buttonIsLoading,
  setButtonIsLoading
}) => {
  const contextState = {
    application: useContext(ApplicationContext),
    activation: useContext(ActivationContext),
    accountSetup: useSelector((state) => state.accountSetupPage),
    phoneNumber: useSelector((state) => state.phoneNumberPage),
    plans: useSelector((state) => state.plansPage),
    payment: useSelector((state) => state.payAndReviewPage)
  }

  const { userType } = useSelector((state) => state.common)

  const {
    [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: selectedPaymentOption,
    [PAYMENT_STATE.AUTO_TOP_UPS]: autoPay
  } = contextState.payment

  

  const { [APPLICATION_STATE.SERVICES]: brandServices } = useContext(ApplicationContext)

  const { processPayment } = brandServices

  const voucherAutoPaySelected = selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER && autoPay

  const history = useHistory()
  const site = useSite()
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const dispatch = useDispatch()
  const { lang, prov } = useLocale()

  const { paymentInformation: paymentInfo } = useSelector((state) => state.payAndReviewPage)

  const [paymentParams, setPaymentParams] = useState({})
  const [isComplete, setIsComplete] = useState(false)

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const {
    activation: { error },
    payment: { activateSim },
    activatingSIMCard
  } = useContext(ContentContext)

  const activateAllHandler = async () => {


    if (site === Sites.consumer) {
      const splashConsumer = await ContentService.getSplashConsumer()


      if (splashConsumer) {
        setIsActivateAllProcessing(false)
        // setButtonIsLoading(false)
        history.push(consumerActivationPaths.Splash)
        return
      }
    } else {
      const splashDealer = await ContentService.getSplashDealer()


      if (splashDealer) {
        setIsActivateAllProcessing(false)
        // setButtonIsLoading(false)
        history.push(dealerActivationPaths.Splash)
        return
      }
    }
    if (contextState?.payment?.isComplete) {
      if (selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD || voucherAutoPaySelected) {
        const cardParams = getCardValidationParams(contextState)
        const generatedParams = await RegistrationPayment.generateParams(
          ...Object.values(cardParams)
        )
        setPaymentParams(generatedParams)
        setIsProcessingPayment(true)
        setShowActivatingPopup(false)
        setIActivateButtonDisabled(true)

        const paymentResult = await processPayment(generatedParams)
        await handleSIMActivation(paymentResult)
      } else {
        setButtonIsLoading(true)
        await handleSIMActivation()
      }
    } else {

        if(paymentGateway === 'EPS') {

          if(contextState.accountSetup[ACCOUNT_SETUP_STATE.IS_COMPLETE] &&
            contextState.phoneNumber[PHONE_NUMBER_STATE.IS_COMPLETE] &&
            contextState.plans[PLANS_STATE.PLAN].id &&
            !contextState.payment[PAYMENT_STATE.IS_COMPLETE]) {
              updatePaymentStore({
                [PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD]: null,
                [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: true
              })
          } else {
            showAllErrors()

            setIsActivateAllProcessing(false)

            setButtonIsLoading(false)

            if (userType === Sites.consumer) {
              history.push(consumerActivationPaths.ProcessFailure)
            } else if (userType === Sites.dealer) {
              history.push(dealerActivationPaths.ProcessFailure)
            }
          }

          //  setButtonIsLoading(false)

        } else {
          showAllErrors()

          setIsActivateAllProcessing(false)
           setButtonIsLoading(false)
        }
    }

  }

  useEffect(() => {
    if (contextState.plans && contextState.plans[PLANS_STATE.PLAN]) {
      setIsComplete(
        contextState.accountSetup[ACCOUNT_SETUP_STATE.IS_COMPLETE] &&
          contextState.phoneNumber[PHONE_NUMBER_STATE.IS_COMPLETE] &&
          contextState.plans[PLANS_STATE.PLAN].id &&
          contextState.payment[PAYMENT_STATE.IS_COMPLETE]
      )
    }
  })

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className="flex flex-row">
        <Link className="w-full" onClick={() => {}}>
          <Button
            disabled={isActivateButtonDisabled}
            label={activateSim}
            onClick={activateAllHandler}
            className="w-full"
            testId="payment-activateButton"
            internalLoader={contextState?.payment?.isCompleteEPS && buttonIsLoading}
            isLoading={contextState?.payment?.isCompleteEPS && buttonIsLoading}
          />
        </Link>
      </div>

      {/* {isProcessingPayment && (
        <CreditCardProcessing data={paymentParams} onComplete={handleSIMActivation} />
      )} */}
    </div>
  )
}

export default ActivateSIMSection
