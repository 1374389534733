import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import { ContentContext } from '@apps/contexts/ContentContext'
import Button from '@apps/components/Button'
import BodyBold from '@apps/components/Typography/BodyBold'
import { useLocation } from 'react-router'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Heading1 from '@/apps/components/Typography/Heading1'
import Heading2 from '@/apps/components/Typography/Heading2'
import Body from '@/apps/components/Typography/Body'
import { useSelector } from 'react-redux'
import { getBySlug, getById, getImgByTitle } from '@apps/utils/contentful'

const ProcessLoader = () => {

  const {
    common: {
      content: { pageContent, featureFlags } = {},
      quote: {
        quoteInfo = {}
      } = {}
    } = {},

    simSelectionPage: { selectSimType } = {}
  } = useSelector((state) => state)

  const consumerActivationPaths = new ConsumerActivationPaths()
  let history = useHistory()

  if (featureFlags && featureFlags[0]?.showSplashing === 'true') {
    history.push(consumerActivationPaths.Splash)
  }

  const pageRef = useRef(null)

  useEffect( () => {
    window.scrollTo(0, 0)

    if(pageRef.current) {
      const minHeight = window.innerHeight
      pageRef.current.style.minHeight = `${minHeight}px`
    }
  }, [])

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region
  } = useContext(ApplicationContext)

  const location = useLocation()
  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [])

  const activatePage = getById(pageContent, 'transactionSummaryPage')[language]

  return (
    <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-4 justify-center items-center mt-8">

      <Heading1 className="text-primary font-bold text-center mb-5 hidden md:block">
        {getBySlug(activatePage?.shortTextFields, 'processing-loader-title')?.value}
      </Heading1>

      <Heading2 className="text-primary font-bold text-center mb-5 block md:hidden">
        {getBySlug(activatePage?.shortTextFields, 'processing-loader-title')?.value}
      </Heading2>

      <Body className="md:w-3/4 text-center">
        { selectSimType?.simType?.toUpperCase() === 'ESIM'
          ? getBySlug(activatePage?.shortTextFields, 'processing-loader-esim-message')?.value
          : getBySlug(activatePage?.shortTextFields, 'processing-loader-havesim-message')?.value 
        }
      </Body>

      <div className="w-64 h-72 flex items-center justify-center">
        <img
          className="w-full h-full object-contain"
          // alt="Colorful Piñata"
          alt={getImgByTitle(activatePage, 'processing-loader-image')?.altText}
          src={getImgByTitle(activatePage, 'processing-loader-image')?.url}
        />
      </div>

      <p className="text-green-success text-sm mx-auto mb-6 font-semibold" id="testid-processing-loader-bottom-message">
      { selectSimType?.simType?.toUpperCase() === 'ESIM'
          ? getBySlug(activatePage?.shortTextFields, 'processing-loader-esim-bottom-message')?.value
          : getBySlug(activatePage?.shortTextFields, 'processing-loader-havesim-bottom-message')?.value 
      }
      </p>

    </div>
  )
}

export default ProcessLoader
