import React, { useContext, useEffect, useState, useMemo } from 'react'
import cookie from 'react-cookies'
import { Route, Switch } from 'react-router-dom'
import config from '@/config'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { ErrorBoundary } from 'react-error-boundary'

import AccountSetupPage from '@sites/shared/AccountSetupPage'
import TransactionSummaryPage from '@sites/dealer/activation/TransactionSummaryPage'
import IntroPage from '@sites/consumer/activation/IntroPage'
import SimSelectionPage from '@sites/shared/SimSelectionPage'
import NewPhoneNumberPage from '@sites/shared/PhoneNumberPage'
import PlansPage from '@sites/shared/PlansPage'
import NewPlanPage from '@sites/shared/NewPlanPage'
import PaymentSummary from '@sites/consumer/activation/PaymentSummary'
import PaymentPage from '@sites/consumer/activation/PaymentPage'

import SiteHeader from '@apps/components/SiteHeader'
import StepTracker from '@apps/components/StepTracker'
import { LANGUAGES_CODE, PAGE_NUMBER } from '@apps/utils/constants'

import { useDispatch } from 'react-redux'
import { setUserType } from '@apps/redux/features/CommonSlice'
import { updateAccountSetupState } from '@apps/redux/features/AccountSetupSlice'
import ContentProvider from '@apps/contexts/ContentContext'
import {
  ApplicationContext,
  APPLICATION_STATE, 
} from '@apps/contexts/ApplicationContext'
import useBrand from '@engine/hooks/useBrand'
import Brands from '@brands/index'
import Sites from '@sites/index'

import koodoEnglish from '@apps/flows/activation/content/koodo/en.json'
import koodoFrench from '@apps/flows/activation/content/koodo/fr.json'
import publicEnglish from '@apps/flows/activation/content/public/en.json'
import publicFrench from '@apps/flows/activation/content/public/fr.json'
import activationEnglish from '@apps/flows/activation/content/en.json'
import activationFrench from '@apps/flows/activation/content/fr.json'

import dealerEnglish from '@sites/dealer/content/en.json'
import dealerFrench from '@sites/dealer/content/fr.json'
import ContentService from 'services/ContentService'
import ErrorSection from '@apps/flows/activation/sections/ErrorSection'
import AlreadyActivated from '@/sites/dealer/activation/AlreadyActivated'
import ProcessLoader from '@sites/dealer/activation/ProcessLoader'
import ProcessFailure from '@sites/dealer/activation/ProcessFailure'
import Loader, { SIZE } from '@apps/components/Loader'

const ActivationMount = () => {
  const dispatch = useDispatch()
  const [dynamicContent, setDynamicContent] = useState(undefined)
  const [dealerSession, setDealerSession] = useState(undefined)

  const brand = useBrand()
  const {
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage,
    [APPLICATION_STATE.IDENTITY]: identity,
    [APPLICATION_STATE.IS_LANDING_LOGIC_NOT_COMPLETE]: is_landing_logic_not_complete,
  } = useContext(ApplicationContext)

  const { outletId } = identity || {}

  const koodoContent = {
    [LANGUAGES_CODE.EN]: [activationEnglish, koodoEnglish, dealerEnglish],
    [LANGUAGES_CODE.FR]: [activationFrench, koodoFrench, dealerFrench]
  }

  const publicContent = {
    [LANGUAGES_CODE.EN]: [activationEnglish, publicEnglish, dealerEnglish],
    [LANGUAGES_CODE.FR]: [activationFrench, publicFrench, dealerFrench]
  }

  let contentHierarchy

  if (brand === Brands.koodo) {
    contentHierarchy = koodoContent
  } else if (brand === Brands.public) {
    contentHierarchy = publicContent
  }

  const dealerActivationPaths = new DealerActivationPaths()

  const provinces = useMemo(() => {
    return mappedProvinceList?.map((item) => {
      const { label, value, shortName } = item
      return {
        value,
        name: label[currentLanguage],
        shortName
      }
    })
  }, [mappedProvinceList])

  const provinceCookie = cookie.load('prov')
  useEffect(() => {
    if (provinces && provinceCookie) {
      const province = provinces?.find(
        (prov) => prov.shortName?.toLowerCase() === provinceCookie.toLowerCase()
      )
      if (province) {
        dispatch(updateAccountSetupState({ province: province.value }))
      }
    }
  }, [JSON.stringify(provinces), provinceCookie])

  useEffect(() => {
    const currentPath = window.location.pathname
    if (
      currentPath !== dealerActivationPaths.Base &&
      currentPath !== dealerActivationPaths.Splash
    ) {
      window.location = dealerActivationPaths.Base
    }

    // fetch supported languages from contentful and apply to state model
    ContentService.getDealerPageContent().then((dealerContent) => {
      setDynamicContent(dealerContent)
    })
    dispatch(setUserType(Sites.dealer))
  }, [])

  useEffect(() => {
    if(outletId) {
      setDealerSession(cookie.load(config.partnerCookieId))
    }
  }, [outletId])

  if (!dynamicContent) return <></>

  if (!dealerSession) {
    return (
      <code className="text-gray-900">
        {JSON.stringify({
          error: 711,
          message: 'Session expired'
        })}
      </code>
    )
  }

  return (
    <ContentProvider
      language={currentLanguage}
      contentHierarchy={contentHierarchy}
      dynamicContent={dynamicContent}
    >
      <ErrorBoundary fallback={<ErrorSection />}>
        <SiteHeader />
        {is_landing_logic_not_complete && (
          <div className={`fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-100 overflow-hidden bg-white flex flex-col items-center justify-center ${
            is_landing_logic_not_complete === true ? 'opacity-100' : 'opacity-50'
          }`}>
            <Loader size={SIZE.MD} loading />
          </div>
          )}
        <Switch>
          <Route exact path={dealerActivationPaths.Base}>
            <IntroPage path={dealerActivationPaths.Base} />
          </Route>
          <Route path={dealerActivationPaths.AccountSetup}>
            <StepTracker activeStepIndex={PAGE_NUMBER.ACCOUNT_SETUP} />
            <AccountSetupPage />
            <PaymentSummary />
          </Route>
          <Route path={dealerActivationPaths.SimSelection}>
            <StepTracker activeStepIndex={PAGE_NUMBER.SIM_SELECTION} />
            <SimSelectionPage />
            <PaymentSummary />
          </Route>
          <Route path={dealerActivationPaths.TransactionSummary}>
            <TransactionSummaryPage />
          </Route>
          <Route path={dealerActivationPaths.AlreadyActivated}>
            <AlreadyActivated />
          </Route>
          <Route path={dealerActivationPaths.ChooseNumber}>
            <StepTracker activeStepIndex={PAGE_NUMBER.CHOOSE_NUMBER} />
            <NewPhoneNumberPage />
            <PaymentSummary />
          </Route>
          <Route path={dealerActivationPaths.ChangeNewPlan}>
            <NewPlanPage />
          </Route>
          <Route path={dealerActivationPaths.ProcessLoader}>
            <ProcessLoader />
          </Route>
          <Route path={dealerActivationPaths.ProcessFailure}>
            <ProcessFailure />
          </Route>
          <Route path={dealerActivationPaths.Plans}>
            <StepTracker activeStepIndex={PAGE_NUMBER.PLAN_SELECTION} />
            <PlansPage />
            <PaymentSummary />
          </Route>
          <Route path={dealerActivationPaths.Payment}>
            <StepTracker activeStepIndex={PAGE_NUMBER.PAYMENT} />
            <PaymentPage />
            <PaymentSummary />
          </Route>
          <Route path={dealerActivationPaths.Splash}>
            <ErrorSection />
          </Route>
          <Route path="*">
            <ErrorSection errorNum={404} />
          </Route>
        </Switch>
      </ErrorBoundary>
    </ContentProvider>
  )
}

export default ActivationMount
