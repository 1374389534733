import React, { createContext, useState } from 'react'
import cookie from 'react-cookies'
import PropTypes from 'prop-types'
import { LANGUAGES_CODE } from '@apps/utils/constants'
import Brands from '@brands/index'
import useBrand from '@engine/hooks/useBrand'
import useSite from '@engine/hooks/useSite'
import Operations from '@apps/flows/activation/services/operations'
import { getParams } from '@apps/flows/activation/services/backend'
import config from '@/config'
import useLocale from '../../_engine/hooks/useLocale'

/**
 * @type {React.Context<function(): {}>} ApplicationContext
 *
 * Initialized with application defaults and can be modified via the ModifyApplicationContext
 */
export const ApplicationContext = createContext(undefined)

/**
 * @type {React.Context<function(): {}>} ModifyApplicationContext
 *
 * Initialized with application state setter function to modify key/val pairs
 */
export const ModifyApplicationContext = createContext(undefined)

/**
 * @enum APPLICATION_STATE - supported application state elements throughout the platform
 *
 * @type {{CURRENT_LANGUAGE: string}} - one of LANGUAGES_CODE en/fr
 *
 * @type {{CURRENT_REGION: string}} - one of prov short name
 *
 */
export const APPLICATION_STATE = {
  CURRENT_LANGUAGE: 'currentLanguage',
  CURRENT_REGION: 'currentReqion',
  SHOW_SELECT_LANGUAGE: 'showSelectLanguage',
  SERVICES: 'services',
  PROVINCES: 'provinces',
  PROVINCE_GROUPS: 'province_groups',
  IDENTITY: 'identity',
  BRAND: 'brand',
  SITE: 'site',
  IS_LOGGED_IN: 'is_logged_in', 
  // ALL_PAGES_DATA: 'allPagesData'
  IS_LANDING_LOGIC_NOT_COMPLETE: 'is_landing_logic_not_complete',
}

// setup our initial state for our context
const initialState = {
  [APPLICATION_STATE.CURRENT_LANGUAGE]: LANGUAGES_CODE.EN,
  [APPLICATION_STATE.CURRENT_REGION]: 'ON',
  [APPLICATION_STATE.SHOW_SELECT_LANGUAGE]: false,
  [APPLICATION_STATE.SERVICES]: undefined,
  [APPLICATION_STATE.PROVINCES]: [],
  [APPLICATION_STATE.PROVINCE_GROUPS]: [],
  [APPLICATION_STATE.IDENTITY]: undefined,
  [APPLICATION_STATE.BRAND]: undefined,
  [APPLICATION_STATE.SITE]: undefined,
  [APPLICATION_STATE.IS_LOGGED_IN]: false,
  [APPLICATION_STATE.IS_LANDING_LOGIC_NOT_COMPLETE]: true,
  // [APPLICATION_STATE.ALL_PAGES_DATA]: []
}

let state
let setState

export const setLoggedInUser = (value = false) => {
  setState({
    ...state,
    [APPLICATION_STATE.IS_LOGGED_IN]: value
  })
}

export const setLandingLogicLoader = (value = false) => {
  setState({
    ...state,
    [APPLICATION_STATE.IS_LANDING_LOGIC_NOT_COMPLETE]: value
  })
}

const removeInvalidPartnerToken = () => {
  cookie.remove(config.partnerCookieId, { path: "/" })
}

const getIdentity = (identityCallback) => {
  const id = cookie.load(config.partnerCookieId)
  if (!id) {
    // eslint-disable-next-line
    console.log('711: Current dealer session, for partners or QSM, has expired')
  }
  
  getParams(id)
    .then((response) => {
      const { data = {} } = response
      const { timestamp } = data
      if (!timestamp) {
        // eslint-disable-next-line
        console.log('711: Current dealer session, for partners or QSM, has expired')
        removeInvalidPartnerToken()
      }

      identityCallback(data)
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.log(error)

      // eslint-disable-next-line
      console.log('711: Current dealer session, for partners or QSM, has expired')

      removeInvalidPartnerToken()
    })
}

export const getProvince = (value) => {
  const provinces = state[APPLICATION_STATE.PROVINCES]

  if(Array.isArray(provinces)) {
    return state[APPLICATION_STATE.PROVINCES].find((province) => province?.value === value) || {}
  }
}

const ApplicationProvider = ({ children }) => {
  let brand = useBrand()

  // TODO: improve with explicit activation example in koodo/PM
  if (brand === undefined) {
    brand = Brands.koodo
  }

  const brandOperations = Operations(brand)

  const { lang, prov } = useLocale()

  const site = useSite()
  // eslint-disable-next-line
  ;[state, setState] = useState({
    ...initialState,
    [APPLICATION_STATE.SERVICES]: brandOperations,
    [APPLICATION_STATE.BRAND]: brand,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: lang,
    [APPLICATION_STATE.CURRENT_REGION]: prov && prov.toUpperCase(),
    [APPLICATION_STATE.SITE]: site
  })

  // const { getProvinces, getAllPagesData } = brandOperations
  const { getProvinces } = brandOperations
  const { 
    [APPLICATION_STATE.PROVINCES]: provinces, 
    [APPLICATION_STATE.IDENTITY]: identity, 
    // [APPLICATION_STATE.ALL_PAGES_DATA]: allPagesData 
  } = state

  // if (allPagesData && allPagesData.length === 0) {
  //   getAllPagesData(site)
  //     .then((result) => {
  //       setState({
  //         ...state,
  //         [APPLICATION_STATE.ALL_PAGES_DATA]: result
  //       })
  //     })
  //     .catch((e) => {
  //       console.warn('error on fetching all pages data', e)
  //     })
  // }

  if (provinces && provinces.length === 0) {
    getProvinces()
      .then((result) => {
        setState({
          ...state,
          [APPLICATION_STATE.PROVINCES]: result
        })
      })
      .catch((e) => {
        console.warn('error on fetching provinces', e)
      })
  }

  if (!identity) {
    getIdentity((data) => {
      setState({
        ...state,
        [APPLICATION_STATE.IDENTITY]: data
      })
    })
  }

  return (
    <ApplicationContext.Provider value={state}>
      <ModifyApplicationContext.Provider value={setState}>
        {children}
      </ModifyApplicationContext.Provider>
    </ApplicationContext.Provider>
  )
}

/**
 * @typedef {object} ApplicationContextAPI
 *
 * @property {object} state - current app state, ie: current language
 * @property {React.Dispatch<any>} setState - the set state method for the current app state
 *
 */

ApplicationProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default ApplicationProvider
