import React, { createContext, useState, useContext } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import PropTypes from 'prop-types'
import {
  MAX_INPUT_LENGTH,
  sanitizeTextField,
  isDigitOrEmpty,
  formatSimField,
  isEmptyEmail,
  isValidEmail,
  isMismatchEmail
} from '@apps/utils/formHelpers'
import {
  regexForUpperCase,
  regexForLowerase,
  regexForNumber,
  regexForSpecialChar,
  regexForSpace
} from '@apps/utils/passwordHelper'
import Sites from '@/sites'

/**
 * @type {React.Context<function(): {}>} AccountSetupContext
 *
 * Initialized with account setup defaults and can be modified via the ModifyAccountSetupContext
 */
export const AccountSetupContext = createContext(undefined)

/**
 * @type {React.Context<function(): {}>} ModifyAccountSetupContext
 *
 * Initialized with account setup state setter function to modify key/val pairs
 */
export const ModifyAccountSetupContext = createContext(undefined)

/**
 * @enum ACCOUNT_SETUP_STATE - supported account setup state elements throughout the platform
 *
 * @type {{SIM: string}} - SIM Number (19 digits)
 *
 * @type {{FIRST_NAME: string}} - First Name (20 characters)
 *
 * @type {{LAST_NAME: string}} - First Name (13 characters)
 *
 * @type {{EMAIL: string}} - Email
 *
 * @type {{POSTAL_CODE: string}} - Postal Code
 *
 * @type {{PIN: string}} - 4 digit PIN
 *
 * @type {{PASSWORD: string}} - min 8 digit Password
 *
 * @type {{SECURITY_QUESTION: string}} - Security question from available options
 *
 * @type {{SECURITY_ANSWER: string}} - Security question answer in string
 *
 */
export const ACCOUNT_SETUP_STATE = {
  SIM: 'sim',
  SIM_ERROR: 'simError',
  FIRST_NAME: 'firstName',
  FIRST_NAME_ERROR: 'firstNameError',
  LAST_NAME: 'lastName',
  LAST_NAME_ERROR: 'lastNameError',
  EMAIL: 'email',
  EMAIL_ERROR: 'emailError',
  CONFIRM_EMAIL: 'confirmEmail',
  CONFIRM_EMAIL_ERROR: 'confirmEmailError',
  EMAIL_IN_USE: 'emailinUse',
  POSTAL_CODE: 'postalCode',
  POSTAL_CODE_ERROR: 'postalCodeError',
  PROVINCE: 'province',
  PROVINCE_ERROR: 'provinceError',
  PIN: 'pin',
  CONFIRM_PIN: 'confirmPin',
  PIN_ERRORS: 'pinErrors',
  PASSWORD: 'password',
  PASSWORD_ERRORS: 'passwordErrors',
  CONFIRM_PASSWORD: 'confirmPassword',
  SECURITY_QUESTION: 'securityQuestion',
  SECURITY_ANSWER: 'securityAnswer',
  SECURITY_QUESTION_ERROR: 'securityQuestionError',
  SECURITY_ANSWER_ERROR: 'securityAnswerError',
  LANGUAGE: 'language',
  IS_COMPLETE: 'isComplete',
  SITE: 'site',
  BAN: 'ban'
}

// setup our initial state for our context
const initialState = {
  [ACCOUNT_SETUP_STATE.SIM]: '',
  [ACCOUNT_SETUP_STATE.SIM_ERROR]: '',
  [ACCOUNT_SETUP_STATE.FIRST_NAME]: '',
  [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR]: '',
  [ACCOUNT_SETUP_STATE.LAST_NAME]: '',
  [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]: '',
  [ACCOUNT_SETUP_STATE.EMAIL]: '',
  [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: '',
  [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL]: '',
  [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: '',
  [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: null,
  [ACCOUNT_SETUP_STATE.POSTAL_CODE]: '',
  [ACCOUNT_SETUP_STATE.POSTAL_CODE_ERROR]: '',
  [ACCOUNT_SETUP_STATE.PROVINCE]: '',
  [ACCOUNT_SETUP_STATE.PROVINCE_ERROR]: '',
  [ACCOUNT_SETUP_STATE.PIN]: '',
  [ACCOUNT_SETUP_STATE.CONFIRM_PIN]: '',
  [ACCOUNT_SETUP_STATE.PIN_ERRORS]: {
    isInvalidPINLength: null,
    pinStartsWithZero: null,
    pinOneTwoThreeFour: null,
    pinSingleRepeatingDigit: null,
    showRequirements: null,
    pinMatches: null,
    confirmPinRequired: null,
    pinRequired: null
  },
  [ACCOUNT_SETUP_STATE.PASSWORD]: '',
  [ACCOUNT_SETUP_STATE.PASSWORD_ERRORS]: {
    isInvalidPasswordLength: null,
    passwordNoUpper: null,
    passwordNoLower: null,
    passwordNoNumber: null,
    passwordNoSpecialChar: null,
    passwordHasSpace: null,
    showRequirements: null,
    passwordMatches: null,
    confirmPasswordRequired: null,
    passwordRequired: null
  },
  [ACCOUNT_SETUP_STATE.CONFIRM_PASSWORD]: '',
  [ACCOUNT_SETUP_STATE.SECURITY_QUESTION]: '',
  [ACCOUNT_SETUP_STATE.SECURITY_ANSWER]: '',
  [ACCOUNT_SETUP_STATE.SECURITY_QUESTION_ERROR]: null,
  [ACCOUNT_SETUP_STATE.SECURITY_ANSWER_ERROR]: null,
  [ACCOUNT_SETUP_STATE.LANGUAGE]: 0,
  [ACCOUNT_SETUP_STATE.IS_COMPLETE]: false,
  [ACCOUNT_SETUP_STATE.SITE]: Sites.consumer,
  [ACCOUNT_SETUP_STATE.BAN]: ''
}

let state
let setState

/**
 * @typedef {object} ActivationContextAPI
 *
 * @property {object} state - current app state, ie: current language
 * @property {React.Dispatch<any>} setState - the set state method for the current app state
 *
 */
const AccountSetupProvider = ({ children }) => {
  // eslint-disable-next-line
  ;[state, setState] = useState({
    ...initialState
  })

  return (
    <AccountSetupContext.Provider value={state}>
      <ModifyAccountSetupContext.Provider value={setState}>
        {children}
      </ModifyAccountSetupContext.Provider>
    </AccountSetupContext.Provider>
  )
}

/**
 * @typedef function UpdateAccountSetupState
 *
 * Updates the provided key,val in Activation State, without destroying other values
 *
 * @param key Target element
 * @param value Value for target element
 */
export const UpdateAccountSetupState = (key, value) => {
  setState((previousState) => {
    Object.assign(previousState, { [key]: value })
    const isComplete = AccountSetupComplete(previousState)
    return {
      ...previousState,
      [ACCOUNT_SETUP_STATE.IS_COMPLETE]: isComplete
    }
  })
}

export const UpdateAccountSetupStates = (newStates) => {
  setState((previousState) => {
    Object.assign(previousState, newStates)
    const isComplete = AccountSetupComplete(previousState)
    return {
      ...previousState,
      [ACCOUNT_SETUP_STATE.IS_COMPLETE]: isComplete
    }
  })
}

const requiredFields = {
  [Sites.consumer]: [
    [ACCOUNT_SETUP_STATE.SIM],
    [ACCOUNT_SETUP_STATE.FIRST_NAME],
    [ACCOUNT_SETUP_STATE.LAST_NAME],
    [ACCOUNT_SETUP_STATE.EMAIL],
    [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL],
    [ACCOUNT_SETUP_STATE.POSTAL_CODE],
    [ACCOUNT_SETUP_STATE.PROVINCE],
    [ACCOUNT_SETUP_STATE.PIN],
    [ACCOUNT_SETUP_STATE.CONFIRM_PIN],
    [ACCOUNT_SETUP_STATE.PASSWORD],
    [ACCOUNT_SETUP_STATE.CONFIRM_PASSWORD],
    [ACCOUNT_SETUP_STATE.SECURITY_QUESTION],
    [ACCOUNT_SETUP_STATE.SECURITY_ANSWER],
    [ACCOUNT_SETUP_STATE.LANGUAGE]
  ],
  [Sites.dealer]: [
    [ACCOUNT_SETUP_STATE.SIM],
    [ACCOUNT_SETUP_STATE.FIRST_NAME],
    [ACCOUNT_SETUP_STATE.LAST_NAME],
    [ACCOUNT_SETUP_STATE.EMAIL],
    [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL],
    [ACCOUNT_SETUP_STATE.POSTAL_CODE],
    [ACCOUNT_SETUP_STATE.PROVINCE],
    [ACCOUNT_SETUP_STATE.PIN],
    [ACCOUNT_SETUP_STATE.CONFIRM_PIN],
    [ACCOUNT_SETUP_STATE.LANGUAGE]
  ]
}

const errorFields = {
  [Sites.consumer]: [
    [ACCOUNT_SETUP_STATE.SIM_ERROR],
    [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR],
    [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR],
    [ACCOUNT_SETUP_STATE.EMAIL_ERROR],
    [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR],
    [ACCOUNT_SETUP_STATE.POSTAL_CODE_ERROR],
    [ACCOUNT_SETUP_STATE.PROVINCE_ERROR],
    [ACCOUNT_SETUP_STATE.SECURITY_QUESTION_ERROR],
    [ACCOUNT_SETUP_STATE.SECURITY_ANSWER_ERROR]
  ],
  [Sites.dealer]: [
    [ACCOUNT_SETUP_STATE.SIM_ERROR],
    [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR],
    [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR],
    [ACCOUNT_SETUP_STATE.EMAIL_ERROR],
    [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR],
    [ACCOUNT_SETUP_STATE.POSTAL_CODE_ERROR],
    [ACCOUNT_SETUP_STATE.PROVINCE_ERROR]
  ]
}

const isNullOrEmpty = (value) => {
  if (value === '' || value === null) {
    return true
  }
  return false
}

export const AccountSetupComplete = (newState) => {
  const site = newState[ACCOUNT_SETUP_STATE.SITE]
  const emptyRequiredField = requiredFields[site].find((field) => isNullOrEmpty(newState[field]))
  const hasError = errorFields[site].find((error) => !isNullOrEmpty(newState[error]))
  const pinErrors = newState[ACCOUNT_SETUP_STATE.PIN_ERRORS]
  const hasPinError = Object.values(pinErrors).find((value) => value)

  if (site === Sites.dealer && (hasPinError || hasError || emptyRequiredField)) {
    return false
  }
  if (site === Sites.consumer) {
    const passwordErrors = newState[ACCOUNT_SETUP_STATE.PASSWORD_ERRORS]
    const hasPasswordError = Object.values(passwordErrors).find((value) => {
      return value
    })
    if (hasPinError || hasError || emptyRequiredField || hasPasswordError) {
      return false
    }
  }
  return true
}

/*
 * Event Functions
 */
const firstNameRegex = new RegExp(/^[A-Za-z\u00C0-\u00FF().'\-\s]{1,20}$/)
const lastNameRegex = new RegExp(/^[A-Za-z\u00C0-\u00FF().'\-\s]{1,13}$/)
const regexNumber = /^\d*\.?\d*$/

export const ACCOUNT_SETUP_EVENTS = {
  POSTAL_CODE: 'event.target.accountSetup.postalCode',
  SIM: 'event.target.accountSetup.sim',
  FIRST_NAME: 'event.target.accountSetup.firstName',
  LAST_NAME: 'event.target.accountSetup.lastName',
  PROVINCE: 'event.target.accountSetup.province',
  EMAIL: 'event.target.accountSetup.email',
  CONFIRM_EMAIL: 'event.target.accountSetup.confirmEmail',
  PASSWORD: 'event.target.accountSetup.password',
  CONFIRM_PASSWORD: 'event.target.accountSetup.confirmPassword',
  SECURITY_QUESTION: 'event.target.accountSetup.securityQuestion',
  SECURITY_ANSWER: 'event.target.accountSetup.securityAnswer',
  PIN: 'event.target.accountSetup.pin',
  CONFIRM_PIN: 'event.target.accountSetup.confirmPin',
  LANGUAGE: 'event.target.accountSetup.language'
}

AccountSetupProvider.Common = {
  [ACCOUNT_SETUP_EVENTS.POSTAL_CODE]: {
    onChange: (value) => {
      const firstLetterMatch = new RegExp(/([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])/)
      const letterMatch = new RegExp(/([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])/)
      const digitMatch = new RegExp(/\d/)

      const stringArray = value.split('')

      if (stringArray.length > 3 && stringArray.indexOf(' ') === -1) {
        stringArray.splice(3, 0, ' ')
      }
      const letters = [0, 2, 5]
      const numbers = [1, 4, 6]

      let matches = true

      stringArray.forEach((char, i) => {
        if (letters.indexOf(i) !== -1) {
          if (i === 0 && !char.match(firstLetterMatch)) {
            matches = false
          } else if (!char.match(letterMatch)) {
            matches = false
          }
        } else if (numbers.indexOf(i) !== -1) {
          if (!char.match(digitMatch)) {
            matches = false
          }
        }
      })
      if (matches) {
        const result = stringArray.join('')
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.POSTAL_CODE, result)
      }
    },
    onBlur: (errors) => {
      const value = state[ACCOUNT_SETUP_STATE.POSTAL_CODE]
      if (value === '') {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.POSTAL_CODE_ERROR, errors.postalCodeRequired)
        return true
      } else {
        const postalMatch = new RegExp(
          /([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])(.?)\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])\d/g
        )
        if (!value.match(postalMatch)) {
          UpdateAccountSetupState(ACCOUNT_SETUP_STATE.POSTAL_CODE_ERROR, errors.postalCodeInvalid)
          return true
        } else {
          UpdateAccountSetupState(ACCOUNT_SETUP_STATE.POSTAL_CODE_ERROR, null)
          return false
        }
      }
    }
  },
  [ACCOUNT_SETUP_EVENTS.SIM]: {
    onChange: (value) => {
      const sanitizedValue = sanitizeTextField(value)

      if (isDigitOrEmpty(sanitizedValue)) {
        const { formattedSim, simArr } = formatSimField(sanitizedValue)
        if (simArr && simArr.length > 0) {
          UpdateAccountSetupState(ACCOUNT_SETUP_STATE.SIM, formattedSim)
        } else {
          UpdateAccountSetupState(ACCOUNT_SETUP_STATE.SIM, '')
        }
      }
    },
    onBlur: async (isSimValid, setLoading, errors) => {
      let error = null
      if (state[ACCOUNT_SETUP_STATE.SIM] === '') {
        error = errors.simRequiredError
      } else if (state[ACCOUNT_SETUP_STATE.SIM].length !== MAX_INPUT_LENGTH) {
        error = errors.simLengthError
      } else if (state[ACCOUNT_SETUP_STATE.SIM].length === MAX_INPUT_LENGTH) {
        setLoading(true)
        // perform service call
        const result = await isSimValid(state[ACCOUNT_SETUP_STATE.SIM]).catch(() => {
          return undefined
        })
        if (!result) {
          error = errors.simUnavailableError
        } else {
          error = null
        }
        setLoading(false)
      } else {
        error = null
      }
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.SIM_ERROR, error)
      return error === null ? false : true
    }
  },
  [ACCOUNT_SETUP_EVENTS.FIRST_NAME]: {
    onChange: (value, errors) => {
      if (firstNameRegex.test(value) || value === '') {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.FIRST_NAME, value.length > 0 ? value : '')
      } else {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR, errors.firstNameInvalid)
      }
    },
    onBlur: (errors) => {
      const value = state[ACCOUNT_SETUP_STATE.FIRST_NAME].trim()

      let error = ''
      if (value === '') {
        error = errors.firstNameRequired
      } else if (!firstNameRegex.test(value)) {
        error = errors.firstNameInvalid
      }
      UpdateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.FIRST_NAME]: value,
        [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR]: error
      })
      return error !== ''
    }
  },
  [ACCOUNT_SETUP_EVENTS.LAST_NAME]: {
    onChange: (value, errors) => {
      if (lastNameRegex.test(value) || value === '') {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.LAST_NAME, value.length > 0 ? value : '')
      } else {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.LAST_NAME_ERROR, errors.lastNameInvalid)
      }
    },
    onBlur: (errors) => {
      const value = state[ACCOUNT_SETUP_STATE.LAST_NAME].trim()

      let error = ''
      if (value === '') {
        error = errors.lastNameRequired
      } else if (!lastNameRegex.test(value)) {
        error = errors.lastNameInvalid
      }
      UpdateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.LAST_NAME]: value,
        [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]: error
      })
      return error !== ''
    }
  },
  [ACCOUNT_SETUP_EVENTS.PROVINCE]: {
    onChange: (value, provinces) => {
      if (value !== '') {
        const foundProvince = provinces.find((province) => province.name === value)
        if (foundProvince) {
          UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PROVINCE, foundProvince.value)
        } else {
          UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PROVINCE, null)
        }
      } else {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PROVINCE, null)
      }
    },
    onBlur: (value, errors) => {
      if (value !== '' && isNullOrEmpty(state[ACCOUNT_SETUP_STATE.PROVINCE])) {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PROVINCE_ERROR, errors.provinceInvalid)
        return true
      } else if (isNullOrEmpty(state[ACCOUNT_SETUP_STATE.PROVINCE])) {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PROVINCE_ERROR, errors.provinceRequired)
        return true
      } else {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PROVINCE_ERROR, '')
        return false
      }
    }
  },
  [ACCOUNT_SETUP_EVENTS.EMAIL]: {
    onChange: (value) => {
      if (state[ACCOUNT_SETUP_STATE.EMAIL].length <= 40) {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.EMAIL, value)
      }
    },
    onBlur: async (errors, setLoading, isUsernameActive) => {
      const value = state[ACCOUNT_SETUP_STATE.EMAIL]
      const isEmpty = isEmptyEmail(value)
      const isValid = isValidEmail(value)
      const isMismatch = isMismatchEmail(state[ACCOUNT_SETUP_STATE.CONFIRM_EMAIL], value)
      const isEmptyConfirm = isEmptyEmail(state[ACCOUNT_SETUP_STATE.CONFIRM_EMAIL])

      const requiredError = isEmpty ? errors.emailRequired : null
      const invalidError = !isValid ? errors.emailInvalid : null
      let mismatchConfirmError = isMismatch && errors.emailMismatch
      let emailInUseError = ''
      const confirmRequiredError = isEmptyConfirm ? errors.emailRequired : null

      if (!isEmptyConfirm && isValid && !isMismatch && !isEmpty) {
        setLoading(true)
        const result = await isUsernameActive(value).catch(() => {
          return undefined
        })
        setLoading(false)
        if (result) {
          emailInUseError = errors.emailInUse
        }
      }

      if (requiredError) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: requiredError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError
        })
        return true
      }
      if (invalidError) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: invalidError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError
        })
        return true
      }
      if (mismatchConfirmError) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: mismatchConfirmError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError
        })
        return true
      }
      if (emailInUseError !== '') {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailInUseError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError,
          [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: true
        })
        return true
      }
      UpdateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: null,
        [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: false
      })
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.CONFIRM_EMAIL]: {
    onChange: (value) => {
      if (value.length <= 40) {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.CONFIRM_EMAIL, value)
      }
    },
    onBlur: async (errors, setLoading, isUsernameActive) => {
      const value = state[ACCOUNT_SETUP_STATE.CONFIRM_EMAIL]
      const isEmptyConfirm = isEmptyEmail(value)
      const isValid = isValidEmail(value)
      const isMismatch = isMismatchEmail(state[ACCOUNT_SETUP_STATE.EMAIL], value)
      const isEmpty = isEmptyEmail(state[ACCOUNT_SETUP_STATE.EMAIL])

      const requiredConfirmError = isEmptyConfirm ? errors.emailRequired : null
      const invalidConfirmError = isValid ? null : errors.emailInvalid
      const mismatchConfirmError = isMismatch ? errors.emailMismatch : null
      let emailError = null
      let emailInUseError = ''

      if (state[ACCOUNT_SETUP_STATE.EMAIL] === '') {
        emailError = isEmpty && errors.emailRequired
      }
      if (!isEmptyConfirm && isValid && !isMismatch && !isEmpty) {
        setLoading(true)
        const result = await isUsernameActive(state[ACCOUNT_SETUP_STATE.EMAIL]).catch(() => {
          return undefined
        })
        setLoading(false)
        if (result) {
          emailInUseError = errors.emailInUse
        }
      }
      if (requiredConfirmError) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: requiredConfirmError,
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailError
        })
        return true
      }
      if (invalidConfirmError) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: invalidConfirmError,
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailError
        })
        return true
      }
      if (mismatchConfirmError) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: mismatchConfirmError,
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailError
        })
        return true
      }
      if (emailInUseError !== '') {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailInUseError,
          [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: true
        })
        return true
      }
      UpdateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: null,
        [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailError,
        [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: false
      })
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.PASSWORD]: {
    onFocus: () => {
      const currentPasswordState = state[ACCOUNT_SETUP_STATE.PASSWORD_ERRORS]
      currentPasswordState.showRequirements = true
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PASSWORD_ERRORS, currentPasswordState)
    },
    onBlur: () => {
      const value = state[ACCOUNT_SETUP_STATE.PASSWORD]
      const confirmPassword = state[ACCOUNT_SETUP_STATE.CONFIRM_PASSWORD]
      const passwordMinLength = '8'
      const passwordMaxLength = '64'
      const invalidPasswordLength =
        value.length < passwordMinLength || value.length > passwordMaxLength
      const oneUpper = !regexForUpperCase.test(value)
      const oneLower = !regexForLowerase.test(value)
      const oneNumber = !regexForNumber.test(value)
      const oneSpecial = !regexForSpecialChar.test(value)
      const noSpaces = regexForSpace.test(value)
      const matches = confirmPassword === value

      const newErrors = {
        isInvalidPasswordLength: null,
        passwordNoUpper: null,
        passwordNoLower: null,
        passwordNoNumber: null,
        passwordNoSpecialChar: null,
        passwordHasSpace: null,
        showRequirements: null,
        passwordMatches: null,
        confirmPasswordRequired: null,
        passwordRequired: null
      }

      if (value.length > 0) {
        // validate length
        newErrors.isInvalidPasswordLength = invalidPasswordLength

        newErrors.passwordHasSpace = noSpaces
        newErrors.passwordNoLower = oneLower
        newErrors.passwordNoUpper = oneUpper
        newErrors.passwordNoNumber = oneNumber
        newErrors.passwordNoSpecialChar = oneSpecial

        newErrors.passwordRequired = false

        // check for mismatch
        if (confirmPassword.length > 0) {
          newErrors.passwordMatches = !matches
        }

        // hide requirements
        if (
          !invalidPasswordLength &&
          !oneUpper &&
          !oneLower &&
          !oneNumber &&
          !oneSpecial &&
          !noSpaces
        ) {
          newErrors.showRequirements = false
        } else {
          newErrors.showRequirements = true
        }
      } else {
        newErrors.passwordRequired = true
        newErrors.isInvalidPasswordLength = true
      }
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PASSWORD_ERRORS, newErrors)
      if (
        newErrors.isInvalidPasswordLength ||
        newErrors.passwordNoUpper ||
        newErrors.passwordNoLower ||
        newErrors.passwordNoNumber ||
        newErrors.passwordNoSpecialChar ||
        newErrors.passwordHasSpace ||
        !newErrors.passwordMatches ||
        newErrors.confirmPasswordRequired ||
        newErrors.passwordRequired
      ) {
        return true
      }
      return false
    },
    onChange: (value) => {
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PASSWORD, value)
    }
  },
  [ACCOUNT_SETUP_EVENTS.CONFIRM_PASSWORD]: {
    onBlur: () => {
      const password = state[ACCOUNT_SETUP_STATE.PASSWORD]
      const value = state[ACCOUNT_SETUP_STATE.CONFIRM_PASSWORD]
      const passwordState = state[ACCOUNT_SETUP_STATE.PASSWORD_ERRORS]
      const matches = password === value

      passwordState.confirmPasswordRequired = value.length === 0
      passwordState.passwordMatches = !matches

      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PASSWORD_ERRORS, passwordState)
      if (passwordState.confirmPasswordRequired || !passwordState.passwordMatches) {
        return true
      }
      return false
    },
    onChange: (value) => {
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.CONFIRM_PASSWORD, value)
    }
  },
  [ACCOUNT_SETUP_EVENTS.SECURITY_QUESTION]: {
    onChange: (value, options) => {
      if (options.indexOf(value) !== -1) {
        UpdateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.SECURITY_QUESTION]: value,
          [ACCOUNT_SETUP_STATE.SECURITY_QUESTION_ERROR]: null
        })
      }
    },
    onBlur: (error) => {
      const value = state[ACCOUNT_SETUP_STATE.SECURITY_QUESTION]
      if (value === '') {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.SECURITY_QUESTION_ERROR, error)
        return true
      } else {
        UpdateAccountSetupState(ACCOUNT_SETUP_STATE.SECURITY_QUESTION_ERROR, null)
        return false
      }
    }
  },
  [ACCOUNT_SETUP_EVENTS.SECURITY_ANSWER]: {
    onChange: (value, error) => {
      const securityQuestion = state[ACCOUNT_SETUP_STATE.SECURITY_QUESTION]
      let questionError = null
      let answerError = state[ACCOUNT_SETUP_STATE.SECURITY_ANSWER_ERROR]

      if (securityQuestion === '') {
        questionError = error
      }

      if (value !== '' && answerError) {
        answerError = null
      }
      UpdateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.SECURITY_ANSWER]: value,
        [ACCOUNT_SETUP_STATE.SECURITY_QUESTION_ERROR]: questionError,
        [ACCOUNT_SETUP_STATE.SECURITY_ANSWER_ERROR]: answerError
      })
    },
    onBlur: (error) => {
      const securityAnswer = state[ACCOUNT_SETUP_STATE.SECURITY_ANSWER]
      let securityAnswerError = null
      if (securityAnswer === '' || !securityAnswer) {
        securityAnswerError = error
      }
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.SECURITY_ANSWER_ERROR, securityAnswerError)
      if (securityAnswerError) {
        return true
      }
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.PIN]: {
    onChange: (value) => {
      if (!regexNumber.test(value)) return

      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PIN, value)
    },
    onBlur: () => {
      const value = state[ACCOUNT_SETUP_STATE.PIN]
      const confirmPin = state[ACCOUNT_SETUP_STATE.CONFIRM_PIN]
      const regexForSameDigits = /^([0-9])\1*$/
      const pinLength = 4

      const invalidPINLength = value.length !== pinLength
      const startWithZero = value.startsWith('0')
      const onetwothreefour = value === '1234'
      const repeatingDigits = regexForSameDigits.test(value)
      const matches = confirmPin === value

      const newErrors = {
        isInvalidPINLength: null,
        pinStartsWithZero: null,
        pinOneTwoThreeFour: null,
        pinSingleRepeatingDigit: null,
        showRequirements: null,
        pinMatches: null,
        confirmPinRequired: null,
        pinRequired: null
      }

      if (value.length > 0) {
        // validate length
        newErrors.isInvalidPINLength = invalidPINLength

        // start with 0
        newErrors.pinStartsWithZero = startWithZero

        // start 1234
        newErrors.pinOneTwoThreeFour = onetwothreefour

        // 1111, 2222, 3333…
        newErrors.pinSingleRepeatingDigit = repeatingDigits

        newErrors.pinRequired = false

        // check for mismatch
        if (confirmPin.length > 0) {
          newErrors.pinMatches = !matches
        }

        // hide requirements
        if (!invalidPINLength && !startWithZero && !onetwothreefour && !repeatingDigits) {
          newErrors.showRequirements = false
        } else {
          newErrors.showRequirements = true
        }
      } else {
        newErrors.pinRequired = true
        newErrors.isInvalidPINLength = true
      }

      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PIN_ERRORS, newErrors)
      if (
        newErrors.isInvalidPINLength ||
        newErrors.pinStartsWithZero ||
        newErrors.pinOneTwoThreeFour ||
        newErrors.pinSingleRepeatingDigit ||
        newErrors.pinMatches ||
        newErrors.confirmPinRequired ||
        newErrors.pinRequired
      ) {
        return true
      }
      return false
    },
    onFocus: () => {
      const currentPinState = state[ACCOUNT_SETUP_STATE.PIN_ERRORS]
      currentPinState.showRequirements = true
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PIN_ERRORS, currentPinState)
    }
  },
  [ACCOUNT_SETUP_EVENTS.CONFIRM_PIN]: {
    onChange: (value) => {
      if (!regexNumber.test(value)) return
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.CONFIRM_PIN, value)
    },
    onBlur: () => {
      const pin = state[ACCOUNT_SETUP_STATE.PIN]
      const value = state[ACCOUNT_SETUP_STATE.CONFIRM_PIN]
      const pinState = state[ACCOUNT_SETUP_STATE.PIN_ERRORS]
      const matches = pin === value

      pinState.confirmPinRequired = value.length === 0
      pinState.pinMatches = !matches

      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.PIN_ERRORS, pinState)
      if (pinState.confirmPinRequired || pinState.pinMatches) {
        return true
      }
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.LANGUAGE]: {
    onChange: (index) => {
      UpdateAccountSetupState(ACCOUNT_SETUP_STATE.LANGUAGE, index)
    }
  }
}

AccountSetupProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default AccountSetupProvider
