import React, { createContext, useState } from 'react'
import {
  isDigitOrEmpty,
  MAX_INPUT_LENGTH,
  sanitizeTextField,
  sanitizeAccountNumber,
  sanitizeNameField,
  formattedPhoneNumber,
  isPostalCode
} from '@apps/utils/formHelpers'
import PropTypes from 'prop-types'
import { PHONE_NUMBER_OPTIONS } from '@apps/utils/constants'

/**
 * @type {React.Context<function(): {}>} ActivationContext
 *
 * Initialized with activation defaults and can be modified via the ModifyActivationContext
 */
export const ActivationContext = createContext(undefined)

/**
 * @type {React.Context<function(): {}>} ModifyActivationContext
 *
 * Initialized with activation state setter function to modify key/val pairs
 */
export const ModifyActivationContext = createContext(undefined)

/**
 * @enum ACTIVATION_STATE - supported activation state elements throughout the platform
 *
 * @type {{CURRENT_LANGUAGE: string}} - one of LANGUAGES_CODE en/fr
 *
 * @type {{CURRENT_REGION: string}} - one of prov short name
 *
 */
export const ACTIVATION_STATE = {
  PHONE_NUMBER: {
    NUMBER_SELECTION: 'numberSelection',
    PHONE_NUMBER: 'phoneNumber'
  },
  PLAN: {
    ID: 'id',
    NAME: 'name',
    PRICE: 'price',
    FEATURES: 'features'
  },
  PAYMENT: {
    FRIEND_REFERRAL_CODE: 'friendReferralCode',
    PROMO: 'promo',
    PAYMENT_TYPE: 'paymentType',
    TAX: 'tax',
    SUB_TOTAL: 'subTotal',
    TOTAL: 'total',
    CARD_NUMBER: 'cardNumber',
    EXPIRY_DATE: 'expiryDate',
    SECURITY_CODE: 'securityCode',
    POSTAL_CODE: 'postalCode',
    VOUCHER_CODE: 'voucherCode',
    FUNDS_ADDED: 'fundAdded'
  },
  ACCOUNT_PROVINCE: 'accountProvince',
  NUM_PROVINCE_ID: 'numProvinceId',
  SELECTED_PHONE_NUMBER_OPTION: 'selectedPhoneNumberOption',
  CREATE_NEW_NUMBER: {
    NUM_PROVINCE_GROUP_ID: 'numProvinceGroupId',
    NUM_SELECTED_INFO: 'numSelectedInfo'
  },
  TRANSFER_A_NUMBER: {
    PORT_IN_AREA_CODE: 'portInAreaCode',
    PORT_IN_THREE_DIGITS: 'portInThreeDigits',
    PORT_IN_FOUR_DIGITS: 'portInFourDigits',
    PREV_PORT_IN_PHONE_NUMBER: 'prevPortInPhoneNumber',
    PORT_IN_PHONE_NUMBER: 'portInPhoneNumber',
    IS_PORT_IN_NUMBER_ELIGIBLE: 'isPortInNumberEligible',
    IS_PREV_PORT_IN_NUMBER_ELIGIBLE: 'isPrevPortInNumberEligible',
    IS_PORT_IN_AGREEMENT_CHECKED: 'isPortInAgreementChecked',
    IS_PORT_IN_WIRELESS: 'isPortInWireless',
    ALTERNATE_CONTACT_NUMBER: 'alternateContactNumber',
    WIRELINE: {
      STREET_NUMBER: 'streetNumber',
      STREET_NAME: 'streetName',
      CITY_NAME: 'cityName',
      SELECTED_PROVINCE_LANDLINE: 'selectedProvinceLandline',
      POSTAL_CODE_LANDLINE: 'postalCodeLandline',
      INTERNET_AND_TV_SERVICES: 'internetAndTvServices'
    },
    WIRELESS: {
      SELECTED_SPID: 'selectedSpid',
      IMEI: 'selectedImei',
      PREVIOUS_PROVIDER_ACCOUNT_NUMBER: 'previousProviderAccountNumber'
    }
  },
  ACTIVATION_DATE: 'activationDate',
  BAN: 'ban'
}

export const createNewNumberState = {
  [ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_PROVINCE_GROUP_ID]: undefined,
  [ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_SELECTED_INFO]: undefined
}

export const transferANumberCommonState = {
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]: undefined,
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]: undefined,
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: false,
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: undefined,
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: ''
}

export const transferANumberWirelessState = {
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]: undefined,
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]: ''
}

export const transferANumberWirelineState = {
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]: '',
  [ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: ''
}

// setup our initial state for our context
const initialState = {
  // [ACTIVATION_STATE.SIM]: undefined,
  // [ACTIVATION_STATE.EMAIL]: undefined,
  // [ACTIVATION_STATE.FIRST_NAME]: '',
  // [ACTIVATION_STATE.LAST_NAME]: '',
  // [ACTIVATION_STATE.ACCOUNT_PROVINCE]: undefined,
  // [ACTIVATION_STATE.NUM_PROVINCE_ID]: undefined,
  // [ACTIVATION_STATE.SELECTED_PHONE_NUMBER_OPTION]: PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER
  // ...createNewNumberState,
  // ...transferANumberCommonState,
  // ...transferANumberWirelessState,
  // ...transferANumberWirelineState,
  // [ACTIVATION_STATE.PLAN.NAME]: 'plan name',
  // [ACTIVATION_STATE.PLAN.PRICE]: 'plan price',
  // [ACTIVATION_STATE.PLAN.FEATURES]: 'plan features'
}

let state
let setState

const ActivationProvider = ({ children }) => {
  // eslint-disable-next-line
  ;[state, setState] = useState({
    ...initialState
  })

  return (
    <ActivationContext.Provider value={state}>
      <ModifyActivationContext.Provider value={setState}>
        {children}
      </ModifyActivationContext.Provider>
    </ActivationContext.Provider>
  )
}

/**
 * @typedef function UpdateActivationState
 *
 * Updates the provided key,val in Activation State, without destroying other values
 *
 * @param key Target element
 * @param value Value for target element
 */
export const UpdateActivationState = (key, value) => {
  setState({
    ...state,
    [key]: value
  })
}

/*
 * Event Functions
 */

export const ACTIVATION_EVENTS = {
  PHONE_NUMBER_SETUP: {
    PROVINCE: 'event.target.phoneNumberSetup.province',
    PROVINCE_GROUP: 'event.target.phoneNumberSetup.provinceGroup',
    SPID: 'event.target.phoneNumberSetup.spid',
    ACCOUNT_NUMBER: 'event.target.phoneNumberSetup.accountNumber',
    ALTERNATE_CONTACT_NUMBER: 'event.target.phoneNumberSetup.alternateContactNumber',
    POSTAL_CODE: 'event.target.phoneNumberSetup.postalCode'
  },
  COMMONS: {
    INPUT: 'event.target.commons.input'
  }
}

ActivationProvider.Common = {
  [ACTIVATION_EVENTS.PHONE_NUMBER_SETUP.PROVINCE]: {
    onBlur: async (
      provinces,
      setProvinceError,
      requiredError,
      invalidError,
      provinceError,
      key
    ) => {
      if (!provinceError) {
        if (state[key] === null) {
          setProvinceError(requiredError)
        } else if (!provinces.find((province) => province.value === state[key])) {
          setProvinceError(invalidError)
        } else {
          setProvinceError(null)
        }
      }
    },
    onChange: (e, setProvinceError, requiredError, invalidError, provinces, key) => {
      if (e.target.value !== '') {
        const matchedProv = provinces.find((prov) => {
          if (prov.name === e.target.value) {
            return prov.value
          }
        })
        if (matchedProv) {
          const id = matchedProv.value
          UpdateActivationState(key, id)
          setProvinceError(null)
        } else {
          UpdateActivationState(key, null)
          setProvinceError(invalidError)
        }
      } else {
        UpdateActivationState(key, null)
        setProvinceError(requiredError)
      }
    }
  },
  [ACTIVATION_EVENTS.PHONE_NUMBER_SETUP.PROVINCE_GROUP]: {
    onBlur: async (cities, setCityError, requiredError, invalidError, cityError) => {
      if (!cityError) {
        if (state[ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_PROVINCE_GROUP_ID] === null) {
          setCityError(requiredError)
        } else if (
          !cities.find(
            (city) => city.value === state[ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_PROVINCE_GROUP_ID]
          )
        ) {
          setCityError(invalidError)
        } else {
          setCityError(null)
        }
      }
    },
    onChange: (e, setCityError, setIsNumbersLoaded, cities, requiredError, invalidError) => {
      if (e.target.value !== '') {
        const matchedCity = cities.find((city) => {
          if (city.name === e.target.value) {
            return city.value
          }
        })

        if (matchedCity) {
          const id = matchedCity.value
          UpdateActivationState(ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_PROVINCE_GROUP_ID, id)
          setIsNumbersLoaded(false)
          setCityError(null)
        } else {
          UpdateActivationState(ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_PROVINCE_GROUP_ID, null)
          setCityError(invalidError)
        }
      } else {
        UpdateActivationState(ACTIVATION_STATE.CREATE_NEW_NUMBER.NUM_PROVINCE_GROUP_ID, null)
        setCityError(requiredError)
      }
    }
  },
  [ACTIVATION_EVENTS.PHONE_NUMBER_SETUP.SPID]: {
    onBlur: async (spids, setProvinceError, requiredError, invalidError, provinceError) => {
      if (!provinceError) {
        if (state[ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID] === null) {
          setProvinceError(requiredError)
        } else if (
          !spids.find(
            (province) =>
              province.value === state[ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]
          )
        ) {
          setProvinceError(invalidError)
        } else {
          setProvinceError(null)
        }
      }
    },
    onChange: (e, setProvinceError, requiredError, invalidError, spids) => {
      if (e.target.value !== '') {
        const matchedProv = spids.find((prov) => {
          if (prov.name === e.target.value) {
            return prov.value
          }
        })
        if (matchedProv) {
          const id = matchedProv.value
          UpdateActivationState(ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID, id)
          setProvinceError(null)
        } else {
          UpdateActivationState(ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID, null)
          setProvinceError(invalidError)
        }
      } else {
        UpdateActivationState(ACTIVATION_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID, null)
        setProvinceError(requiredError)
      }
    }
  },
  [ACTIVATION_EVENTS.COMMONS.INPUT]: {
    onChange: (e, updateKey, setError) => {
      const { value } = e.target
      const sanitizedValue = sanitizeTextField(value)
      if (isDigitOrEmpty(sanitizedValue)) {
        UpdateActivationState(updateKey, sanitizedValue)
      }
      setError('')
    },
    onChangeName: (e, updateKey, setError) => {
      const { value } = e.target
      let sanitizedValue = value
      if (/^([\s]).*/.test(value)) {
        sanitizedValue = sanitizedValue.trim()
      }
      UpdateActivationState(updateKey, sanitizeNameField(sanitizedValue))
      setError('')
    },
    onBlur: async (
      value,
      setError,
      requiredErrorMessage,
      invalidErrorMessage,
      maxLength = MAX_INPUT_LENGTH
    ) => {
      if (value === '') {
        setError(requiredErrorMessage)
      } else if (value.length !== maxLength) {
        setError(invalidErrorMessage)
      } else {
        setError(null)
      }
    }
  },
  [ACTIVATION_EVENTS.PHONE_NUMBER_SETUP.ACCOUNT_NUMBER]: {
    onChange: (e, updateKey, setError) => {
      const { value } = e.target
      let sanitizedValue = sanitizeTextField(value)
      sanitizedValue = sanitizeAccountNumber(sanitizedValue)
      UpdateActivationState(updateKey, sanitizedValue)
      setError('')
    }
  },
  [ACTIVATION_EVENTS.PHONE_NUMBER_SETUP.ALTERNATE_CONTACT_NUMBER]: {
    onChange: (e, updateKey, setError) => {
      const { value } = e.target
      const sanitizedValue = value.replace(/\s|-/g, '').substring(0, 12)
      if (isNaN(sanitizedValue)) return
      const formattedValue = formattedPhoneNumber(sanitizedValue)
      UpdateActivationState(updateKey, formattedValue)
      setError('')
    }
  },
  [ACTIVATION_EVENTS.PHONE_NUMBER_SETUP.POSTAL_CODE]: {
    onChange: (e, updateKey, setError) => {
      const { value } = e.target
      const sanitizedValue = sanitizeTextField(value)
      const postalArray = sanitizedValue
        .replace(/\s/g, '')
        .substring(0, 6)
        .match(/.{1,3}/g)

      setError('')
      if (postalArray && postalArray.length > 0) {
        UpdateActivationState(updateKey, postalArray.join(' '))
        return
      }
      UpdateActivationState(updateKey, sanitizedValue)
    },
    onBlur: (value, setError, requiredErrorMessage, invalidErrorMessage) => {
      if (value === '') {
        setError(requiredErrorMessage)
      } else if (!isPostalCode(value)) {
        setError(invalidErrorMessage)
      } else {
        setError(null)
      }
    }
  }
}

/**
 * @typedef {object} ActivationContextAPI
 *
 * @property {object} state - current app state, ie: current language
 * @property {React.Dispatch<any>} setState - the set state method for the current app state
 *
 */

ActivationProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default ActivationProvider
