/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  APPLICATION_STATE,
  ApplicationContext,
  getProvince
} from '@apps/contexts/ApplicationContext'
import { ACCOUNT_SETUP_STATE } from '@apps/redux/features/AccountSetupSlice'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import Button from '@/apps/components/Button'
import Input from '@/apps/components/Input'
import Notification from '@apps/components/Notification'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import AccountHelpers from '@apps/flows/activation/services/accountHelper'
import { ContentContext } from '@apps/contexts/ContentContext'
import Heading4 from '@apps/components/Typography/Heading4'

// import PaymentProvider, {
//   PaymentContext,
//   PAYMENT_STATE,
//   PAYMENT_EVENTS,
//   ModifyPaymentContext,
//   referAFriend as referAFriendInitState,
//   promoCode as promoCodeInitState
// } from '@apps/flows/activation/contexts/PaymentContext'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import BodySmall from '@apps/components/Typography/BodySmall'
import Link from '@apps/components/Link'
import Sites from '@/sites'
import useSite from '@engine/hooks/useSite'
import { PaymentContext,PAYMENT_STATE,ModifyPaymentContext,referAFriend as referAFriendInitState,
    promoCode as promoCodeInitState,setPaymentInformation,updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import MultiRafCode from '@apps/components/MultiRafCode'
import MultiPromoCode from '@apps/components/MultiPromoCode'
import PaymentChangeHandlers, {
  PAYMENT_EVENTS
} from '@apps/utils/ChangeHandlers/PaymentChangeHandlers'

const PromoCode = (textContent) => {
  const site = useSite()
  const dispatch = useDispatch()
  const {
    content: { pageContent },
    provinces
  } = useSelector((state) => state.common)
  const { paymentInformation: paymentInfo } = useSelector((state) => state.payAndReviewPage)

  const [promoCodeVal, setPromoCode] = useState('')
  const [validationCodeVal, setvalidationCode] = useState('')
  const {
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.IDENTITY]: identity,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage
  } = useContext(ApplicationContext)

  const {
    payment: {
      friendRefCode,
      friendRefCodeText,
      add,
      promo: promoTitle,
      promoCodeText,
      ratePlanPage,
      ratePlanPageLink,
      validationCode,
      succesfulPromoUpdateMessage,
      enterFriendCode,
      enterPromoCode,
      enterValidationCode,
      promotionCodeInvalid,
      friendRefCodeInvalid,
      friendRefCodeUsageExceeded,
      validationCodeInvalid
    },
    provinceRequired,
    dynamicContent
  } = useContext(ContentContext)

  const { [ACCOUNT_SETUP_STATE.PROVINCE]: provinceInfo } = useSelector(state => state.accountSetupPage)
  const { [PLANS_STATE.PLAN]: plansInfo } = useSelector((state) => state.plansPage)

  const paymentState = useSelector((state) => state.payAndReviewPage)

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const {
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: friendReferralCode,
    [PAYMENT_STATE.PROMO.PROMOTION_CODE]: promoCode,
    [PAYMENT_STATE.PROMO.PROMOTION_VALIDATION_CODE]: promoValidationCode,
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_SETTINGS]: friendReferralSettings
  } = paymentState

  const { isValidReferral, validatePromotion } = brandServices
  const { verifiedCheckMark, close } = useContext(AssetsContext)
  const [promoCodeError, setPromoCodeError] = useState('')
  const [promoCodeValidationError, setPromoCodeValidationError] = useState('')
  const [friendReferralCodeError, setFriendReferralCodeError] = useState('')
  const [isValidFriendReferralCode, setIsValidFriendReferralCode] = useState(false)
  const [isValidPromoCode, setIsValidPromoCode] = useState(false)
  const [isValidPromoValidationCode, setIsValidPromoValidationCode] = useState(false)
  const [isFriendReferralLoading, setIsFriendReferralLoading] = useState(false)
  const [isPromoCodeLoading, setIsPromoCodeLoading] = useState(false)
  const [isPromoValidationCodeLoading, setIsPromoValidationCodeLoading] = useState(false)
  const [isPromoValidationRequired, setIsPromoValidationRequired] = useState(false)
  const [showPromoUpdateNotification, setShowPromoUpdateNotification] = useState(false)
  const [promoBundles, setPromoBundles] = useState([])

  const checkIfCodeIsValid = (dCode) => {
    console.log('checkIfCodeIsValid called')
    return new Promise((resolve, reject) => {
      resolve(dCode.length > 4)
    })
  }

  useEffect(() => {
    if (
      (isValidPromoCode && isPromoValidationRequired && isValidPromoValidationCode) ||
      (isValidPromoCode && !isPromoValidationRequired)
    ) {
      removePCode()
    }
  }, [provinceInfo, plansInfo])

  const validateFriendCode = (value) => {
    if (!value) setFriendReferralCodeError(textContent?.shortTextFields?.enterCode)
    else setFriendReferralCodeError('')
  }

  const validatePromoCodeFields = (value) => {
    if (!value) setPromoCodeError(textContent?.shortTextFields?.enterCode)
    else setPromoCodeError('')
  }

  const validatePromoCodeValidationFields = (value) => {
    if (!value) setPromoCodeValidationError(textContent?.shortTextFields?.enterCode)
    else setPromoCodeValidationError('')
  }

  const removeFriendReferralCode = () => {
    setIsValidFriendReferralCode(false)
    // dispatch(
    //   setPaymentInformation({
    //     ...paymentInfo,
    //     rafCode: ''
    //   })
    // )
    updatePaymentStore({
      ...paymentState,
      ...referAFriendInitState,
      [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
    })
  }
  const removePCode = (showNotification = true) => {
    if (showNotification) setShowPromoUpdateNotification(true)
    setPromoBundles([])
    setIsValidPromoCode(false)
    setIsValidPromoValidationCode(false)
    setIsPromoValidationRequired(false)
    // dispatch(
    //   setPaymentInformation({
    //     ...paymentInfo,
    //     promoCode: '',
    //     promoCodeId: ''
    //   })
    // )
    updatePaymentStore({
      ...paymentState,
      ...promoCodeInitState
    })
  }

  const addPromoCode = async () => {
    console.log('Provinces', provinces)
    validatePromoCodeFields(promoCode)
    if (promoCode) {
      if (!provinces) {
        setPromoCodeError(textContent?.shortTextFields?.provinceRequired)
        return
      }
      if (!plansInfo.id) {
        setPromoCodeError('Please select a plan to continue')
        return
      }

      const { shortName = '' } = getProvince(provinceInfo)
      setIsPromoCodeLoading(true)

      const data = [
        plansInfo.id,
        identity.channelOrgCode,
        shortName,
        promoCode,
        '',
        currentLanguage
      ]

      const promo = await AccountHelpers.validatePromoCode(validatePromotion, ...data)
      if (promo) {
        if (promo.bundles) {
          setPromoBundles(promo.bundles)
        }
        setIsValidPromoCode(true)
        setPromoCode(promoCode)
        updatePaymentStore({
          ...paymentState,
          [PAYMENT_STATE.PROMO.PROMOTION_CODE]: promoCode,
          [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]:
            promo.code && promo.code === 'VALIDATION_CODE' ? undefined : promo
        })
        if (promo.code && promo.code === 'VALIDATION_CODE') {
          setIsPromoValidationRequired(true)
        } else {
          // dispatch(
          //   setPaymentInformation({
          //     ...paymentInfo,
          //     promoCode: promoCode,
          //     promoCodeId: promo?.id
          //   })
          // )
          setShowPromoUpdateNotification(true)
        }
      } else {
        setPromoCodeError(textContent?.shortTextFields?.promotionCodeInvalid)
      }
      setIsPromoCodeLoading(false)
    }
  }

  const addFriendRefrralCode = async () => {
    validateFriendCode(friendReferralCode)
    if (friendReferralCode) {
      setIsFriendReferralLoading(true)
      const { referral, error } =
        (await AccountHelpers.applyReferralCode(
          isValidReferral,
          friendReferralCode,
          friendReferralSettings
        )) || {}
      if (referral) {
        setIsValidFriendReferralCode(true)
        // dispatch(
        //   setPaymentInformation({
        //     ...paymentInfo,
        //     rafCode: friendReferralCode
        //   })
        // )
        updatePaymentStore({
          ...paymentState,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: friendReferralCode,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_INFO]: referral,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: true
        })
        setIsFriendReferralLoading(false)
      } else {
        const { code: errorCode } = error || {}
        const errorMessage =
          errorCode != '429'
            ? textContent?.shortTextFields?.friendRefCodeInvalid
            : textContent?.shortTextFields?.friendRefCodeUsageExceeded
        setIsValidFriendReferralCode(false)
        setFriendReferralCodeError(errorMessage)
        updatePaymentStore({
          ...paymentState,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
        })
        setIsFriendReferralLoading(false)
      }
    }
  }

  const addValidationCode = async () => {
    validatePromoCodeValidationFields(promoValidationCode)
    if (promoValidationCode) {
      setIsPromoValidationCodeLoading(true)

      const data = [
        plansInfo.id,
        identity.channelOrgCode,
        provinceInfo.shortName,
        promoCode,
        promoValidationCode,
        currentLanguage
      ]

      const promoValidation = await AccountHelpers.validatePromoCode(validatePromotion, ...data)
      if (promoValidation) {
        if (promoValidation) {
          setPromoBundles(promoValidation.bundles)
        }
        if (
          !promoValidation.code ||
          (promoValidation.code && promoValidation.code !== 'VALIDATION_CODE')
        ) {
          setIsValidPromoCode(true)
          setIsValidPromoValidationCode(true)
          setIsPromoValidationRequired(false)
          setvalidationCode(promoValidationCode)
          updatePaymentStore({
            ...paymentState,
            [PAYMENT_STATE.PROMO.PROMOTION_VALIDATION_CODE]: promoValidationCode,
            [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: promoValidation
          })
          // dispatch(
          //   setPaymentInformation({
          //     ...paymentInfo,
          //     promoCode: `${promoCodeVal}-${promoValidationCode}`,
          //     promoCodeId: promoValidation?.id
          //   })
          // )
          setShowPromoUpdateNotification(true)
        } else {
          setPromoCodeValidationError(textContent?.shortTextFields?.validationCodeInvalid)
        }
      } else {
        setPromoCodeValidationError(textContent?.shortTextFields?.validationCodeInvalid)
      }
      setIsPromoValidationCodeLoading(false)
    }
  }

  const getStyles = useCallback(
    (error) => {
      if (error) {
        return 'items-center'
      }
      if (isValidPromoCode || isValidPromoValidationCode) {
        return 'items-start'
      }
      return 'items-end'
    },
    [isValidPromoCode, isValidPromoValidationCode, promoCodeValidationError]
  )

  return (
    <>
      {!dynamicContent ||
        (!dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo && (
          <div className="w-full space-y-4">
            <div className="flex flex-col items-start space-x-0 space-y-4 lg:space-y-0 md:space-y-0 md:space-x-4 lg:space-x-4 md:flex-row lg:flex-row">
              <div className="flex flex-col flex-1 w-full">
                <div className="flex flex-row w-full">
                  <div className="flex flex-col flex-1">
                    <label
                      className={`block text-unselected-text ${
                        isValidFriendReferralCode ? 'text-disabled' : ''
                      }`}
                      htmlFor="friendReferralCode"
                    >
                      <Heading4>{textContent?.shortTextFields?.friendRefCode}</Heading4>
                      {site === Sites.consumer ? (
                        <p
                          className={
                            currentLanguage === 'en'
                              ? 'text-copy text-sm font-body h-20'
                              : 'text-copy text-sm font-body h-24'
                          }
                        >
                          {textContent?.shortTextFields?.friendRefCodeText}
                        </p>
                      ) : (
                        ''
                      )}
                    </label>
                    <div className="flex flex-row">
                      <div className="flex flex-col items-start flex-1">
                        <Input
                          type="text"
                          id="friendReferralCode"
                          name="friendReferralCode"
                          value={isValidFriendReferralCode ? '' : friendReferralCode}
                          onChange={(e) => {
                            e.preventDefault()
                            PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                              e.target.value,
                              validateFriendCode,
                              PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE,
                              updatePaymentStore
                            )
                          }}
                          onFocus={() => setFriendReferralCodeError('')}
                          errorMessage={friendReferralCodeError}
                          maxLength="7"
                          disabled={isValidFriendReferralCode}
                          isLoading={isFriendReferralLoading}
                          internalLoader
                          className={`${isValidFriendReferralCode ? 'opacity-50' : ''}`}
                          data-testid="payment-referralInput"
                        />
                        {isValidFriendReferralCode && (
                          <div className="flex flex-row content-between w-full pt-1">
                            <div className="flex flex-row items-center flex-1">
                              <img
                                className="mr-6"
                                width="14"
                                height="14"
                                alt="valid"
                                src={verifiedCheckMark}
                                data-testid="payment-referralInput-valid"
                              />
                              <BodySmall className="text-primary">{friendReferralCode}</BodySmall>
                            </div>
                            <div
                              className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125"
                              onClick={removeFriendReferralCode}
                            >
                              <img width="24" height="24" alt="close" src={close} />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-2">
                        <Button
                          disabled={isValidFriendReferralCode || isFriendReferralLoading}
                          onClick={addFriendRefrralCode}
                          label={textContent?.shortTextFields?.add}
                          className={`ml-2 h-11 ${isValidFriendReferralCode ? 'opacity-50' : ''}`}
                          testId="payment-referralButton"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1 w-full space-y-4">
                {/* <div className="flex flex-col flex-1">
                  <div className="flex flex-row w-full">
                    <div className="flex flex-col flex-1">
                      <label
                        className={`block text-unselected-text ${
                          isValidFriendReferralCode ? 'text-disabled' : ''
                        }`}
                        htmlFor="friendReferralCode"
                      >
                        <Heading4>{textContent?.shortTextFields?.promo}</Heading4>
                        {site === Sites.consumer ? (
                          <p
                            className={
                              currentLanguage === 'en'
                                ? 'text-copy text-sm font-body h-20'
                                : 'text-copy text-sm font-body h-24'
                            }
                          >
                            {textContent?.shortTextFields?.promoCodeText}
                            <Link
                              target="_blank"
                              href={textContent?.shortTextFields?.ratePlanPageLink}
                              className="font-h1 text-primary "
                            >
                              {textContent?.shortTextFields?.ratePlanPage}
                            </Link>
                          </p>
                        ) : (
                          ''
                        )}
                      </label>
                      <div className="flex flex-row">
                        <div className="flex flex-col items-start flex-1">
                          <Input
                            type="text"
                            id="friendReferralCode"
                            name="friendReferralCode"
                            value={isValidFriendReferralCode ? '' : friendReferralCode}
                            onChange={(e) => {
                              e.preventDefault()
                              PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                                e.target.value,
                                validateFriendCode,
                                PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE,
                                updatePaymentStore
                              )
                            }}
                            onFocus={() => setFriendReferralCodeError('')}
                            errorMessage={friendReferralCodeError}
                            maxLength="7"
                            disabled={isValidFriendReferralCode}
                            isLoading={isFriendReferralLoading}
                            internalLoader
                            className={`${isValidFriendReferralCode ? 'opacity-50' : ''}`}
                            data-testid="payment-referralInput"
                          />
                          {isValidFriendReferralCode && (
                            <div className="flex flex-row content-between w-full pt-1">
                              <div className="flex flex-row items-center flex-1">
                                <img
                                  className="mr-6"
                                  width="14"
                                  height="14"
                                  alt="valid"
                                  src={verifiedCheckMark}
                                  data-testid="payment-referralInput-valid"
                                />
                                <BodySmall className="text-primary">{friendReferralCode}</BodySmall>
                              </div>
                              <div
                                className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125"
                                onClick={removeFriendReferralCode}
                              >
                                <img width="24" height="24" alt="close" src={close} />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="mt-2">
                          <Button
                            disabled={isValidFriendReferralCode || isFriendReferralLoading}
                            onClick={addFriendRefrralCode}
                            label={add}
                            className={`ml-2 h-11 flex justify-center ${
                              isValidFriendReferralCode ? 'opacity-50' : ''
                            }`}
                            testId="payment-referralButton"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="flex flex-col flex-1 w-full space-y-4">
                  <div className="flex flex-col flex-1">
                    <div className={`flex flex-row w-full ${getStyles(promoCodeError)}`}>
                      <div className="flex flex-col flex-1">
                        <label
                          className={`block text-unselected-text ${
                            isValidPromoCode || isValidPromoValidationCode ? 'text-disabled' : ''
                          }`}
                          htmlFor="promoCode"
                        >
                          <Heading4>{promoTitle}</Heading4>
                          <p
                            className={
                              currentLanguage === 'en'
                                ? 'text-copy text-sm font-body h-20'
                                : 'text-copy text-sm font-body h-24'
                            }
                          >
                            {promoCodeText}
                            <Link
                              target="_blank"
                              href={ratePlanPageLink}
                              className="font-h1 text-primary "
                            >
                              {ratePlanPage}
                            </Link>
                          </p>
                        </label>
                        <div className="flex flex-row items-start flex-1">
                          <div className="flex flex-col flex-1">
                            <Input
                              type="text"
                              id="promoCode"
                              name="promoCode"
                              value={isValidPromoCode ? '' : promoCode}
                              onChange={(e) => {
                                e.preventDefault()
                                PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                                  e.target.value,
                                  validatePromoCodeFields,
                                  PAYMENT_STATE.PROMO.PROMOTION_CODE,
                                  updatePaymentStore
                                )
                              }}
                              onFocus={() => setPromoCodeError('')}
                              errorMessage={promoCodeError}
                              maxLength="30"
                              disabled={isValidPromoCode || isValidPromoValidationCode}
                              isLoading={isPromoCodeLoading}
                              internalLoader
                              className={`${
                                isValidPromoCode || isValidPromoValidationCode ? 'opacity-50' : ''
                              }`}
                              data-testid="payment-promoInput"
                            />
                            <div className="mt-2">
                              {isValidPromoCode &&
                                !isPromoValidationRequired &&
                                promoBundles &&
                                promoBundles.length > 0 && (
                                  <div className="flex flex-col content-between">
                                    <div className="flex flex-row items-center flex-1 mb-1">
                                      <div className="flex flex-row items-center flex-1">
                                        <img
                                          className="mr-6"
                                          width="14"
                                          height="14"
                                          alt="valid"
                                          src={verifiedCheckMark}
                                          data-testid="payment-promoInput-valid"
                                        />
                                        <BodySmallBold className="text-primary">
                                          {promoCode}
                                        </BodySmallBold>
                                      </div>
                                      <div
                                        className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125"
                                        onClick={removePCode}
                                      >
                                        <img width="24" height="24" alt="close" src={close} />
                                      </div>
                                    </div>
                                    {promoBundles.map((bundle) => (
                                      <div
                                        className="flex flex-row items-center flex-1 mb-1"
                                        key={bundle.id}
                                      >
                                        <div className="flex flex-row items-center flex-1">
                                          <BodySmall className="pl-10 pr-10 text-primary">
                                            {bundle.name}
                                          </BodySmall>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </div>
                            {isValidPromoCode && isPromoValidationRequired && (
                              <div className="flex flex-row content-between">
                                <div className="flex flex-row items-center flex-1">
                                  <img
                                    className="mr-6"
                                    width="14"
                                    height="14"
                                    alt="valid"
                                    src={verifiedCheckMark}
                                    data-testid="payment-promoInput-valid"
                                  />
                                  <BodySmall className="text-primary">{promoCode}</BodySmall>
                                </div>
                                <div
                                  className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125"
                                  onClick={() => removePCode(false)}
                                >
                                  <img width="24" height="24" alt="close" src={close} />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="mt-2">
                            <Button
                              disabled={
                                isValidPromoCode || isValidPromoValidationCode || isPromoCodeLoading
                              }
                              onClick={addPromoCode}
                              className={`ml-2 h-11 ${
                                isValidPromoCode || isValidPromoValidationCode ? 'opacity-50' : ''
                              }`}
                              label={add}
                              testId="payment-promoButton"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:space-y-0 lg:flex-row lg:space-x-6">
                    {isValidPromoCode && isPromoValidationRequired && (
                      <div
                        className={`pr-2 flex flex-row w-full ${getStyles(
                          promoCodeValidationError
                        )}`}
                      >
                        <div className="flex flex-col flex-1">
                          <label className="block text-unselected-text" htmlFor="validationCode">
                            <Heading4>{validationCode}</Heading4>
                          </label>
                          <div className="flex flex-row">
                            <Input
                              type="text"
                              id="validationCode"
                              name="validationCode"
                              value={isValidPromoValidationCode ? '' : promoValidationCode}
                              onChange={(e) => {
                                e.preventDefault()
                                PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                                  e.target.value,
                                  validatePromoCodeValidationFields,
                                  PAYMENT_STATE.PROMO.PROMOTION_VALIDATION_CODE,
                                  updatePaymentStore
                                )
                              }}
                              onFocus={() => setPromoCodeValidationError('')}
                              errorMessage={promoCodeValidationError}
                              maxLength="30"
                              isLoading={isPromoValidationCodeLoading}
                              internalLoader
                              data-testid="payment-validationInput"
                            />
                            <div className="relative mt-2">
                              <Button
                                onClick={addValidationCode}
                                className="ml-2 h-11"
                                label={add}
                                testId="payment-validationButton"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Notification
                show={showPromoUpdateNotification}
                setVisibility={setShowPromoUpdateNotification}
              >
                <img src={verifiedCheckMark} alt="check" />
                <BodySmall className="ml-2 overflow-hidden w-100">
                  {textContent?.shortTextFields?.succesfulPromoUpdateMessage}
                </BodySmall>
              </Notification>
            </div>
          </div>
        ))}

      {dynamicContent && dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo && (
        <MultiRafCode />
      )}

      {dynamicContent && dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo && (
        <MultiPromoCode />
      )}
    </>
  )
}

export default PromoCode
