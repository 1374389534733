import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { PAYMENT_OPTIONS } from '@apps/utils/constants'

import {
  ACCOUNT_SETUP_STATE,
  AccountSetupComplete
} from '@apps/flows/activation/contexts/AccountContext'

/**
 * @type {React.Context<function(): {}>} PaymentContext
 *
 * Initialized with plans defaults and can be modified via the ModifyPaymentContext
 */
export const PaymentContext = createContext(undefined)

/**
 * @type {React.Context<function(): {}>} ModifyPaymentContext
 *
 * Initialized with payment state setter function to modify key/val pairs
 */
export const ModifyPaymentContext = createContext(undefined)

/**
 * @enum PAYMENT_STATE - supported payment state elements throughout the platform
 *
 * @type {{FRIEND_REFERRAL_CODE): string}} - referral code
 *
 * @type {{PROMOTION_CODE): string}} - promotion code
 *
 */
export const PAYMENT_STATE = {
  RAF: {
    FRIEND_REFERRAL_CODE: 'friendReferralCode',
    FRIEND_REFERRAL_CODE_INFO: 'friendReferralCodeInfo',
    FRIEND_REFERRAL_CODE_IS_VALID: 'friendReferralCodeIsValid',
    FRIEND_REFERRAL_SETTINGS: 'friendReferralSettings'
  },
  PROMO: {
    PROMOTION_CODE: 'promotionCode',
    PROMOTION_CODE_INFO: 'promotionCodeInfo',
    PROMOTION_VALIDATION_CODE: 'promotionValidationCode',
    MULTI_PROMOTION_CODE: 'multiPromotionCode',
    MULTI_PROMOTION_CODE_LOADER: 'multiPromotionCodeLoader',
    MULTI_PROMOTION_CODE_INFO: 'multiPromotionCodeInfo',
    MULTI_PROMOTION_VALIDATION_CODE: 'multiPromotionValidationCode',
    MULTI_PROMO_CODES: 'multiPromoCodes'
  },
  VOUCHER: {
    ADDED_VOUCHERS: 'addedVouchers',
    INSUFFICIENT_AMOUNT_ERROR: 'insufficientAmountError'
  },
  SUMMARY: {
    AMOUNT_DUE: 'amountDue',
    AMOUNT_OWING: 'amountOwing',
    AMOUNT_FUNDS: 'amountFunds',
    AMOUNT_TOTAL_VOUCHER: 'amountTotalVoucher',
    AMOUNT_TAX: 'amountTax'
  },
  CC_INFO: {
    CREDIT_CARD_NUMBER: 'creditCardNumber',
    EXPIRY_DATE: 'expiryDate',
    SECURITY_CODE: 'securityCode',
    POSTAL_CODE: 'postalCode',
    CREDIT_CARD_NUMBER_ERROR: 'creditCardNumberError',
    EXPIRY_DATE_ERROR: 'expiryDateError',
    SECURITY_CODE_ERROR: 'securityCodeError',
    POSTAL_CODE_ERROR: 'postalCodeError',
    CC_API_VALIDATION_ERROR: 'ccApiValidationError',
    MASKED: 'masked'
  },
  TERMS_OF_SERVICE: 'termsOfService',
  VOUCHER_CODE: 'voucherCode',
  AUTO_TOP_UPS: 'autoTopUps',
  TAX: 'tax',
  SUB_TOTAL: 'subtotal',
  TOTAL: 'total',
  SELECTED_PAYMENT_OPTION: 'selectedPaymentOption',
  PAYMENT_GATEWAY: 'paymentGateway',
  PAYMENT_GATEWAY_DEALER: 'paymentGatewayDealer',
  PAYMENT_ALLOWED_COUNTRY_CODES: 'paymentAllowedCountryCodes',
  PAYMENT_CVV_RESP_VAL: 'paymentcvvResponseValue',
  PAYMENT_AVS_POSTAL_RESP_VAL: 'paymentAvsPostalResponse',
  PAYMENT_ADDRESS_RESP_VAL: 'paymentAddressResponseVal',
  PAYMENT_SCROLL_TO_ERR_FIELD: 'paymentScrollToErrField',
  TRIGGER_EPS_CARD_VALIDATION: 'triggerEPSCardValidation',
  IS_COMPLETE: 'isComplete'
}

export const referAFriend = {
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: '',
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_INFO]: '',
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
}

export const promoCode = {
  [PAYMENT_STATE.PROMO.PROMOTION_CODE]: '',
  [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: '',
  [PAYMENT_STATE.PROMO.PROMOTION_VALIDATION_CODE]: '',
  [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE]: '',
  [PAYMENT_STATE.MULTI_PROMOTION_CODE_LOADER]: false,
  [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: [],
  [PAYMENT_STATE.PROMO.MULTI_PROMOTION_VALIDATION_CODE]: '',
  [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: []
}

export const paymentVoucher = {
  [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: [],
  [PAYMENT_STATE.VOUCHER.INSUFFICIENT_AMOUNT_ERROR]: false
}

export const paymentSummary = {
  [PAYMENT_STATE.SUMMARY.AMOUNT_DUE]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_FUNDS]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]: (0.0).toFixed(2)
}

export const ccInfo = {
  [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: '',
  [PAYMENT_STATE.CC_INFO.EXPIRY_DATE]: '',
  [PAYMENT_STATE.CC_INFO.SECURITY_CODE]: '',
  [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: '',
  [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.MASKED]: ''
}

// setup our initial state for our context
const initialState = {
  ...referAFriend,
  ...promoCode,
  ...paymentVoucher,
  ...paymentSummary,
  ...ccInfo,
  [PAYMENT_STATE.TERMS_OF_SERVICE]: false,
  [PAYMENT_STATE.AUTO_TOP_UPS]: true,
  [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: PAYMENT_OPTIONS.CREDIT_CARD,
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_SETTINGS]: null,
  [PAYMENT_STATE.PAYMENT_GATEWAY]: 'SALT',
  [PAYMENT_STATE.PAYMENT_GATEWAY_DEALER]: 'SALT',
  [PAYMENT_STATE.PAYMENT_ALLOWED_COUNTRY_CODES]: ['124'],
  [PAYMENT_STATE.PAYMENT_CVV_RESP_VAL]: ['MATCHED'], 
  [PAYMENT_STATE.PAYMENT_AVS_POSTAL_RESP_VAL]: ['MATCHED'],
  [PAYMENT_STATE.PAYMENT_ADDRESS_RESP_VAL]: ['MATCHED', 'NOT_MATCHED', 'NOT_CHECKED'],
  [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false,
  [PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD]: null,
  [PAYMENT_STATE.IS_COMPLETE]: false
}

let state
let setState

/**
 * @typedef {object} ActivationContextAPI
 *
 * @property {object} state - current app state, ie: current language
 * @property {React.Dispatch<any>} setState - the set state method for the current app state
 *
 */
const PaymentProvider = ({ children }) => {
  // eslint-disable-next-line
  ;[state, setState] = useState({
    ...initialState
  })

  return (
    <PaymentContext.Provider value={state}>
      <ModifyPaymentContext.Provider value={setState}>{children}</ModifyPaymentContext.Provider>
    </PaymentContext.Provider>
  )
}

/**
 * @typedef function UpdatePaymentState
 *
 * Updates the provided key,val in Payment State, without destroying other values
 *
 * @param key Target element
 * @param value Value for target element
 */
export const UpdatePaymentState = (key, value) => {
  setState((previousState) => {
    Object.assign(previousState, { [key]: value })
    const isComplete = PaymentComplete(previousState)
    return {
      ...previousState,
      [PAYMENT_STATE.IS_COMPLETE]: isComplete
    }
  })
}

export const UpdatePaymentStates = (newStates) => {
  setState((previousState) => {
    Object.assign(previousState, newStates)
    const isComplete = PaymentComplete(previousState)
    return {
      ...previousState,
      [PAYMENT_STATE.IS_COMPLETE]: isComplete
    }
  })
}

const isNullOrEmpty = (value) => {
  if (value === '' || value === null) {
    return true
  }
  return false
}

export const PaymentComplete = (newState) => {
  const flow = newState[PAYMENT_STATE.SELECTED_PAYMENT_OPTION]

  if (!newState[PAYMENT_STATE.TERMS_OF_SERVICE]) {
    return false
  }

  if (
    flow === PAYMENT_OPTIONS.CREDIT_CARD ||
    (flow === PAYMENT_OPTIONS.VOUCHER && newState[PAYMENT_STATE.AUTO_TOP_UPS])
  ) {
    const fields = [
      [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER],
      [PAYMENT_STATE.CC_INFO.EXPIRY_DATE],
      [PAYMENT_STATE.CC_INFO.SECURITY_CODE],
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE]
    ]
    const errors = [
      [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR],
      [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR],
      [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR],
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]
    ]

    const emptyRequiredField = fields.find((field) => isNullOrEmpty(newState[field]))
    if (emptyRequiredField) {
      return false
    }

    const hasError = errors.find((error) => !isNullOrEmpty(newState[error]))
    if (hasError) {
      return false
    }
    if (flow === PAYMENT_OPTIONS.VOUCHER && newState[PAYMENT_STATE.AUTO_TOP_UPS]) {

      // amount Number(amountDue) > 0 return false
      const diff = Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]) - Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_OWING])

      if (newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS] && newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].length > 0 && diff >= 0) {
        return true
      }
      return false
    }

    return true
  }


  if (flow === PAYMENT_OPTIONS.VOUCHER) {

    // amount Number(amountDue) > 0 return false
    const diff = Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]) - Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_OWING])

    if (newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS] && newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].length > 0 && diff >= 0) {
      return true
    }
  }

  return false
}

export const getVouchers = () => {
  return state[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].map((voucher) => voucher.id).join(', ')
}

export const getTotalPromoDiscounts = () => {
  const { bundles = [] } = state[PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]
  return bundles.reduce((a, b) => Number(a.fee) || 0 + Number(b.fee) || 0, 0)
}

/*
 * Event Functions
 */

export const PAYMENT_EVENTS = {
  VALIDATE_CODE: 'event.target.payment.validateCode',
  POSTAL_CODE: 'event.target.payment.postalCode'
}

PaymentProvider.Common = {
  [PAYMENT_EVENTS.VALIDATE_CODE]: {
    onChange: (value, validateCode, updateKey) => {
      if (/^[a-zA-Z0-9]*$/.test(value)) {
        validateCode(value)
        UpdatePaymentState(updateKey, value)
      }
    }
  },
  [PAYMENT_EVENTS.POSTAL_CODE]: {
    onChange: (value) => {
      const firstLetterMatch = new RegExp(/([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])/)
      const letterMatch = new RegExp(/([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])/)
      const digitMatch = new RegExp(/\d/)

      const stringArray = value.split('')

      if (stringArray.length > 3 && stringArray.indexOf(' ') === -1) {
        stringArray.splice(3, 0, ' ')
      }
      const letters = [0, 2, 5]
      const numbers = [1, 4, 6]

      let matches = true

      stringArray.forEach((char, i) => {
        if (letters.indexOf(i) !== -1) {
          if (i === 0 && !char.match(firstLetterMatch)) {
            matches = false
          } else if (!char.match(letterMatch)) {
            matches = false
          }
        } else if (numbers.indexOf(i) !== -1) {
          if (!char.match(digitMatch)) {
            matches = false
          }
        }
      })
      if (matches) {
        const result = stringArray.join('')
        UpdatePaymentState(PAYMENT_STATE.CC_INFO.POSTAL_CODE, result)
      }
    },
    onBlur: (errors) => {
      const value = state[PAYMENT_STATE.CC_INFO.POSTAL_CODE]
      if (value === '') {
        UpdatePaymentState(PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR, errors.postalCodeRequired)
        return true
      }
      const postalMatch = new RegExp(
        /([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])(.?)\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])\d/g
      )
      if (!value.match(postalMatch)) {
        UpdatePaymentState(PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR, errors.postalCodeInvalid)
        return true
      }
      UpdatePaymentState(PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR, null)
      return false


    }
  }
}

PaymentProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default PaymentProvider
