import React, {
  useState, useContext, useEffect, useRef
} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { PaymentContext,PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import { ContentContext } from '@apps/contexts/ContentContext'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import  CreditCardForm  from './CreditCardFormEPS'
import BodyError from '@apps/components/Typography/BodyError'
import { PAYMENT_OPTIONS } from '@apps/utils/constants'

const EPSCard = (
  {
      handleSIMActivation,
      textContent,
      setButtonIsLoading,
      hasInsufficientVoucherCodeError = false,
      voucherRequiredRef
  }
  ) => {
    const dispatch = useDispatch()

  const {
      [APPLICATION_STATE.SERVICES]: brandServices,
      [APPLICATION_STATE.CURRENT_LANGUAGE]: language
    } = useContext(ApplicationContext)

    const paymentState = useSelector((state) => state.payAndReviewPage)

  const {
    [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: triggerValidation,
    [PAYMENT_STATE.PAYMENT_ALLOWED_COUNTRY_CODES]: paymentAllowedCountryCodes,
    [PAYMENT_STATE.PAYMENT_CVV_RESP_VAL]: paymentcvvResponseValue,
    [PAYMENT_STATE.PAYMENT_AVS_POSTAL_RESP_VAL]: paymentAvsPostalResponse,
    [PAYMENT_STATE.PAYMENT_ADDRESS_RESP_VAL]: paymentAddressResponseVal,
    [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: ccApiValidationError,
    [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: addedVouchers,
    [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: selectedPaymentOption,
    [PAYMENT_STATE.TERMS_OF_SERVICE]: isTermsConditionsChecked
  } = paymentState

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }
  const postalRef = useRef(null)

  const { retrievePaymentSession } = brandServices

  const classes = {
      errorText: 'mvne-text-danger-100',
      rowOneClass: 'mb-11'
  }

  const {
      payment: {
        cardNumber: cardNumberTitle,
        expiryDate: expiryDateTitle,
        securityCode: securityCodeTitle,
        postalCode: postalCodeTitle,
        cardNumberRequired,
        expiryDateRequired,
        securityCodeRequired,
        postalZipRequired,
        cardNumberInvalid,
        expiryDateInvalidFormat,
        expiryDateExpired,
        cardNumberIncorrect,
        paymentError,
        paymentSessionExpiredError,
        securityCodeInvalid,
        postalZipInvalid
      },
      activation: { error }
    } = useContext(ContentContext)

  const {
    cannotProcessCreditCard
  } = error

  const [cardErrorMessage, setCardErrorMessage] = useState(null)
  const [expiryErrorMessage, setExpiryErrorMessage] = useState(null)
  const [cvvErrorMessage, setCvvErrorMessage] = useState(null)

  const [cardErrorCode, setCardErrorCode] = useState(null)
  const [expiryErrorCode, setExpiryErrorCode] = useState(null)
  const [cvvErrorCode, setCvvErrorCode] = useState(null)
  
  const [cardInputErrorCode, setCardInputErrorCode] = useState(null)
  const [expiryInputErrorCode, setExpiryInputErrorCode] = useState(null)
  const [cvvInputErrorCode, setCvvInputErrorCode] = useState(null)

  const [showCardError, setShowCardError] = useState(false)
  const [showExpiryDateError, setShowExpiryDateError] = useState(false)
  const [showCVVError, setShowCVVError] = useState(false)

  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState(null)
  const [postalCodeValue, setPostalCodeValue] = useState('')

  const [payClient, setPayClient] = useState(null)
  
  useEffect(() => {
    if(hasInsufficientVoucherCodeError && voucherRequiredRef) {
      voucherRequiredRef?.current !== undefined ?? voucherRequiredRef?.current.scrollIntoView({
        behavior: "smooth"
      })
    }

    if(triggerValidation && !hasInsufficientVoucherCodeError) {
      validateCardDetails()
    }
  }, [triggerValidation])



  useEffect( () => {
    if(cardErrorCode) {
      showCardNumberError(cardErrorCode)
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR] : cardErrorCode})
    } else {
      setCardErrorMessage('')
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR] : ''})
    }
  }, [cardErrorCode])

  useEffect( () => {

    if(showCardError) {
      if(cardInputErrorCode === null) { // means initial fields user not started typing clicked inside field and blur
        setCardErrorCode('empty_field')
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR] : 'empty_field'})
      } else {
        setCardErrorCode(cardInputErrorCode)
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR] : cardInputErrorCode})
      }
    } else {
      setCardErrorCode('')
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR] : ''})
    }

  }, [showCardError])

  useEffect( () => {
    if(showCVVError) {
      if(cvvInputErrorCode === null) { // means initial fields user not started typing clicked inside field and blur
        setCvvErrorCode('empty_field')
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR] : 'empty_field'})
      } else {
        setCvvErrorCode(cvvInputErrorCode)
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR] : cvvInputErrorCode})
      }
    } else {
      setCvvErrorCode('')
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR] : ''})
    }

  }, [showCVVError])

  
  useEffect( () => {
    if(showExpiryDateError) {

      if(expiryInputErrorCode === null) { // means initial fields user not started typing clicked inside field and blur
        setExpiryErrorCode('empty_field')
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR] : 'empty_field'})
      } else {
        setExpiryErrorCode(expiryInputErrorCode)
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR] : expiryInputErrorCode})
      }
    } else {
      setExpiryErrorCode('')
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR] : ''})
    }

  }, [showExpiryDateError])

  useEffect( () => {
    if(expiryErrorCode) {
      generateExpiryDateError(expiryErrorCode)
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR] : expiryErrorCode})
    } else {
      setExpiryErrorMessage('')
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR] : ''})
    }
  }, [expiryErrorCode])

  useEffect( () => {
    if(cvvErrorCode) {
      generateCVVError(cvvErrorCode)
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR] : cvvErrorCode})
    } else {
      setCvvErrorMessage('')
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR] : ''})
    }
  }, [cvvErrorCode])

  const createEpsPaymentForm = async () => {
    const sessionResp = await retrievePaymentSession() 
    const { data: bearer, error } = sessionResp

    const options = {
        font: "15px Roboto, Open Sans, Segoe UI, sans-serif",
        fontColor: "#394056",
        backgroundColor: "#fff",
        placeHolderColor: "#6A7080"
      };
      
      const client = epsClient.init(bearer, options);
      setPayClient(client)
  
      let cardNumberInput = client.addField("cardNumber", "CardNumber",  {
        placeHolder: " ",
      })
  
      let expiryDateInput = client.addField("expiryDate", "ExpiryDate", {
        placeHolder: " ", 
      })
  
      let cvvInput = client.addField("cvv", "Cvv", {
        placeHolder: " ", 
      })

      let iframeCard = document.getElementById('iframe_cardNumber')
      let iframeExpiry = document.getElementById('iframe_expiryDate')
      let iframeCvv = document.getElementById('iframe_cvv')
  
      if (iframeCard && iframeExpiry && iframeCvv) {
        iframeCard.setAttribute("width", "369");
        iframeCard.setAttribute("height", "42");
        iframeExpiry.setAttribute("width", "369");
        iframeExpiry.setAttribute("height", "42");
        iframeCvv.setAttribute("width", "174");
        iframeCvv.setAttribute("height", "42");
      }

      cardNumberInput?.addEventListener("blur", (e) => {

        setShowCardError(true)

      })
  
      cardNumberInput?.addEventListener("input", (e) => {
        if(e.detail.error.code) {
          setCardInputErrorCode(e.detail.error.code)
        } else {
          setCardInputErrorCode('')
        }
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR] : ''})
      })

      expiryDateInput?.addEventListener("blur", (e) => {
        setShowExpiryDateError(true)
      })

      expiryDateInput?.addEventListener("input", (e) => {

        if(e.detail.error.code) {
          setExpiryInputErrorCode(e.detail.error.code)
        } else {
          setExpiryInputErrorCode('')
        }

      })

      cvvInput?.addEventListener("blur", (e) => {
        setShowCVVError(true)
      })
      
      cvvInput?.addEventListener("input", (e) => {
        if(e.detail.error.code) {
          setCvvInputErrorCode(e.detail.error.code)
        } else {
          setCvvInputErrorCode('')
        }
    
      })

      cardNumberInput?.addEventListener("focus", (e) => {
        setShowCardError(false)
      })
  
      expiryDateInput?.addEventListener("focus", (e) => {
        setShowExpiryDateError(false)
      })
  
      cvvInput?.addEventListener("focus", (e) => {
        setShowCVVError(false)
      })
  }

  useEffect(() => {
    createEpsPaymentForm()
  }, [])

  useEffect( () => {

    if(cardErrorCode) {
      showCardNumberError(cardErrorCode)
    }
   
    if(expiryErrorCode) {
      generateExpiryDateError(expiryErrorCode)
    }

    if(cvvErrorCode) {
      generateCVVError(cvvErrorCode)
    }

    if(postalCodeErrorMessage) {
      showPostalCodeError()
    }

  }, [language])


  const generateCVVError = (code, msg) => {

    switch(code) {
      case 'empty_field':
        setCvvErrorMessage(textContent?.shortTextFields?.securityCodeRequired)
        break;
      case 'incomplete_cvv':
        setCvvErrorMessage(textContent?.shortTextFields?.securityCodeInvalid)
        break;
      default:
        setCvvErrorMessage(textContent?.shortTextFields?.securityCodeInvalid)
        console.error(code)
        break
    }
   
  }


  const showCardNumberError = (code, msg) => {

    switch(code) {
      case 'empty_field':
        setCardErrorMessage(textContent?.shortTextFields?.cardNumberRequired)
        break
      case 'incomplete_number':
        setCardErrorMessage(textContent?.shortTextFields?.cardNumberInvalid)
        break
      default:
        setCardErrorMessage(textContent?.shortTextFields?.cardNumberIncorrect)
        console.error(code)
        break
    }

  } 

  const generateExpiryDateError = (code, msg) => {

    switch(code) {
      case 'empty_field':
        setExpiryErrorMessage(textContent?.shortTextFields?.expiryDateRequired)
        break
      case 'incomplete_date':
        setExpiryErrorMessage(textContent?.shortTextFields?.expiryDateInvalidFormat)
        break
      case 'past_date':
        setExpiryErrorMessage(textContent?.shortTextFields?.expiryDateExpired)
        break
      default:
        setExpiryErrorMessage(textContent?.shortTextFields?.expiryDateInvalidFormat)
        console.error(code)
        break
    }
 
  }


  const isValidCardIssuer = (issuerCountry) => {
    if(paymentAllowedCountryCodes.includes('All countries')) {
      return true
    }
    if(paymentAllowedCountryCodes.includes(issuerCountry)) {
      return true
    }
    return false
  }

  const isFraudDetected = ({avsAddressResponse = '', avsPostalCodeResponse = '', cvvResponse = ''}) => {

    if(!paymentAddressResponseVal.includes(avsAddressResponse) ||
        !paymentAvsPostalResponse.includes(avsPostalCodeResponse) ||
        !paymentcvvResponseValue.includes(cvvResponse)) {
      return true
    }
    return false

  }

  const validateCardDetails = async () => {

    let isValidForm = true
    const address = {
      line1: "100 york street",
      postalCode: postalCodeValue
    }

  if(cardInputErrorCode === null || cardInputErrorCode) {
    setShowCardError(true)
    if(isValidForm) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD] : 'cardNumber'})
    }
    isValidForm = false
  }
  if(expiryInputErrorCode === null || expiryInputErrorCode) {
    setShowExpiryDateError(true)
    if(isValidForm) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD] : 'expiryDate'})

    }
    isValidForm = false
  }
  if(cvvInputErrorCode === null || cvvInputErrorCode) {
    setShowCVVError(true)
    if(isValidForm) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD] : 'cvv'})
    }
    isValidForm = false
  }

  const isPostalCodeInvalid =  showPostalCodeError()

  if(isPostalCodeInvalid) {
    if(isValidForm) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD]: 'postal'})
    }
    isValidForm = false
  }

  if(!isTermsConditionsChecked) {
    if(isValidForm) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD] : 'tnc'})
    }
    isValidForm = false
  }

  if(selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER && addedVouchers.length === 0) {
    isValidForm = false
  }

  if (!isValidForm) {
    setButtonIsLoading(false)
    updatePaymentStore({[PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false})
    return
  }

   
  setButtonIsLoading(true)
  const res = await payClient.tokenize(address)

    if (res?.id && res?.cardInfo) {

      if(isFraudDetected(res)) {

        updatePaymentStore({
          [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false,
          [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: textContent?.shortTextFields?.cannotProcessCreditCard
        })
        setButtonIsLoading(false)
  
        // disable payment btn check 
        return
      }

      if(!isValidCardIssuer(res?.cardInfo?.countryOfIssue)) {
        updatePaymentStore({
          [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false,
          [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: textContent?.shortTextFields?.cannotProcessCreditCard
        })
        setButtonIsLoading(false)
        return
      }

      const maskedCardNumber = `${res?.cardInfo?.bin??'******'}******${res?.cardInfo?.lastFourDigits??'****'}`
      setButtonIsLoading(false)
      handleSIMActivation({
        STORAGE_TOKEN_ID: res?.id,
        STORAGE_TOKEN_EXPIRY: res?.cardInfo?.expiryMonth + res?.cardInfo?.expiryYear,
        CARD_NUMBER: maskedCardNumber
      })

      updatePaymentStore({
        [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false,
        [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: maskedCardNumber
      })

    } else {

      // highlight erroneous fields
      if(res?.error ) {
        // disable payment btn
        if(res?.containerId === 'CardNumber') {
          showCardNumberError(res?.error?.code, res?.error?.message)
        } else if(res?.containerId === 'ExpiryDate') {
          generateExpiryDateError(res?.error?.code, res?.error?.message)
        } else if(res?.containerId === 'Cvv') {
          generateCVVError(res?.error?.code, res?.error?.message)
        } else if(res?.error?.code === 401) {
          updatePaymentStore({[PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR] : textContent?.shortTextFields?.paymentSessionExpiredError})
        } else {
          updatePaymentStore({[PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: textContent?.shortTextFields?.cannotProcessCreditCard})
        }
      }

      setButtonIsLoading(false)

      updatePaymentStore({[PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false})

    }

  }

  const onChangePostalCode = (e) => {

      e.preventDefault()

      const value = e.target.value

      const firstLetterMatch = new RegExp(/([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])/)
      const letterMatch = new RegExp(/([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])/)
      const digitMatch = new RegExp(/\d/)

      const stringArray = value.split('')

      if (stringArray.length > 3 && stringArray.indexOf(' ') === -1) {
        stringArray.splice(3, 0, ' ')
      }
      const letters = [0, 2, 5]
      const numbers = [1, 4, 6]

      let matches = true

      stringArray.forEach((char, i) => {
        if (letters.indexOf(i) !== -1) {
          if (i === 0 && !char.match(firstLetterMatch)) {
            matches = false
          } else if (!char.match(letterMatch)) {
            matches = false
          }
        } else if (numbers.indexOf(i) !== -1) {
          if (!char.match(digitMatch)) {
            matches = false
          }
        }
      })
      if (matches) {
        const result = stringArray.join('')
        setPostalCodeValue(result)
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.POSTAL_CODE]: result})
      }

  }

  const showPostalCodeError = () => {

    const value = postalCodeValue
    if (value === '') {
      setPostalCodeErrorMessage(textContent?.shortTextFields?.postalZipRequired)
      return true
    }
    const postalMatch = new RegExp(
      /([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])(.?)\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])\d/g
    )
    if (!value.match(postalMatch)) {
      setPostalCodeErrorMessage(textContent?.shortTextFields?.postalZipInvalid)
      return true
    }
    setPostalCodeErrorMessage('')
    return false

  }

  const onBlurPostalCode = () => {
    showPostalCodeError()
  }

  const onFocusPostalCode = () => {
    setPostalCodeErrorMessage('')
  }

  return (
    <div className="space-y-4">

      {ccApiValidationError && (
        <BodyError>
          {ccApiValidationError}
        </BodyError>
      )}

      <div>
        <CreditCardForm 
            classes={classes}
            cardNumberId='CardNumber'
            cardErrorMessage={cardErrorMessage}
            cardNumberLabel={textContent?.shortTextFields?.cardNumber}
            postalCodeId='postalCodeCard'
            postalCodeLabel={textContent?.shortTextFields?.postalCode}
            postalErrorMessage={postalCodeErrorMessage}
            valuePostal={postalCodeValue}
            onChangePostal={onChangePostalCode}
            onBlurPostal={onBlurPostalCode}
            onFocusPostal={onFocusPostalCode}
            expiryDateId='ExpiryDate'
            expiryDateLabel={textContent?.shortTextFields?.expiryDate}
            expiryErrorMessage={expiryErrorMessage}
            cvvId='Cvv'
            labelSecurityCode={textContent?.shortTextFields?.securityCode}
            cvvErrorMessage={cvvErrorMessage}
            cvvSuggestionText=''
        />
      </div>

    </div>
  )
}

export default EPSCard
