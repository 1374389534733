import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BulletCard from '@apps/components/Cards/BulletCard'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Sites from '@sites/index'
import { getShortTextFromPage, getById } from '@apps/utils/contentful'
import Heading3 from '@apps/components/Typography/Heading3'
import BodyBold from '@apps/components/Typography/BodyBold'
import Body from '@apps/components/Typography/Body'

const SelfServerHeader = (props) => {
  const { pageContent } = useSelector((state) => state.common.content)
  const { userType } = useSelector((state) => state.common)
  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const text = useMemo(() => {
    if (!pageContent || !language || !userType) return {}
    const contentSuffix = isDealer ? 'Dealer' : 'Consumer'
    const accountSetupPageContent = getById(pageContent, `accountSetupPage${contentSuffix}`)?.[
      language
    ]
    return {
      selfServeHeading: getShortTextFromPage(accountSetupPageContent, 'selfServeHeading'),
      bulletCardTitle: getShortTextFromPage(accountSetupPageContent, 'bulletCardTitle'),
      bulletPointOne: getShortTextFromPage(accountSetupPageContent, 'bulletPointOne'),
      bulletPointTwo: getShortTextFromPage(accountSetupPageContent, 'bulletPointTwo')
    }
  }, [pageContent, language, userType])

  const { selfServeHeading, bulletCardTitle, bulletPointOne, bulletPointTwo } = text

  return (
    <div>
      <Heading3 className="mb-3 md:pt-8">{selfServeHeading}</Heading3>
      <BulletCard>
        <BodyBold className="pb-2 leading-6">{bulletCardTitle}</BodyBold>
        <div className="px-4">
          <ul className="list-disc list-outside text-copy pl-2">
            <li>
              <Body className="inline leading-6">{bulletPointOne}</Body>
            </li>
            <li>
              <Body className="inline leading-6">{bulletPointTwo}</Body>
            </li>
          </ul>
        </div>
      </BulletCard>
    </div>
  )
}

SelfServerHeader.propTypes = {}

export default SelfServerHeader
