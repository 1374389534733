import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { PLAN_DURATION } from '@apps/utils/constants'

/**
 * @type {React.Context<function(): {}>} PlansContext
 *
 * Initialized with plans defaults and can be modified via the ModifyPlansContext
 */
export const PlansContext = createContext(undefined)

/**
 * @type {React.Context<function(): {}>} ModifyPlansContext
 *
 * Initialized with plans state setter function to modify key/val pairs
 */
export const ModifyPlansContext = createContext(undefined)

/**
 * @enum PLANS_STATE - supported plans state elements throughout the platform
 *
 * @type {{SIM: string}} - SIM Number (19 digits)
 *
 */
export const PLANS_STATE = {
  PLAN: 'plan',
  PROMO: 'promo',
  PLANS: 'plans',
  SELECTED_PLAN_OPTION: 'selectPlanOption',
  PRE_SELECTED_PLAN: 'preSelectedPlan'
}

// setup our initial state for our context
const initialState = {
  [PLANS_STATE.PLAN]: {
    id: undefined,
    name: 'name',
    subtitle: 'subtitle',
    cost: 0,
    features: ['item', 'item2', 'item3'],
    options: []
  },
  [PLANS_STATE.PROMO]: null,
  [PLANS_STATE.PLANS]: { en: [], fr: [] },
  [PLANS_STATE.SELECTED_PLAN_OPTION]: PLAN_DURATION.SLOT_30,
  [PLANS_STATE.PRE_SELECTED_PLAN]: false
}

let state
let setState

/**
 * @typedef {object} ActivationContextAPI
 *
 * @property {object} state - current app state, ie: current language
 * @property {React.Dispatch<any>} setState - the set state method for the current app state
 *
 */
const PlansProvider = ({ children }) => {
  // eslint-disable-next-line
  ;[state, setState] = useState({
    ...initialState
  })

  return (
    <PlansContext.Provider value={state}>
      <ModifyPlansContext.Provider value={setState}>{children}</ModifyPlansContext.Provider>
    </PlansContext.Provider>
  )
}

/**
 * @typedef function UpdatePlansState
 *
 * Updates the provided key,val in Plans State, without destroying other values
 *
 * @param key Target element
 * @param value Value for target element
 */
export const UpdatePlansState = (key, value) => {
  setState({
    ...state,
    [key]: value
  })
}

/*
 * Event Functions
 */

export const PLANS_EVENTS = {}

PlansProvider.Common = {}

export const SelectedPlan = (language) => {
  return state[PLANS_STATE.PLANS][language].find(
    (item) => item && state[PLANS_STATE.PLAN] && item.id === state[PLANS_STATE.PLAN].id
  )
}

PlansProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default PlansProvider
