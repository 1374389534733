/* eslint-disable react/require-default-props */
import React, {
  useCallback, useState, forwardRef, useContext
} from 'react'
import PropTypes from 'prop-types'
import Error from '@apps/assets/error.svg'
import HideIcon from '@apps/assets/HideIcon'
import ShowIcon from '@apps/assets/ShowIcon'
import Loader, { SIZE } from '@apps/components/Loader'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import Heading4 from '../Typography/Heading4'
import BodyError from '../Typography/BodyError'
import ToolTipSvg from './tooltipSvg.svg'

const TYPE = {
  PASSWORD: 'password',
  SIM: 'sim',
  EMAIL: 'email',
  TEXT: 'text'
}

export const Input = ({
  id,
  name,
  label = "",
  labelStyles = "",
  type,
  onChange,
  onBlur,
  value,
  errorMessage,
  forwardedRef,
  disabled = false,
  onToolTipClick,
  onToolTipBlur,
  showToolTip,
  hasError = false,
  loading = false,
  valid,
  invalid = false,
  maxLength,
  internalLoader = false,
  isLoading,
  textAlignment = 'text-left',
  ...rest
}) => {
  const { circleCheck, exclamation } = useContext(AssetsContext)

  const [maskInputText, setMaskInputText] = useState(true)
  const options = { ...rest }

  const maskInputHandler = useCallback(() => {
    value !== undefined && value !== '' && setMaskInputText(!maskInputText)
  }, [maskInputText, value])

  const customErrorStyles =
    'border-red focus:shadow-danger'

  return (
    <div className="relative w-full">
      {label && (
        <div className="flex flex-row justify-between">
          <label
            className={`block text-unselected-text  ${disabled ? 'text-disabled' : ''}`}
            htmlFor={id}
          >
            <Heading4 className={labelStyles}>{label}</Heading4>
          </label>
          {showToolTip && (
            <button
              type="button"
              className="mb-0.2 focus:outline-none z-20"
              onClick={(e) => !disabled && onToolTipClick(e)}
              onBlur={onToolTipBlur}
            >
              <img src={ToolTipSvg} alt="tool tip" className="w-5 h-5" />
            </button>
          )}
        </div>
      )}
      <div className="relative flex items-center mt-2">
        <input
          type={type === TYPE.PASSWORD ? (maskInputText ? type : TYPE.TEXT) : type}
          className={`px-4 disabled:text-disabled disabled:border-disabled ${textAlignment} ${
            errorMessage && customErrorStyles
          }`}
          id={id}
          name={name}
          onChange={(e) => onChange && onChange(e)}
          value={value}
          onBlur={() => onBlur && onBlur()}
          disabled={disabled}
          ref={forwardedRef}
          autoComplete="chrome-off"
          maxLength={maxLength}
          {...options}
        />
        {internalLoader && (
          <Loader
            className="absolute top-auto bottom-auto left-auto right-7 z-index-10"
            size={SIZE.SM}
            loading={isLoading}
          />
        )}
        {(loading || valid || invalid) && (
        <div className="ml-4">
          {loading && <Loader loading size={SIZE.SM} data-testid={options["data-testid"] ? `${options["data-testid"]}-loading` : undefined} />}
          {valid && <img width="24" height="24" alt="valid" data-testid={options["data-testid"] ? `${options["data-testid"]}-valid` : undefined} src={circleCheck} />}
          {invalid && <img width="24" height="24" alt="invalid" src={exclamation} data-testid={options["data-testid"] ? `${options["data-testid"]}-invalid` : undefined} />}
        </div>
        )}
        {type === TYPE.PASSWORD && (
          <button
            type="button"
            className="absolute right-0 rounded-lg h-11 w-11 focus:shadow-active focus:outline-none"
            onClick={maskInputHandler}
          >
            {maskInputText ? (
              <HideIcon title="hide Password" />
            ) : (
              <ShowIcon title="show Password" />
            )}
          </button>
        )}
      </div>
      {!disabled && errorMessage && (
        <div className="flex items-center" data-testid={options["data-testid"] ? `${options["data-testid"]}-error` : undefined}>
          <img className="h-4 my-2" src={Error} alt="" />
          <BodyError className="ml-1 mt-0.5 leading-4">{errorMessage}</BodyError>
        </div>
      )}
    </div>
  )
}
Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelStyles: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.oneOf([TYPE.PASSWORD, TYPE.EMAIL, TYPE.TEXT, TYPE.SIM]),
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  maxLength: PropTypes.string,
  internalLoader: PropTypes.bool,
  textAlignment: PropTypes.string
}

const InputWithRef = forwardRef((props, ref) => <Input {...props} forwardedRef={ref} />)
export default InputWithRef
