import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import Brands from '@brands/index'
import useBrand from '@engine/hooks/useBrand'
import KoodoAssets from '@apps/assets/koodo'
import PublicAssets from '@apps/assets/public'

/**
 * @type {React.Context<function(): {}>} AssetsContext
 *
 * Initialized with asset defaults
 */
export const AssetsContext = createContext(undefined)

const getAssets = (brand) => {
  switch (brand) {
    case Brands.koodo:
      return KoodoAssets
    case Brands.public:
      return PublicAssets
    default:
      return KoodoAssets
  }
}

const AssetsProvider = ({ children }) => {
  let brand = useBrand()

  if (brand === undefined) {
    brand = Brands.koodo
  }

  const brandedAssets = getAssets(brand)

  return <AssetsContext.Provider value={brandedAssets}>{children}</AssetsContext.Provider>
}

AssetsProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default AssetsProvider
