import React, { useContext } from 'react'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import { AssetsContext } from '@apps/contexts/AssetsContext'

const ValidationCard = ({ children, title, showCuts, visibility}) => {
  const { koodoCuts } = useContext(AssetsContext)
  return (
    <div className={`relative w-full bg-card-background p-6 text-sm rounded text-black ${visibility}`}>
      <BodySmallBold className="mb-1">{title}</BodySmallBold>
      {children}
      {koodoCuts && <img className="absolute bottom-0 right-0 w-full h-10 mx-auto my-0" src={koodoCuts} alt="" />}
    </div>
  )
}

export default ValidationCard
