/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {
  APPLICATION_STATE,
  ApplicationContext,
  getProvince
} from '@apps/contexts/ApplicationContext'
import {
  ACCOUNT_SETUP_STATE
} from '@apps/flows/activation/contexts/AccountContext'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import AccountHelpers, { convertCentsToDollars, PROMO_CRITERIA } from '@apps/flows/activation/services/accountHelper'

import Loader, { SIZE } from '@apps/components/Loader'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import Input from '@apps/components/Input'
import Button from '@apps/components/Button'
import ModalPopup from '@apps/components/ModalPopUp'
import Sites from '@sites'

import { ContentContext } from '@apps/contexts/ContentContext'
import { PAYMENT_STATE, updatePaymentStates,referAFriend as referAFriendInitState, promoCode as promoCodeInitState} from '../../redux/features/PayAndReviewSlice'

import { getPromotionAndValidationCodes } from '@/apps/utils/requestPayloadHelper'
import { PROMO_TYPE } from '@/apps/utils/constants'

import BodyError from '../Typography/BodyError'
import Heading4 from '../Typography/Heading4'
import PaymentChangeHandlers, {
  PAYMENT_EVENTS
} from '@apps/utils/ChangeHandlers/PaymentChangeHandlers'

const MultiPromoCode = (props) => {
  const dispatch = useDispatch()
  const {
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.IDENTITY]: identity,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage,
    [APPLICATION_STATE.SITE]: site
  } = useContext(ApplicationContext)

  const {
    payment: {
      add,
      applyPromotion,
      promo: promoTitle,
      promoCodeText,
      ratePlanPage,
      ratePlanPageLink,
      enterPromoCode,
      enterValidationCode,
      promotionCodeInvalid,
      validationCodeInvalid,
      validatePromoTitle,
      validatePromoSubTitle,
      promoAlreadyExists
    },
    provinceRequired,
    dynamicContent
  } = useContext(ContentContext)

  const { [ACCOUNT_SETUP_STATE.PROVINCE]: provinceInfo } = useSelector(state => state.accountSetupPage)
  const { [PLANS_STATE.PLAN]: plansInfo } = useSelector((state) => state.plansPage)

  const paymentState = useSelector((state) => state.payAndReviewPage)

  const [adding, setAdding] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { close } = useContext(AssetsContext)

  // Multiple Promo Declarations
  const { findAndValidatePromotions } = brandServices
  const [promoCodeError, setPromoCodeError] = useState('')
  const [isValidPromoCode, setIsValidPromoCode] = useState(false)
  const [atuPromoCode, setATUPromoCode] = useState('')
  const [atuPromoEnabled, setATUPromoEnabled] = useState(false)
  const [isPromoCodeLoading, setIsPromoCodeLoading] = useState(false)
  const [promoBundles, setPromoBundles] = useState([])
  const [isPromoValidationRequired, setIsPromoValidationRequired] = useState(false)
  const [showPromoUpdateNotification, setShowPromoUpdateNotification] = useState(false)
  const [isValidPromoValidationCode, setIsValidPromoValidationCode] = useState(false)
  const [promoCodeValidationError, setPromoCodeValidationError] = useState('')
  const [isPromoValidationCodeLoading, setIsPromoValidationCodeLoading] = useState(false)
  const [multiPromoMaxLimitMessage, setMultiPromoMaxLimitMessage] = useState(null)
  const [multiPromoMaxLimit, setMultiPromoMaxLimit] = useState(null)
  const [exclusivePromoCodeError, setExclusivePromoCodeError] = useState('')
  const [reapplyExistingPromoCodeError, setReapplyExistingPromoCodeError] = useState('')

  const reapplyExistingPromoCodeErrorRef = useRef(reapplyExistingPromoCodeError)
  const exclusivePromoCodeErrorRef = useRef(exclusivePromoCodeError)


  const {
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE]: multiPromotionCode,
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_VALIDATION_CODE]: multiPromotionValidationCode,
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo,
    [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: multiPromoCodes,
    [PAYMENT_STATE.AUTO_TOP_UPS]: autoTopUps
  } = paymentState

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  useEffect(() => {
    if (
      (isValidPromoCode && isPromoValidationRequired && isValidPromoValidationCode) ||
      (isValidPromoCode && !isPromoValidationRequired)
    ) {
      removePCode()
    }
  }, [provinceInfo, plansInfo])

  useEffect(() => {
    if (dynamicContent) {
      const maxLimit = dynamicContent?.paymentPage?.shortTextFields?.find(
        (item) => item.slug === 'multiPromoMaxLimit'
      )
      const maxLimitMessage = dynamicContent?.paymentPage?.shortTextFields?.find(
        (item) => item.slug === 'multiPromoMaxLimitMessage'
      )
      const atuPromoCodeValue = dynamicContent?.paymentPage?.shortTextFields?.find(
        (item) => item.slug === 'atuPromoCode'
      )
      const exclusivePromoErrorMessage = dynamicContent?.paymentPage?.shortTextFields?.find(
        (item) => item.slug === 'exclusivePromoErrorMessage'
      )

      const reapplyExistingPromoCodeErrorMessage = dynamicContent?.paymentPage?.shortTextFields?.find(
        (item) => item.slug === 'reapplyExistingPromoCodeErrorMessage'
      )

      const isATUPromoEnabled = dynamicContent?.paymentPage?.featureFlags?.isAtuPromoEnabled
      const limitValue = parseInt(maxLimit && maxLimit?.value)
      setATUPromoCode(atuPromoCodeValue && atuPromoCodeValue?.value)
      setATUPromoEnabled(isATUPromoEnabled)
      setMultiPromoMaxLimit(limitValue)
      setMultiPromoMaxLimitMessage(maxLimitMessage && maxLimitMessage?.value)

      const exclusiveErrorMessageValue = exclusivePromoErrorMessage?.value || ''
      setExclusivePromoCodeError(exclusiveErrorMessageValue)
      exclusivePromoCodeErrorRef.current = exclusiveErrorMessageValue

      const errorMessage = reapplyExistingPromoCodeErrorMessage?.value || ''
      setReapplyExistingPromoCodeError(errorMessage)
      reapplyExistingPromoCodeErrorRef.current = errorMessage
    }
  }, [dynamicContent, currentLanguage])

  useEffect(() => {
    if (
      atuPromoEnabled &&
      atuPromoCode &&
      autoTopUps &&
      !!provinceInfo &&
      !!plansInfo?.id
    ) {
      const newState = {...paymentState}
      newState.multiPromotionCode = atuPromoCode
      updatePaymentStore({
        ...newState
      })
    }
  }, [atuPromoEnabled, atuPromoCode, autoTopUps])

  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current && multiPromotionCode !== '') {
      validatePromoCodeFields(multiPromotionCode)
      addPromoCode()
    } else {
      mounted.current = true
    }

    if (mounted.current && multiPromotionCode === '' && promoCodeError !== '') {
      validatePromoCodeFields(multiPromotionCode)
      addPromoCode()
    } else {
      mounted.current = true
    }

  }, [currentLanguage, validatePromoCodeFields, addPromoCode])

  useEffect(() => {
    if (promoCodeError === reapplyExistingPromoCodeError) {
      setPromoCodeError(reapplyExistingPromoCodeError)
    }
  }, [reapplyExistingPromoCodeError])

  const isSamePromoCode = (promoCode, code) => {
    return !!promoCode && !!code && code === promoCode
  }

  const applyingATUPromoCode = useMemo(() => {
    return (
      atuPromoEnabled &&
      autoTopUps &&
      !!provinceInfo &&
      !!plansInfo?.id &&
      isSamePromoCode(atuPromoCode, multiPromotionCode)
    )
  }, [
    atuPromoEnabled,
    atuPromoCode,
    autoTopUps,
    provinceInfo,
    plansInfo?.id,
    multiPromotionCode
  ])

  useEffect(() => {
    const appliedCodes = paymentState[PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]
    const atuApplied = appliedCodes.some(code => code === atuPromoCode)
    if (applyingATUPromoCode && !atuApplied) {
      const verify = async () => {
        await addPromoCode()
      }
      verify()
    }
  }, [applyingATUPromoCode])

  useEffect(() => {
    if (!autoTopUps && atuPromoEnabled) {
      const atuPromoCodeIndex = multiPromoCodes?.findIndex((code) => isSamePromoCode(atuPromoCode, code))
      if(atuPromoCodeIndex > -1) {
        removePCode(atuPromoCodeIndex)
      }
    }
  }, [autoTopUps, atuPromoCode, atuPromoEnabled])

  const manualMultiPromoCodes = useMemo(() => {
    return multiPromoCodes.filter((code) => !isSamePromoCode(atuPromoCode, code) && !isSamePromoCode(PROMO_TYPE.AUTOMATIC_PROMO, code))
  }, [multiPromoCodes.length, atuPromoCode])

  useEffect(() => {
    if (multiPromotionCodeInfo.length > 0) {
      updateMultiPromoLanguage()
    }
  }, [currentLanguage])

  const updateMultiPromoLanguage = async () => {
    const multiPromotionAndValidationCodes = getPromotionAndValidationCodes(multiPromoCodes)
    if(multiPromotionAndValidationCodes === PROMO_TYPE.AUTOMATIC_PROMO && currentLanguage === 'en') return
    const { shortName = '' } = getProvince(provinceInfo)
    const { data } = await findAndValidatePromotions(
      plansInfo.id,
      shortName,
      currentLanguage,
      identity.channelOrgCode,
      multiPromotionAndValidationCodes
    )
    const allHaveIds = data.every(
      (promotion) => promotion.id !== undefined && promotion.id !== null
    )
    if (allHaveIds) {
      const newMultiPromotionCodeInfo = data.map((promo) => {
        const bundleArray = promo?.bundles.map((bundle) => {
          const { id, name, fee: rawFee, bundleType, unitType, numberMrc } = bundle
          return {
            id,
            name,
            fee: convertCentsToDollars(rawFee),
            bundleType,
            unitType,
            numberMrc
          }
        })
        return {
          id: promo.id,
          description: promo.description,
          bundles: bundleArray
        }
      })
      updatePaymentStore({
        ...paymentState,
        multiPromotionCodeInfo: newMultiPromotionCodeInfo
      })
    }
  }

  useEffect(() => {
    const newState = {
      multiPromotionCode: '',
      multiPromotionValidationCode: ''
    }

    updatePaymentStore(newState)

    setIsValidPromoCode(false)
    setIsValidPromoValidationCode(false)
  }, [isValidPromoCode, isValidPromoValidationCode])

  const validatePromoCodeFields = (value) => {
    if (!value) setPromoCodeError(enterPromoCode)
    else setPromoCodeError('')
  }

  const validatePromoCodeValidationFields = (value) => {
    if (!value) setPromoCodeValidationError(enterValidationCode)
    else setPromoCodeValidationError('')
  }

  const removePCode = (index) => {
    const newTagList = multiPromoCodes.filter((_, i) => i !== index)
    const newMultiPromotionCodeInfo = multiPromotionCodeInfo.filter((_, i) => i !== index)

    setErrorMessage('')
    setIsValidPromoCode(false)
    setIsValidPromoValidationCode(false)
    setIsPromoValidationRequired(false)

    updatePaymentStore({
      ...paymentState,
      multiPromotionCodeInfo: newMultiPromotionCodeInfo,
      multiPromoCodes: newTagList
    })
  }

  const addPromoCode = async () => {
    validatePromoCodeFields(multiPromotionCode)
    if (multiPromotionCode) {
      if (!provinceInfo) {
        setPromoCodeError(provinceRequired)
        return
      }
      if (!plansInfo.id) {
        setPromoCodeError('Please select a plan to continue')
        return
      }

      const { shortName = '' } = getProvince(provinceInfo)
      updatePaymentStore({ [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: true })
      setIsPromoCodeLoading(true)

      const existingPromoAndValCodes = getPromotionAndValidationCodes(multiPromoCodes)
      const multiPromotionAndValidationCodes = [
        ...existingPromoAndValCodes.split(';').filter((e) => e),
        multiPromotionCode
      ].join(';')

      const data = [
        plansInfo.id,
        identity.channelOrgCode,
        shortName,
        multiPromotionAndValidationCodes,
        currentLanguage
      ]
      const dupes = (multiPromoCodes || []).filter((atag) => {
        return typeof atag === 'string' && (atag?.split('-') ? atag?.split('-')[0] : atag) === multiPromotionCode
      }).length
      if (dupes > 0 && !applyingATUPromoCode) {
        setPromoCodeError(`${promoAlreadyExists}`)
        updatePaymentStore({ [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: false })
        setIsPromoCodeLoading(false)
        setAdding(false)
        return
      }
      const promo = await AccountHelpers.findAndValidatePromoCode(
        findAndValidatePromotions,
        ...data
      )
      if (promo) {
        if (promo.bundles) {
          setPromoBundles(promo.bundles)
        }
        if (promo.code && promo.code === PROMO_CRITERIA.EXCLUSIVE_PROMO_CONFLICT) {
          setIsValidPromoCode(false)
          setPromoCodeError(exclusivePromoCodeErrorRef.current)
          updatePaymentStore({ [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: false })
          setIsPromoCodeLoading(false)
          setAdding(false)
          return
        }

        if (promo.code && promo.code === PROMO_CRITERIA.REAPPLY_EXISTING_PROMOTION_ERROR) {
          setIsValidPromoCode(false)
          setPromoCodeError(reapplyExistingPromoCodeErrorRef.current)
          updatePaymentStore({ [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: false })
          setIsPromoCodeLoading(false)
          setAdding(false)
          return
        }
 
        if (promo.code && promo.code === 'VALIDATION_CODE') {
          setIsPromoValidationRequired(true)
          setIsValidPromoValidationCode(false)
          setIsValidPromoCode(false)
          updatePaymentStore({ [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: false })
          setIsPromoCodeLoading(false)
          setAdding(false)
          return
        }
        setIsValidPromoCode(true)
  
        const newState = {
          ...paymentState,
          multiPromotionCodeInfo: [...paymentState.multiPromotionCodeInfo, promo],
          multiPromoCodes: [...paymentState.multiPromoCodes, multiPromotionCode],
          multiPromotionCode: multiPromotionCode
        }
        updatePaymentStore(newState)
  
      } else if (applyingATUPromoCode) {
        setPromoCodeError('')
        updatePaymentStore({
          multiPromotionCode: ''
        })
      } else {
        setPromoCodeError(promotionCodeInvalid)
      }
      setTimeout(() => {
        updatePaymentStore({ [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: false })
      }, 1000)
      setIsPromoCodeLoading(false)
      setAdding(false)
    }
  }

  const addValidationCode = async () => {
    validatePromoCodeValidationFields(multiPromotionValidationCode)
    if (multiPromotionValidationCode) {
      setIsPromoValidationCodeLoading(true)

      const existingPromoAndValCodes = getPromotionAndValidationCodes(multiPromoCodes)
      const multiPromoAndValCode = `${multiPromotionCode}-${multiPromotionValidationCode}`
      const multiPromotionAndValidationCodes = [
        ...existingPromoAndValCodes.split(';').filter((e) => e),
        multiPromoAndValCode
      ].join(';')

      const data = [
        plansInfo.id,
        identity.channelOrgCode,
        provinceInfo.shortName,
        multiPromotionAndValidationCodes,
        currentLanguage
      ]

      const promoValidation = await AccountHelpers.findAndValidatePromoCode(
        findAndValidatePromotions,
        ...data
      )
      if (promoValidation) {
        if (promoValidation?.bundles) {
          setPromoBundles(promoValidation.bundles)
        }
        if (
          !promoValidation.code ||
          (promoValidation.code && promoValidation.code !== 'VALIDATION_CODE')
        ) {
          setIsValidPromoValidationCode(true)
          setIsPromoValidationRequired(false)
          const newState = {...paymentState}
          newState.multiPromotionValidationCode = multiPromotionValidationCode
          newState.multiPromotionCodeInfo = [...newState.multiPromotionCodeInfo, promoValidation ]
          newState.multiPromoCodes = [...newState.multiPromoCodes, `${multiPromotionCode}-${multiPromotionValidationCode}`]
          updatePaymentStore({
            ...newState
          })
          setShowPromoUpdateNotification(true)
          setIsValidPromoCode(true)
        } else {
          setPromoCodeValidationError(validationCodeInvalid)
        }
      } else {
        setPromoCodeValidationError(validationCodeInvalid)
      }
      setIsPromoValidationCodeLoading(false)
    }
  }

  const closePopup = () => {
    setIsPromoValidationRequired(false)
    const newState = {...paymentState}
      newState.multiPromotionValidationCode = ''
      updatePaymentStore({
        ...newState
      })
    if (promoCodeValidationError) setPromoCodeValidationError('')
  }

const hidePromoCode = () => {
  return applyingATUPromoCode && isSamePromoCode(atuPromoCode, multiPromotionCode)
}

  return (
    <>
      <div className="w-full space-y-4">
        <div className="flex flex-col items-start space-x-0 space-y-4 lg:space-y-0 md:space-y-0 md:space-x-4 lg:space-x-4 md:flex-row lg:flex-row">
          <div className="flex flex-col flex-1 w-full space-y-4">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row w-full">
                <div className="flex flex-col flex-1">
                  <label className="block text-unselected-text" htmlFor="promoCode">
                    <Heading4>{promoTitle}</Heading4>
                    {site && Sites?.consumer && site === Sites.consumer && (
                      <p className="text-copy text-sm font-body">
                        {promoCodeText}
                        <span className="border-solid border-b border-primary mb-1">
                          <a
                            href={ratePlanPageLink}
                            rel="noopener noreferrer"
                            className="text-primary font-bold font-h1"
                            target="_blank"
                          >
                            {ratePlanPage}
                          </a>
                        </span>
                      </p>
                    )}
                  </label>
                  <div className="flex flex-col md:flex-row w-full items-start flex-1">
                    <div className="flex flex-col flex-1 w-full">
                      <Input
                        type="text"
                        id="promoCode"
                        value={
                          // Hide value when applying ATU promo code
                          applyingATUPromoCode ? '' : multiPromotionCode
                        }
                        onChange={(e) => {
                          e.preventDefault()
                          PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                            e.target.value.toUpperCase(),
                            validatePromoCodeFields,
                            PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE,
                            updatePaymentStore
                          )
                        }}
                        onFocus={() => setPromoCodeError('')}
                        errorMessage={promoCodeError}
                        maxLength="30"
                        disabled={
                          multiPromoMaxLimit && multiPromoMaxLimit === manualMultiPromoCodes.length
                        }
                        data-testid="payment-multipromoInput"
                        isLoading={isPromoCodeLoading}
                        internalLoader
                      />
                    </div>
                    <div className="w-full md:w-28 mt-2">
                      <Button
                        className="md:ml-2 h-11 w-full flex justify-center"
                        label={adding ? <Loader size={SIZE.SMW} loading /> : add}
                        testId="payment-promoButton"
                        onClick={addPromoCode}
                        disabled={
                          (multiPromoMaxLimit &&
                            multiPromoMaxLimit === manualMultiPromoCodes.length) ||
                          isPromoCodeLoading
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {multiPromoMaxLimitMessage &&
            multiPromoMaxLimit &&
            manualMultiPromoCodes.length === multiPromoMaxLimit && (
              <div className="flex items-center">
                <BodyError className="ml-1 mb-0.5 leading-4">{multiPromoMaxLimitMessage}</BodyError>
              </div>
            )}
          <div className="mt-2 flex flex-wrap">
            {multiPromoCodes?.map((tag, index) => {
              if (isSamePromoCode(atuPromoCode, tag) || isSamePromoCode(PROMO_TYPE.AUTOMATIC_PROMO, tag)) return null

              if(typeof tag !== 'string') return null

              const tagText = tag?.split('-') ? tag?.split('-')[0] : tag

              return (
                <div
                  key={tag}
                  className="inputTag border pt-2 pb-2 pr-2 pl-4 rounded-full mr-6 flex bg-tag mb-2"
                >
                  <p className="text-primary text-sm font-body leading-6">
                    {/* {tag?.split('-') ? tag?.split('-')[0] : tag} */}
                    {tagText}
                  </p>
                  <div
                    className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125 ml-2"
                    onClick={() => removePCode(index)}
                  >
                    <img width="24" height="24" alt="close" src={close} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {/* Multipromo Validation Code Pop Up */}
      <ModalPopup showPopup={isPromoValidationRequired}>
        <div className="bg-white rounded p-8 relative">
          <div className="flex justify-between items-center mb-8 pb-2">
            <h2 className="text-lg font-semibold">{validatePromoTitle}</h2>
            <img
              className="relative bottom-4 cursor-pointer"
              width="24"
              height="24"
              alt="close"
              src={close}
              onClick={closePopup}
            />
          </div>
          <p className="text-md text-black font-semibold pb-4">{validatePromoSubTitle}</p>
          <Input
            type="text"
            id="validationCode"
            name="validationCode"
            className="border rounded px-3 py-2 mb-0 w-full"
            placeholder=""
            value={multiPromotionValidationCode}
            onChange={(e) => {
              e.preventDefault()
              PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                e.target.value,
                validatePromoCodeValidationFields,
                PAYMENT_STATE.PROMO.MULTI_PROMOTION_VALIDATION_CODE,
                updatePaymentStore
              )
            }}
            errorMessage={promoCodeValidationError}
            onFocus={() => setPromoCodeValidationError('')}
            maxLength="30"
            isLoading={isPromoValidationCodeLoading}
            internalLoader
          />
          <Button
            label={applyPromotion}
            className="text-white py-2 w-80 mt-8 font-semibold rounded"
            onClick={addValidationCode}
            disabled={isPromoValidationCodeLoading || promoCodeValidationError}
          />
        </div>
      </ModalPopup>
    </>
  )
}
export default MultiPromoCode