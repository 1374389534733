/* eslint-disable react/button-has-type */
import React, { useEffect, useContext, useRef, useState, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ContentContext } from '@apps/contexts/ContentContext'
import Heading2 from '@apps/components/Typography/Heading2'
import Heading3 from '@apps/components/Typography/Heading3'
import Heading4 from '@apps/components/Typography/Heading4'
import BulletCard from '@apps/components/Cards/BulletCard'
import BodyBold from '@apps/components/Typography/BodyBold'
import BodySmall from '@apps/components/Typography/BodySmall'
import ChoosePhoneOption from '@apps/components/ChoosePhoneOption'
import LocationSelector from '@apps/components/LocationSelector'
import { PHONE_NUMBER_OPTIONS,PAYMENT_OPTIONS, PORT_ORDER_AUTH_TYPE, SPIDS } from '@apps/utils/constants'
import Button from '@apps/components/Button'
import {
  ACCOUNT_SETUP_STATE
} from '@apps/redux/features/AccountSetupSlice'
import { PHONE_NUMBER_STATE, updatePhoneNumberState } from '@apps/redux/features/PhoneNumberSlice'
import { PAYMENT_STATE, updatePaymentStates,referAFriend as referAFriendInitState } from '@apps/redux/features/PayAndReviewSlice'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import { APPLICATION_STATE, ApplicationContext, setLandingLogicLoader } from '@apps/contexts/ApplicationContext'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import { setPageAnalytics } from '@apps/utils/Analytics'
import RichText from '@apps/components/RichText'
import TransferNumber from '@apps/components/TransferNumber'
import { getById, getBySlug } from '@apps/utils/contentful'
import Sites from '@sites/index'
import PhoneNumberChangeHandlers, {
  PHONE_NUMBER_EVENTS
} from '@apps/utils/ChangeHandlers/PhoneNumberChangeHandlers'
import PhoneNumberUnavailablePopup from '@/apps/components/PhoneNumberUnavailablePopup'

const PhoneNumberPage = () => {
  const dispatch = useDispatch()
  const {
    userType,
    user: { email }
  } = useSelector((state) => state.common)
  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = useSelector(
    (state) => state.plansPage
  )
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const { pageContent, featureFlags } = useSelector((state) => state.common.content)
  const { chevronBack } = useContext(AssetsContext)
  const { UserData } = useSelector((state) => state.common.quote.quoteInfo)

  const [showPhoneNumberUnavailable, setShowPhoneNumberUnavailable] = useState(false)


  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region,
    [APPLICATION_STATE.PROVINCE_GROUPS]: mappedProvinceGroupsList,
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList,
    [APPLICATION_STATE.SERVICES]: { isEligibleForPort, setPatchQuote }
  } = useContext(ApplicationContext)

  const accountSetupPageState = useSelector((state) => state.accountSetupPage)

  const {
    [ACCOUNT_SETUP_STATE.FIRST_NAME]: accountFirstName,
    [ACCOUNT_SETUP_STATE.LAST_NAME]: accountLastName
  } = accountSetupPageState

  const {
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption,
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: selectedPhoneNumber,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: phoneNumberInfo,
    [PHONE_NUMBER_STATE.IS_COMPLETE]: isComplete,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: isPortInAgreementChecked,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: isPortInWireless,
    [PHONE_NUMBER_STATE.PROVINCE_ID]: provID,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: provinceGroupId,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.CITY_NAME]: newNumberCityName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: alternateContactNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: streetNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: streetName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: cityName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS
      .PREVIOUS_PROVIDER_ACCOUNT_NUMBER]: prevProviderAccountNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: imeiNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS
      .PREVIOUS_PROVIDER_ACCOUNT_NUMBER]: previousProviderAccountNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME]: billingFirstName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME]: billingLastName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: billingStreetNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: billingStreetName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: billingCity,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: billingProvince,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]: billingPostalCode,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: serviceAction,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]: selectedSpid,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: portInPhoneNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: prevPortInPhoneNumber
  } = phoneNumberState

  const { currentProvinceId } = useSelector((state) => state.common)

  const paymentState = useSelector((state) => state.payAndReviewPage)

  const { dynamicContent } = useContext(ContentContext)

  const history = useHistory()

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const splashPage = featureFlags && featureFlags[0]?.showSplashing === 'true'
  if (splashPage) {
    if (isDealer) history.push(dealerActivationPaths.Splash)
    else history.push(consumerActivationPaths.Splash)
  }

  const { phoneNumberPage: { header = {}, content = [] } = {} } = dynamicContent || {}
  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const phoneNumberPageContent = getById(pageContent, `phoneNumberPage${pagePrefix}`) || {}
  const comingFromCard = content.find((item) => item.id === 'phoneNumberPage-coming-from-consumer')
  const { shortTextFields } = phoneNumberPageContent[language] || {}

  const textContent = useMemo(() => {
    return {
      alternateContactInvalid: getBySlug(shortTextFields, 'alternateContactInvalid')?.value,
      alternateContactRequired: getBySlug(shortTextFields, 'alternateContactRequired')?.value,
      cityRequired: getBySlug(shortTextFields, 'cityRequired')?.value,
      cityInvalid: getBySlug(shortTextFields, 'cityInvalid')?.value,
      completeOneOfError: getBySlug(shortTextFields, 'completeOneOfError')?.value,
      createNumberInfo: getBySlug(shortTextFields, 'createNumberInfo')?.value,
      createNumberSubInfo: getBySlug(shortTextFields, 'createNumberSubInfo')?.value,
      disconnectBoth: getBySlug(shortTextFields, 'disconnectBoth')?.value,
      disconnectInternet: getBySlug(shortTextFields, 'disconnectInternet')?.value,
      disconnectTV: getBySlug(shortTextFields, 'disconnectTV')?.value,
      firstNameRequired: getBySlug(shortTextFields, 'firstNameRequired')?.value,
      imeiInvalid: getBySlug(shortTextFields, 'imeiInvalid')?.value,
      lastNameRequired: getBySlug(shortTextFields, 'lastNameRequired')?.value,
      nextStepPayment: getBySlug(shortTextFields, 'nextStepPayment')?.value,
      nextStepPlans: getBySlug(shortTextFields, 'nextStepPlans')?.value,
      noChange: getBySlug(shortTextFields, 'noChange')?.value,
      phoneInvalid: getBySlug(shortTextFields, 'phoneInvalid')?.value,
      postalCodeInvalid: getBySlug(shortTextFields, 'postalCodeInvalid')?.value,
      postalCodeRequired: getBySlug(shortTextFields, 'postalCodeRequired')?.value,
      previousStep: getBySlug(shortTextFields, 'previousStep')?.value,
      provinceInvalid: getBySlug(shortTextFields, 'provinceInvalid')?.value,
      provinceRequired: getBySlug(shortTextFields, 'provinceRequired')?.value,
      phoneRequired: getBySlug(shortTextFields, 'phoneRequired')?.value,
      previousAccountNumberInvalid: getBySlug(shortTextFields, 'previousAccountNumberInvalid')
        ?.value,
      selectOption: getBySlug(shortTextFields, 'selectOption')?.value,
      serviceRequired: getBySlug(shortTextFields, 'serviceRequired')?.value,
      serviceInvalid: getBySlug(shortTextFields, 'serviceInvalid')?.value,
      streetNameRequired: getBySlug(shortTextFields, 'streetNameRequired')?.value,
      streetNumRequired: getBySlug(shortTextFields, 'streetNumRequired')?.value,
      validCanadianNumber: getBySlug(shortTextFields, 'validCanadianNumber')?.value,
      inEligiblePortInNumberTitle: getBySlug(shortTextFields, 'inEligiblePortInNumberTitle')?.value,
      inEligiblePortInNumberMessage: getBySlug(shortTextFields, 'inEligiblePortInNumberMessage')?.value,
      inEligiblePortInNumberButtonLabel: getBySlug(shortTextFields, 'inEligiblePortInNumberButtonLabel')?.value
    }
  }, [shortTextFields])

  const {
    alternateContactInvalid,
    alternateContactRequired,
    cityRequired,
    cityInvalid,
    completeOneOfError,
    createNumberInfo,
    createNumberSubInfo,
    disconnectBoth,
    disconnectInternet,
    disconnectTV,
    firstNameRequired,
    imeiInvalid,
    lastNameRequired,
    nextStepPayment,
    nextStepPlans,
    noChange,
    phoneInvalid,
    postalCodeInvalid,
    postalCodeRequired,
    previousStep,
    provinceRequired,
    provinceInvalid,
    phoneRequired,
    previousAccountNumberInvalid,
    selectOption,
    serviceRequired,
    serviceInvalid,
    streetNameRequired,
    streetNumRequired,
    validCanadianNumber,
    inEligiblePortInNumberTitle,
    inEligiblePortInNumberMessage,
    inEligiblePortInNumberButtonLabel
  } = textContent

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const location = useLocation()
  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
    updatePaymentStore({
        ...paymentState,
        multiPromotionCodeInfo: [],
        multiPromoCodes: [],
        ...referAFriendInitState,
        [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false,
        [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: PAYMENT_OPTIONS.CREDIT_CARD,
        [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: '',
        [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: ''
      })
  }, [language])

  const phoneRef = useRef(null)
  const provinceRef = useRef(null)
  const cityRef = useRef(null)
  const alternateRef = useRef(null)
  const accountRef = useRef(null)
  const imeiRef = useRef(null)
  const prevProvRef = useRef(null)
  const firstNameRef = useRef(null)
  const alternateLandlineRef = useRef(null)
  const streetNameRef = useRef(null)
  const provinceLandlineRef = useRef(null)
  const cityLandlineRef = useRef(null)
  const postalRef = useRef(null)
  const tvInternetRef = useRef(null)
  const streetNumRef = useRef(null)

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  useEffect(() => {
    return () => {
      updatePhoneNumberStore({
        [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
      })
    }
  }, [preSelectedPlan])

  const onNumberOptionSelect = (name) => {
    updatePhoneNumberStore({
      [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: name
    })
    if (name === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER) {
      updatePhoneNumberStore({
        [PHONE_NUMBER_STATE.PROVINCE_ID]: ''
      })
    }
  }

  useEffect(() => {
    if (
      UserData?.portIn && 
      !UserData.phoneNumber && 
      !UserData.portInformation &&
      !portInPhoneNumber && 
      !prevPortInPhoneNumber && 
      !selectedPhoneNumber &&
      selectedPhoneNumberOption !== PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER
    ) {
      updatePhoneNumberStore({
        [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER
      })
      setShowPhoneNumberUnavailable(true)
    }
  }, [])

  useEffect(() => {
    return history.block( (location, action) => {
      if ( (action === 'pop' || action === 'POP') && phoneNumberState.isComplete === false) {
        return false
      }

      return () => {
        unblock()
      }

    })
  }, [history, phoneNumberState.isComplete])


  const handleSelectTransferNumberIfInEligible = () => {
    setShowPhoneNumberUnavailable(false)
  }

  const updateQuote = async () => {
    try {
      const input = { email, userData: {} }
      if (selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER) {
        input.provinceId = provID
        input.userData.phoneNumber = selectedPhoneNumber
        input.userData.phoneNumberId = phoneNumberInfo.id
        input.userData.portIn = false
        input.userData.cityName = newNumberCityName
        input.userData.provinceGroupId = provinceGroupId
      } else if (selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER) {
        input.provinceId = currentProvinceId
        input.userData.portIn = true
        input.userData.phoneNumber = `${selectedPhoneNumber}`
        if(isPortInWireless) {
          const portInformation = {
            isWireless: isPortInWireless,
            authType: imeiNumber ? PORT_ORDER_AUTH_TYPE.SERIAL_NUMBER : PORT_ORDER_AUTH_TYPE.ACCOUNT,
            authValue: imeiNumber || previousProviderAccountNumber,
            authorizationName: `${accountFirstName} ${accountLastName}`,
            contactNumber: alternateContactNumber.replaceAll(' ', ''),
            serviceProvider: selectedSpid,
            reseller: '',
            serviceAction: '',
            billing: {
              billingCity: '',
              billingStreetNumber: '',
              billingStreetName: '',
              billingFirstName: '',
              billingLastName: '',
              billingProvince: '',
              billingPostalCode: ''
            }
          }
          input.userData.portInformation = portInformation
        } else {
          const { shortName = '' } = mappedProvinceList.find((group) => {
            return group.value === billingProvince
          }) || {}
          const portInformation = {
            isWireless: isPortInWireless,
            authType: PORT_ORDER_AUTH_TYPE.NONE,
            authValue: null,
            authorizationName: `${accountFirstName} ${accountLastName}`,
            contactNumber: alternateContactNumber.replaceAll(' ', ''),
            serviceProvider: null,
            reseller: '',
            serviceAction: serviceAction,
            billing: {
              billingCity,
              billingStreetNumber,
              billingStreetName,
              billingFirstName,
              billingLastName,
              billingProvince: shortName,
              billingPostalCode
            }
          }
          input.userData.portInformation = portInformation
        }
      }
      const response = await setPatchQuote(input)
      return response
    } catch (err) {
      console.log('UPDATE QUOTE: PHONE NUMBER SELECTION ERROR', err)
      return err
    }
  }

  const handleNextClick = async () => {
    try {
      setLandingLogicLoader('IN_PROGRESS')

      if (isComplete) {
        const updateQuoteResponse = await updateQuote()
        const nextConsumerPath = preSelectedPlan
          ? consumerActivationPaths.Payment
          : consumerActivationPaths.Plans
  
        const nextPagePath = isDealer ? dealerActivationPaths.Plans : nextConsumerPath
  
        const splashPagePath = isDealer
          ? dealerActivationPaths.Splash
          : consumerActivationPaths.Splash
  
        if (updateQuoteResponse?.data?.accountId) {
          history.push(nextPagePath)
        } else {
          history.push(splashPagePath)
        }
      } else {
        let topError
        if (selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER) {
          const prov = mappedProvinceList?.find((item) => {
            return item.value === provID
          })
          let name = ''
          if (prov) {
            const { label: { en = '', fr = '' } = {} } = prov
            name = language === 'en' ? en : fr
          }
          if (handleBlurProvince(name) && !topError) {
            topError = provinceRef
          }
          if (handleBlurCity() && !topError) {
            topError = cityRef
          }
        } else {
          if ((await handleBlurPhone()) && !topError) {
            topError = phoneRef
          }
          if (!isPortInAgreementChecked) {
            updatePhoneNumberStore({
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_HAS_AGREEMENT_ERROR]: true
            })
            if (!topError) {
              topError = phoneRef
            }
          }
  
          if (isPortInWireless) {
            if (handlePrevProvider() && !topError) {
              topError = prevProvRef
            }
            if (handleIMEI() && !topError) {
              topError = imeiRef
            }
            if (handleAccount(prevProviderAccountNumber) && !topError) {
              topError = accountRef
            }
            if (handleAltPhone() && !topError) {
              topError = alternateRef
            }
          } else {
            if (handleFirstName(billingFirstName) && !topError) {
              topError = firstNameRef
            }
            if (handleLastName(billingLastName) && !topError) {
              topError = firstNameRef
            }
            if (handleStreetNum(streetNumber) && !topError) {
              topError = streetNumRef
            }
            if (handleStreetName(streetName) && !topError) {
              topError = streetNameRef
            }
            if (handleProvince() && !topError) {
              topError = provinceLandlineRef
            }
            if (handleCity(cityName) && !topError) {
              topError = cityRef
            }
            if (handlePostal() && !topError) {
              topError = postalRef
            }
            if (handleTVInternet() && !topError) {
              topError = tvInternetRef
            }
            if (handleAltPhoneLandline(alternateContactNumber) && !topError) {
              topError = alternateLandlineRef
            }
          }
        }
        if (topError) {
          topError.current.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    } finally {
      setLandingLogicLoader(false)
    }
  }

  let provinces = []

  if (mappedProvinceList) {
    provinces = mappedProvinceList.map((item) => {
      const { label, value } = item
      return {
        value,
        name: label[language]
      }
    })
  }

  const handleBlurProvince = (provinceValue) => {
    const errorCopy = {
      cityRequired,
      cityInvalid,
      provinceRequired,
      provinceInvalid
    }
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE].onBlur(
      phoneNumberState,
      provinces,
      errorCopy,
      provinceValue,
      updatePhoneNumberStore
    )
  }

  const handleBlurCity = () => {
    let cities = []
    if (mappedProvinceGroupsList) {
      cities = mappedProvinceGroupsList
    }
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_GROUP].onBlur(
      phoneNumberState,
      cities,
      updatePhoneNumberStore
    )
  }

  const handleBlurPhone = async () => {
    const phoneCheck = PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PHONE_NUMBER].onBlur(
      phoneNumberState,
      phoneRequired,
      phoneInvalid,
      isEligibleForPort,
      validCanadianNumber,
      updatePhoneNumberStore
    )

    if (phoneCheck) {
      return true
    }

    return false
  }

  const handlePrevProvider = () => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.SPID].onBlur(
      phoneNumberState,
      SPIDS,
      updatePhoneNumberStore
    )
  }

  const handleIMEI = () => {
    const errors = {
      requiredErrorMessage: completeOneOfError,
      invalidErrorMessage: imeiInvalid
    }

    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.IMEI].onBlur(
      phoneNumberState,
      errors,
      updatePhoneNumberStore
    )
  }

  const handleAccount = (value) => {
    const errors = {
      requiredErrorMessage: completeOneOfError,
      invalidErrorMessage: previousAccountNumberInvalid
    }
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.ACCOUNT_NUMBER].onBlur(
      phoneNumberState,
      errors,
      value.length,
      updatePhoneNumberStore
    )
  }

  const MAX_LENGTH_ALTERNATE_PHONE_NUMBER = 12

  const handleAltPhone = () => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.ALTERNATE_CONTACT_NUMBER].onBlur(
      phoneNumberState,
      {
        requiredErrorMessage: alternateContactRequired,
        invalidErrorMessage: alternateContactInvalid
      },
      MAX_LENGTH_ALTERNATE_PHONE_NUMBER,
      updatePhoneNumberStore
    )
  }

  const handleFirstName = (value) => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onBlur(
      value,
      PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME_ERROR,
      firstNameRequired,
      'First name must contain letters and a max 11 characters',
      value.length,
      updatePhoneNumberStore
    )
  }

  const handleLastName = (value) => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onBlur(
      value,
      PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME_ERROR,
      lastNameRequired,
      'Last name must contain letters and a max 18 characters',
      value.length,
      updatePhoneNumberStore
    )
  }

  const handleAltPhoneLandline = (value) => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onBlur(
      value,
      PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR,
      alternateContactRequired,
      alternateContactInvalid,
      MAX_LENGTH_ALTERNATE_PHONE_NUMBER,
      updatePhoneNumberStore
    )
  }

  const handleStreetNum = (value) => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onBlur(
      value,
      PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER_ERROR,
      streetNumRequired,
      '',
      value.length,
      updatePhoneNumberStore
    )
  }
  const handleStreetName = (value) => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onBlur(
      value,
      PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME_ERROR,
      streetNameRequired,
      '',
      value.length,
      updatePhoneNumberStore
    )
  }
  const handleProvince = () => {
    const errorCopy = {
      provinceRequired,
      provinceInvalid
    }
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_LANDLINE].onBlur(
      phoneNumberState,
      provinces,
      errorCopy,
      updatePhoneNumberStore
    )
  }

  const handleCity = (value) => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onBlur(
      value,
      PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME_ERROR,
      cityRequired,
      '',
      value.length,
      updatePhoneNumberStore
    )
  }
  const handlePostal = () => {
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.POSTAL_CODE].onBlur(
      phoneNumberState,
      {
        postalCodeRequired,
        postalCodeInvalid
      },
      updatePhoneNumberStore
    )
  }

  const handleTVInternet = () => {
    const tvInternetOptions = [
      {
        name: disconnectBoth,
        value: '0'
      },
      {
        name: disconnectInternet,
        value: '1'
      },
      {
        name: disconnectTV,
        value: '2'
      },
      {
        name: noChange,
        value: '3'
      }
    ]
    const errorCopyInternetAndTv = {
      requiredErrorMessage: serviceRequired,
      invalidErrorMessage: serviceInvalid
    }
    return PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INTERNET_TV].onBlur(
      phoneNumberState,
      tvInternetOptions,
      errorCopyInternetAndTv,
      updatePhoneNumberStore
    )
  }

  const transferRefs = {
    phoneRef,
    alternateRef,
    accountRef,
    imeiRef,
    prevProvRef,
    alternateLandlineRef,
    firstNameRef,
    streetNameRef,
    provinceLandlineRef,
    cityLandlineRef,
    postalRef,
    tvInternetRef,
    streetNumRef
  }
  const transferHandlers = {
    handleBlurPhone,
    handlePrevProvider,
    handleIMEI,
    handleAccount,
    handleAltPhone,
    handleFirstName,
    handleLastName,
    handleAltPhoneLandline,
    handleStreetNum,
    handleStreetName,
    handleProvince,
    handleCity,
    handlePostal,
    handleTVInternet
  }

  return (
    <>
      <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-4">
        <div className="space-y-8">
          <div>
            {header && <Heading2 className="mb-6">{header.title}</Heading2>}
            {comingFromCard && (
              <BulletCard>
                <BodyBold className="pb-4">{comingFromCard.title}</BodyBold>
                <RichText className="ml-2">{comingFromCard.body}</RichText>
              </BulletCard>
            )}
            {header && <Heading4 className="text-medium">{header.subtitle}</Heading4>}
          </div>
          <Heading3>{selectOption}</Heading3>
          <div className="mb-4">
            <ChoosePhoneOption
              onSelect={onNumberOptionSelect}
              selectedOption={selectedPhoneNumberOption}
            />
          </div>
        </div>
        {selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER && (
          <>
            <div>
              <Heading4 className="mt-10 text-lg leading-7">{createNumberInfo}</Heading4>
              <BodySmall className="leading-5 mt-6">{createNumberSubInfo}</BodySmall>
              <LocationSelector
                handleBlurProvince={handleBlurProvince}
                handleBlurCity={handleBlurCity}
                cityRef={cityRef}
                provinceRef={provinceRef}
              />
            </div>
          </>
        )}
        {selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER && (
          <div>
            <TransferNumber
              transferRefs={transferRefs}
              transferHandlers={transferHandlers}
              chooseNewPhoneNumber={() =>
                onNumberOptionSelect(PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER)
              }
            />
          </div>
        )}
        <div className="flex pt-4 sm:pt-16 flex-col-reverse sm:flex-row sm:justify-between sm:items-center">
          <div className="my-8 sm:my-0">
            <Link
              className="flex items-center justify-start"
              to={
                isDealer ? dealerActivationPaths.SimSelection : consumerActivationPaths.SimSelection
              }
            >
              <img alt="back button" src={chevronBack} />
              <BodyBold className="pl-2 text-h1 underline">{previousStep}</BodyBold>
            </Link>
          </div>
          <Button
            onClick={() => handleNextClick()}
            label={preSelectedPlan ? nextStepPayment : nextStepPlans}
            testId="phoneNumber-nextStep"
          />
        </div>
      </div>
      <PhoneNumberUnavailablePopup
        isOpen={showPhoneNumberUnavailable}
        unavailableNumberTitle={inEligiblePortInNumberTitle}
        unavailableNumberMessage={inEligiblePortInNumberMessage}
        selectNewPhoneNumberLabel={inEligiblePortInNumberButtonLabel}
        onClose={handleSelectTransferNumberIfInEligible}
        onNewPhoneNumberClick={handleSelectTransferNumberIfInEligible}
      />
    </>
  )
}

export default PhoneNumberPage
