import React, { useContext, useEffect, useRef } from 'react'
import Input from '@apps/components/Input'
import { PHONE_NUMBER_STATE, updatePhoneNumberState } from '@apps/redux/features/PhoneNumberSlice'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import { ContentContext } from '@apps/contexts/ContentContext'
import Loader, { SIZE } from '@apps/components/Loader'
import Error from '@apps/assets/error.svg'
import PhoneNumberChangeHandlers, {
  PHONE_NUMBER_EVENTS
} from '@apps/utils/ChangeHandlers/PhoneNumberChangeHandlers'
import { useDispatch, useSelector } from 'react-redux'
import BodyError from '../Typography/BodyError'

const PhoneNumber = ({ blurPhone }) => {
  const dispatch = useDispatch()
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const areaCodeRef = useRef(null)
  const threeDigitsRef = useRef(null)
  const fourDigitsRef = useRef(null)
  const portErrorRef = useRef(null)

  const { [APPLICATION_STATE.SERVICES]: brandServices } = useContext(ApplicationContext)

  const {
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]: areaPincode,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]: threeDigits,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]: fourDigits,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: phoneNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: prevPhoneNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]: isPortInNumberEligible,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
      .IS_PREV_PORT_IN_NUMBER_ELIGIBLE]: isPrevPortInNumberEligible,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: hasError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: errorMessage,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: isLoading
  } = phoneNumberState

  const { isEligibleForPort } = brandServices

  const {
    phoneNumber: { phoneInvalid, phoneRequired, validCanadianNumber }
  } = useContext(ContentContext)

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  const formattedPhoneNumber = (phoneNumber) => {
    const phoneArr = []
    const chunk = phoneNumber.substring(0, 3)

    if (chunk.length > 0) {
      phoneArr.push(chunk)
    }

    const chunk1 = phoneNumber.substring(3, 6)

    if (chunk1.length > 0) {
      phoneArr.push(chunk1)
    }

    const chunk2 = phoneNumber.substring(6, 10)

    if (chunk2.length > 0) {
      phoneArr.push(chunk2)
    }

    if (phoneArr && phoneArr.length > 0) {
      return phoneArr.join(' ')
    }

    return ''
  }

  const updateField = (e) => {
    const { name, value } = e.target
    const isNum = /^\d+$/.test(value) || value === ''
    if (!isNum) return

    let tempNumber
    let formattedValue
    let updateState = {}
    if (name === 'areaCode') {
      updateState = {
        ...updateState,
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]: value
      }
      tempNumber = `${value}${threeDigits}${fourDigits}`
      formattedValue = formattedPhoneNumber(tempNumber)
    } else if (name === 'threeDigits') {
      updateState = {
        ...updateState,
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]: value
      }
      tempNumber = `${areaPincode}${value}${fourDigits}`
      formattedValue = formattedPhoneNumber(tempNumber)
    } else {
      updateState = {
        ...updateState,
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]: value
      }
      tempNumber = `${areaPincode}${threeDigits}${value}`
      formattedValue = formattedPhoneNumber(tempNumber)
    }
    updateState = {
      ...updateState,
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: tempNumber,
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: false,
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]:
        tempNumber === prevPhoneNumber ? isPrevPortInNumberEligible : false
    }
    updatePhoneNumberStore(updateState)
  }

  const handleBackSpace = (e) => {
    if (phoneNumber && phoneNumber.length === 1) {
      updatePhoneNumberStore({
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneRequired
      })
    }

    if (e.target.value === '' && e.keyCode === 8) {
      if (e.target.name === 'threeDigits') {
        areaCodeRef.current.focus()
      } else if (e.target.name === 'fourDigits') {
        threeDigitsRef.current.focus()
      }
    }
  }
  useEffect(() => {
    if (areaPincode || threeDigits || fourDigits) validatePhoneNumber()
  }, [areaPincode, threeDigits, fourDigits])

  const validatePhoneNumber = () => {
    if (phoneNumber) {
      if (phoneNumber.length === 0) {
        updatePhoneNumberStore({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneRequired
        })
      }
      if (phoneNumber.length > 0 && phoneNumber.length < 10) {
        updatePhoneNumberStore({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneInvalid
        })
      }
      if (phoneNumber.length === 10) {
        if (prevPhoneNumber !== phoneNumber || isPrevPortInNumberEligible) {
          updatePhoneNumberStore({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: ''
          })
        } else {
          updatePhoneNumberStore({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
              .PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: validCanadianNumber
          })
        }
      }
    }
  }

  useEffect(() => {
    if (
      document.activeElement !== areaCodeRef.current &&
      document.activeElement !== fourDigitsRef.current &&
      threeDigits.length === 3
    ) {
      fourDigitsRef.current.focus()
    }
    if (document.activeElement === areaCodeRef.current && areaPincode.length === 3) {
      threeDigitsRef.current.focus()
    }
  }, [areaPincode, threeDigits, fourDigits])

  const specialStyles = 'w-1 lg:mx-3 mx-1 mt-2 text-lg text-black'

  useEffect(() => {
    if (blurPhone) {
      handleBlurPhone()
    }
  }, [blurPhone])

  const handleBlurPhone = async () => {
    const phoneCheck = PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PHONE_NUMBER].onBlur(
      phoneNumberState,
      phoneRequired,
      phoneInvalid,
      isEligibleForPort,
      validCanadianNumber,
      updatePhoneNumberStore
    )

    if (phoneCheck) {
      areaCodeRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <>
      <div className="flex flex-row flex-wrap items-center content-between justify-between lg:justify-start">
        <div className="w-1 mt-2 mr-1 text-lg text-black lg:mr-3">(</div>
        <div className="w-1/5 mx-1 lg:w-1/6 lg:mx-3">
          <Input
            ref={areaCodeRef}
            type="text"
            id="areacode"
            name="areaCode"
            disabled={isLoading}
            errorMessage=""
            maxLength="3"
            value={areaPincode}
            onBlur={() => handleBlurPhone()}
            onChange={(event) => {
              updateField(event)
            }}
            onKeyDown={handleBackSpace}
            hasError={hasError}
            placeholder="XXX"
            textAlignment="text-center"
            data-testid="phoneNumber-transferAreaCode"
          />
        </div>
        <div className={specialStyles}>)</div>
        <div className="w-1/5 mx-1 lg:w-1/6 lg:mx-3">
          <Input
            ref={threeDigitsRef}
            type="text"
            id="threedigits"
            name="threeDigits"
            disabled={isLoading}
            errorMessage=""
            maxLength="3"
            value={threeDigits}
            onBlur={() => handleBlurPhone()}
            onChange={(event) => {
              updateField(event)
            }}
            onKeyDown={handleBackSpace}
            hasError={hasError}
            placeholder="XXX"
            textAlignment="text-center"
            data-testid="phoneNumber-transferThreeDigits"
          />
        </div>
        <div className={specialStyles}>-</div>
        <div className="w-1/3 mx-1 lg:w-1/5 lg:mx-3">
          <Input
            ref={fourDigitsRef}
            type="text"
            id="fourdigits"
            name="fourDigits"
            disabled={isLoading}
            errorMessage=""
            maxLength="4"
            value={fourDigits}
            onBlur={() => handleBlurPhone()}
            onChange={(event) => {
              updateField(event)
            }}
            onKeyDown={handleBackSpace}
            valid={isPortInNumberEligible}
            hasError={hasError}
            placeholder="XXXX"
            textAlignment="text-center"
            data-testid="phoneNumber-transferFourDigits"
          />
        </div>
        {hasError && (
          <div className="mt-2">
            <img className="h-6 my-2 ml-3" src={Error} aria-label="error" />
          </div>
        )}
        {isLoading && <Loader className="mt-2 ml-3" size={SIZE.SM} loading />}
      </div>
      <div tabIndex={-1} ref={portErrorRef}>
        {hasError && (
          <div className="flex items-center" data-testid="phoneNumber-transferPhoneNumber-error">
            <img className="h-4 my-2" src={Error} aria-label="error" />
            <BodyError className="ml-1 mt-0.5 leading-4">{errorMessage}</BodyError>
          </div>
        )}
      </div>
    </>
  )
}

export default PhoneNumber
