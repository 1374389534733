import React, { useState, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import RadioButton from '@apps/components/RadioButton'
import AccountSetupChangeHandlers, {
  ACCOUNT_SETUP_EVENTS
} from '@apps/utils/ChangeHandlers/AccountSetupChangeHandlers'
import {
  ACCOUNT_SETUP_STATE,
  updateAccountSetupState
} from '@apps/redux/features/AccountSetupSlice'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Heading3 from '@apps/components/Typography/Heading3'
import BodySmall from '@apps/components/Typography/BodySmall'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getShortTextFromPage, getById } from '@apps/utils/contentful'
import Sites from '@sites/index'

const PreferredLanguage = () => {
  const dispatch = useDispatch()
  const { pageContent } = useSelector((state) => state.common.content)
  const { userType } = useSelector((state) => state.common)
  const accountSetupState = useSelector((state) => state.accountSetupPage)

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const text = useMemo(() => {
    if (!pageContent || !language || !userType) return {}
    const contentSuffix = isDealer ? 'Dealer' : 'Consumer'
    const accountSetupPageContent = getById(pageContent, `accountSetupPage${contentSuffix}`)?.[
      language
    ]
    return {
      languageTitle: getShortTextFromPage(accountSetupPageContent, 'languageTitle'),
      languageSubtitle: getShortTextFromPage(accountSetupPageContent, 'languageSubtitle'),
      languageOption1: getShortTextFromPage(accountSetupPageContent, 'languageOption1'),
      languageOption2: getShortTextFromPage(accountSetupPageContent, 'languageOption2')
    }
  }, [pageContent, language, userType])

  const { languageTitle, languageSubtitle, languageOption1, languageOption2 } = text

  const languages = [
    { name: languageOption1, value: 'English' },
    { name: languageOption2, value: 'French' }
  ]

  let { [ACCOUNT_SETUP_STATE.LANGUAGE]: selectedLanguage } = accountSetupState

  const setAccountSetupState = (payload) => {
    dispatch(updateAccountSetupState(payload))
  }

  useEffect(() => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.LANGUAGE].onChange(
      selectedLanguage,
      setAccountSetupState
    )
  }, [selectedLanguage])

  const updateSelectedIndex = (index) => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.LANGUAGE].onChange(index, setAccountSetupState)
  }

  const radioButtons = languages.map((language, i) => {
    return (
      <RadioButton
        key={language.value}
        option={language.name}
        selected={i === selectedLanguage}
        name={language.name}
        setSelectedIndex={(index) => updateSelectedIndex(index)}
        index={i}
        testId={`accountSetup-language${language.value}`}
      />
    )
  })

  return (
    <div className="space-y-4">
      <div>
        <Heading3 className="mt-0 sm:mt-16">{languageTitle}</Heading3>
        <BodySmall className="mt-2">{languageSubtitle}</BodySmall>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between">{radioButtons}</div>
    </div>
  )
}

PreferredLanguage.prototype = {
  children: PropTypes.string
}

export default PreferredLanguage
