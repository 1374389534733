import React, { useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router'
import TransactionSummarySection from '@apps/flows/activation/sections/TransactionSummarySection'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { ContentContext } from '@apps/contexts/ContentContext'
import DealerActivationPaths from './DealerActivationPaths'

const TransactionSummaryPage = () => {

  const { dynamicContent } = useContext(ContentContext)

  const history = useHistory()
  const dealerActivationPaths = new DealerActivationPaths()
  if (dynamicContent && dynamicContent.accountSetupPage.featureFlags.showSplashing) {
    history.push(dealerActivationPaths.Splash)
  }

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region,
    [APPLICATION_STATE.BRAND]: brand
  } = useContext(ApplicationContext)

  const location = useLocation()

  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [])

  return (
    <div className="flex flex-col max-w-6xl px-4 pb-12 m-auto space-y-4 lg:space-y-24">
      <TransactionSummarySection />
    </div>
  )
}

export default TransactionSummaryPage
