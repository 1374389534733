/* eslint-disable import/extensions */
import React, { useContext } from 'react'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Box from '@/apps/components/Box'
import Button from '@/apps/components/Button'
import BodyBold from '@/apps/components/Typography/BodyBold'

const SimSelectionFooter = ({
  previousStepLabel = '',
  previousStepLink = '',
  nextStepLabel,
  onNextStepClick,
  nextStepTextButton,
  hideNextStep = false
}) => {
  const { chevronBack: chevronAngle } = useContext(AssetsContext)

  const mobileFooter = () => {
    return (
      <Box
        className={`flex ${
          nextStepTextButton ? 'flex-row-reverse justify-between' : 'flex-col items-start'
        } w-full md:justify-start py-4`}
      >
        {!hideNextStep && (
          <>
            {nextStepTextButton ? (
              <div>
                <button type="button" onClick={onNextStepClick}>
                  <BodyBold
                    textColor="text-primary"
                    className="pr-2 text-h1 underline inline-block cursor-pointer"
                    onClick={onNextStepClick}
                  >
                    {nextStepLabel}
                  </BodyBold>
                </button>
                <img
                  alt="next button icon"
                  src={chevronAngle}
                  className="w-2 inline-block transform rotate-180"
                />
              </div>
            ) : (
              <Button
                onClick={onNextStepClick}
                label={nextStepLabel}
                className="w-full my-4 md:w-1/2 lg:w-4/12 font-h4"
              />
            )}
          </>
        )}
        <div className={`${nextStepTextButton ? 'my-0' : 'my-4'}`}>
          <img alt="back button" src={chevronAngle} className="w-2 inline-block" />
          <Link to={previousStepLink}>
            <BodyBold textColor="text-primary" className="pl-2 text-h1 underline inline-block">
              {previousStepLabel}
            </BodyBold>
          </Link>
        </div>
      </Box>
    )
  }

  const regularFooter = () => {
    return (
      <Box className="flex flex-row w-full md:justify-between py-4 items-center">
        <div>
          {previousStepLabel && (
            <>
              <img alt="back button icon" src={chevronAngle} className="w-2 inline-block" />
              <Link to={previousStepLink}>
                <BodyBold textColor="text-primary" className="pl-2 text-h1 underline inline-block">
                  {previousStepLabel}
                </BodyBold>
              </Link>
            </>
          )}
        </div>
        {!hideNextStep && (
          <>
            {nextStepTextButton ? (
              <div>
                <button type="button" onClick={onNextStepClick}>
                  <BodyBold
                    textColor="text-primary"
                    className="pr-2 text-h1 underline inline-block cursor-pointer"
                  >
                    {nextStepLabel}
                  </BodyBold>
                </button>
                <img
                  alt="next button icon"
                  src={chevronAngle}
                  className="w-2 inline-block transform rotate-180"
                />
              </div>
            ) : (
              <Button
                onClick={onNextStepClick}
                label={nextStepLabel}
                className="w-full my-4 md:w-1/2 lg:w-4/12 font-h4"
              />
            )}
          </>
        )}
      </Box>
    )
  }

  return isMobile ? mobileFooter() : regularFooter()
}

SimSelectionFooter.propTypes = {
  nextStepLabel: PropTypes.string.isRequired,
  previousStepLabel: PropTypes.string,
  previousStepLink: PropTypes.string,
  nextStepLink: PropTypes.string,
  onNextStepClick: PropTypes.func,
  hideNextStep: PropTypes.bool
}

export default SimSelectionFooter
