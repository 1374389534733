import React, { useState, useContext, useEffect } from 'react'
import Input from '@apps/components/Input'
import Datalist from '@apps/components/Datalist'
import {
  PHONE_NUMBER_STATE,
  updatePhoneNumberState
} from '@apps/redux/features/PhoneNumberSlice'
import PhoneNumberChangeHandlers, {
  PHONE_NUMBER_EVENTS
} from '@apps/utils/ChangeHandlers/PhoneNumberChangeHandlers'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import { ContentContext } from '@apps/contexts/ContentContext'
import PostalCode from '@apps/components/PostalCode'

const MAX_LENGTH_STREET_NUMBER = 10

const Address = ({
  landlineRefs,
  landlineHandlers
}) => {
  const dispatch = useDispatch()
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const {
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: streetNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER_ERROR]: streetNumberError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: streetName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME_ERROR]: streetNameError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: cityName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME_ERROR]: cityNameError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]: internetAndTvServicesError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]: provinceIdError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]: postalCode,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR]: postalError
  } = phoneNumberState
  const [provinceValue, setProvinceValue] = useState('')
  const [internetAndTVValue, setInternetAndTVValue] = useState('')

  const {
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage
  } = useContext(ApplicationContext)

  let provinces = []

  if (mappedProvinceList) {
    provinces = mappedProvinceList.map((item) => {
      const { label, value } = item
      return {
        value,
        name: label[currentLanguage]
      }
    })
  }

  useEffect(() => {
    if (phoneNumberState.streetNumberError) {
      handleStreetNum(streetNumber)
    }
    if (phoneNumberState.selectedProvinceLandlineError) {
      handleProvince()
    }
    if (phoneNumberState.streetNameError) {
      handleStreetName(streetName)
    }
    if (phoneNumberState.cityNameError) {
      handleCity(cityName)
    }
    if (phoneNumberState.internetAndTvServicesError) {
      handleTVInternet()
    }
    if (phoneNumberState.postalCodeLandlineError) {
      handlePostal()
    }
  }, [currentLanguage])

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  const {
    phoneNumber: {
      streetNum,
      streetNameLabel,
      cityLabel,
      internetAndTV,
      disconnectBoth,
      disconnectInternet,
      disconnectTV,
      noChange,
      serviceRequired
    },
    provinceLabel,
    provinceRequired
  } = useContext(ContentContext)

  const tvInternetOptions = [
    {
      name: disconnectBoth,
      value: '0'
    },
    {
      name: disconnectInternet,
      value: '1'
    },
    {
      name: disconnectTV,
      value: '2'
    },
    {
      name: noChange,
      value: '3'
    }
  ]

  const errorCopy = {
    provinceRequired,
    provinceInvalid: 'Province is invalid'
  }

  const errorCopyInternetAndTv = {
    requiredErrorMessage: serviceRequired,
    invalidErrorMessage: 'Service is invalid'
  }

  const {
    streetNameRef,
    provinceLandlineRef,
    cityLandlineRef,
    postalRef,
    tvInternetRef,
    streetNumRef
  } = landlineRefs

  const {
    handleStreetNum,
    handleStreetName,
    handleProvince,
    handleCity,
    handlePostal,
    handleTVInternet
  } = landlineHandlers

  return (
    <>
      <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
        <div ref={streetNumRef} className="w-full lg:w-1/3">
          <Input
            type="text"
            id="streetNumber"
            label={streetNum}
            name="streetNumber"
            value={streetNumber}
            maxLength={MAX_LENGTH_STREET_NUMBER}
            onBlur={() => handleStreetNum(streetNumber)}
            onChange={(e) => {
              PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onChange(
                e,
                PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER,
                updatePhoneNumberStore
              )
            }}
            errorMessage={streetNumberError}
            data-testid="phoneNumber-streetNumber"
          />
        </div>
        <div ref={streetNameRef} className="w-full lg:w-2/3">
          <Input
            type="text"
            id="streetName"
            label={streetNameLabel}
            name="streetName"
            value={streetName}
            onBlur={() => handleStreetName(streetName)}
            onChange={(e) => {
              PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onChangeName(
                e,
                PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME,
                updatePhoneNumberStore
              )
            }}
            errorMessage={streetNameError}
            maxLength="110"
            data-testid="phoneNumber-streetName"
          />
        </div>
      </div>
      <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
        <div ref={provinceLandlineRef} className="flex-1">
          <Datalist
            items={provinces}
            loading={provinces.length === 0}
            title={provinceLabel}
            onChange={(event) => {
              if (event.target.value.length <= 25) {
                setProvinceValue(event.target.value)
                PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_LANDLINE].onChange(
                  event.target.value,
                  provinces,
                  errorCopy,
                  updatePhoneNumberStore
                )
              }
            }}
            onBlur={() => handleProvince()}
            error={provinceIdError}
            id="phoneNumber-province"
            value={provinceValue}
          />
        </div>
        <div ref={cityLandlineRef} className="flex-1">
          <Input
            type="text"
            id="cityName"
            label={cityLabel}
            name="cityName"
            value={cityName}
            onBlur={() => handleCity(cityName)}
            onChange={(e) => {
              PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onChangeName(
                e,
                PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME,
                updatePhoneNumberStore
              )
            }}
            errorMessage={cityNameError}
            maxLength="35"
            data-testid="phoneNumber-cityName"
          />
        </div>
      </div>
      <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
        <div ref={postalRef} className="flex-1">
          <PostalCode
            onBlur={() => handlePostal()}
            onChange={(e) => PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.POSTAL_CODE].onChange(e.target.value, updatePhoneNumberStore)}
            testId="phoneNumber-postalCode"
            value={postalCode}
            error={postalError}
          />
        </div>
        <div ref={tvInternetRef} className="flex-1">
          <Datalist
            items={tvInternetOptions}
            title={internetAndTV}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setInternetAndTVValue(e.target.value)
                PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INTERNET_TV].onChange(
                  e.target.value,
                  tvInternetOptions,
                  errorCopyInternetAndTv,
                  updatePhoneNumberStore
                )
              }
            }}
            onBlur={() => handleTVInternet()}
            error={internetAndTvServicesError}
            id="phoneNumber-internetAndTV"
            value={internetAndTVValue}
          />
        </div>
      </div>
    </>
  )
}

Address.propTypes = {}

export default Address
