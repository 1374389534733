/* eslint-disable react/require-default-props */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { AssetsContext } from '@apps/contexts/AssetsContext'

const HIDDEN = 'hidden'
const BLOCK = 'block'

const Expander = ({ heading, body, toggle = HIDDEN }) => {
  const { chevronUpCircle, chevronDownCircle } = useContext(AssetsContext)

  const [toggleStatus, setToggleStatus] = useState(toggle)

  const toggleSection = () => {
    const updateToggle = toggleStatus
    setToggleStatus(updateToggle === HIDDEN ? BLOCK : HIDDEN)
  }

  return (
    <div className="divide-y divide-copy">
      <div className="py-8">
        <div onClick={() => toggleSection()} className="flex justify-between cursor-pointer">
          {heading}
          <img src={toggleStatus === HIDDEN ? chevronDownCircle : chevronUpCircle} />
        </div>
        <div className={`${toggleStatus} py-4`}>{body}</div>
      </div>
    </div>
  )
}

Expander.propTypes = {
  heading: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  toggle: PropTypes.oneOf([HIDDEN, BLOCK])
}

export default Expander
