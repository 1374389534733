import React, { useContext, useState } from 'react'
import Error from '@apps/assets/error.svg'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import PropTypes from 'prop-types'
import Loader, { SIZE } from '@apps/components/Loader'
import Heading4 from '../Typography/Heading4'
import BodyError from '../Typography/BodyError'

const Datalist = ({
  value = '',
  title,
  placeholder,
  items,
  onChange,
  onBlur,
  error,
  disabled = false,
  loading = false,
  id = null,
  onFocus
}) => {
  const options = items.map((item) => {
    return <option key={item.value} value={item.name} />
  })

  const { chevronDown } = useContext(AssetsContext)

  const style = () => {
    // hide chevron for ios
    if (typeof window !== 'undefined' && window.navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      return null
    }

    return {
      backgroundImage: loading ? 'none' : `url("${chevronDown}")`,
      backgroundPosition: '96% 60%',
      backgroundRepeat: 'no-repeat',
      paddingRight: '45px',
      appearance: 'none'
    }
  }

  return (
    <div tabIndex="0" className="focus-visible:outline-none">
      <Heading4 className={`${disabled ? 'opacity-50' : ''}`}>{title}</Heading4>
      <div className="relative">
        <Loader className="absolute right-4 bottom-3" size={SIZE.SM} loading={loading} />
        <input
          type="search"
          className={`mt-2 text-copy font-body ${disabled ? 'opacity-50' : ''} ${
            error ? 'border-red focus:shadow-danger' : ''
          } appearance-none`}
          list={title}
          onChange={(e) => onChange(e)}
          onBlur={(e) => {
            onBlur(e)
          }}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
          }}
          placeholder={placeholder}
          autoComplete="chrome-off"
          style={style()}
          disabled={disabled}
          data-testid={id}
          value={value}
        />
        <datalist id={title}>{options}</datalist>
      </div>
      {error && (
        <div className="flex items-center" data-testid={id ? `${id}-error` : undefined}>
          <img alt="error" className="h-4 my-2" src={Error} />
          <BodyError className="ml-1 mt-0.5 leading-4">{error}</BodyError>
        </div>
      )}
    </div>
  )
}

Datalist.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  id: PropTypes.string
}

export default Datalist
