
export const CardType = (number) => {
  let type
  if (number && number.trim().length > 0) {
    if (number.startsWith('3')) {
      type = 'amex'
    } else if (number.startsWith('4')) {
      type = 'visa'
    } else if (number.startsWith('5')) {
      type = 'mastercard'
    } else {
      type = 'visadebit'
    }
  }
  return type
}

export const formatCreditCard = (cardNum) => {
  const cardNumTrim = cardNum.substring(0, 16)
  const cardArr = cardNumTrim.match(/.{1,4}/g)

  if (cardArr && cardArr.length > 0) {
    return cardArr.join('  ')
  }
  return ''
}

export const formatCreditCardAmex = (cardNum) => {
  const cardArr = []
  const chunk = cardNum.substring(0, 4)

  if (chunk.length > 0) {
    cardArr.push(chunk)
  }

  const chunk1 = cardNum.substring(4, 10)

  if (chunk1.length > 0) {
    cardArr.push(chunk1)
  }

  const chunk2 = cardNum.substring(10, 15)

  if (chunk2.length > 0) {
    cardArr.push(chunk2)
  }

  if (cardArr && cardArr.length > 0) {
    return cardArr.join('  ')
  }

  return ''
}
