import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Input from '@apps/components/Input'
import { ContentContext } from '@apps/contexts/ContentContext'

const PostalCode = ({ testId, onBlur, onChange, value, error }) => {

  const POSTAL_CODE_LENGTH = '7'

  const { postalCodeLabel } = useContext(ContentContext)

  return (
    <div className="w-full">
      <Input
        type="text"
        id="postalCode"
        label={postalCodeLabel}
        name="postalCode"
        value={value}
        onBlur={() => onBlur()}
        onChange={(e) => onChange(e)}
        errorMessage={error}
        maxLength={POSTAL_CODE_LENGTH}
        data-testid={testId}
      />
    </div>
  )
}

PostalCode.prototype = {
  children: PropTypes.string,
  onChange: PropTypes.func
}

export default PostalCode
