/* eslint-disable import/extensions */
import React from 'react'
import HaveSimCardDetail from '@apps/components/HaveSimCardDetail'
import NewESimDetail from '@apps/components/NewESimDetail'
import { useSelector } from 'react-redux'
import DealerESIMCompatibility from '@apps/components/DealerESIMCompatibility'
import SimPurchaseUnavailable from '@apps/components/SimPurchaseUnavailable'
import PropTypes from 'prop-types'
import { CONSUMER_SIM_OPTIONS, SIM_OPTIONS } from '@apps/utils/constants'

const SimSelectionDetails = ({ userType, simSelection, simRef }) => {
  const { featureFlags } = useSelector((state) => state.common.content)

  const eSimPurchaseAvailable = featureFlags && featureFlags[0]?.eSimPurchaseAvailable === 'true'

  const pSIMSelection = () => {
    return simSelection === CONSUMER_SIM_OPTIONS.HAVE_SIM || simSelection === SIM_OPTIONS.P_SIM
  }

  const consumerESIMSelection = () => {
    return simSelection === CONSUMER_SIM_OPTIONS.NEW_SIM
  }

  const dealerESIMSelection = () => {
    return simSelection === SIM_OPTIONS.E_SIM
  }

  if ((consumerESIMSelection() || dealerESIMSelection()) && !eSimPurchaseAvailable) {
    return <SimPurchaseUnavailable />
  }

  return (
    <>
      {pSIMSelection() && <HaveSimCardDetail userType={userType} simRef={simRef}/>}
      {consumerESIMSelection() && <NewESimDetail userType={userType} />}
      {dealerESIMSelection() && <DealerESIMCompatibility userType={userType} />}
    </>
  )
}

SimSelectionDetails.propTypes = {
  userType: PropTypes.string.isRequired,
  simSelection: PropTypes.string.isRequired,
  simRef: PropTypes.object.isRequired
}

export default SimSelectionDetails
