import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import BodySmall from '@apps/components/Typography/BodySmall'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { Link } from 'react-router-dom'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import { PAYMENT_OPTIONS, PAGE_NUMBER, SIM_OPTIONS } from '@apps/utils/constants'
import { PLANS_STATE, SelectedPlan, loadAllPlans } from '@apps/redux/features/PlansSlice'
import ProxyOperations from '@apps/flows/activation/services/operations'
import useLocale from '../../../_engine/hooks/useLocale'
import { useHistory } from 'react-router-dom'
import {
  PAYMENT_STATE,
  updatePaymentStates,
  ccInfo,
  paymentVoucher,
  paymentSummary,
  promoCode as promoCodeInitState
} from '../../../apps/redux/features/PayAndReviewSlice'
import { PHONE_NUMBER_OPTIONS } from '@apps/utils/constants'
import { containsProvince } from '@apps/utils/common'

import { ACCOUNT_SETUP_STATE } from '@apps/redux/features/AccountSetupSlice'
import { PHONE_NUMBER_STATE } from '@apps/redux/features/PhoneNumberSlice'
import { recordUserInfo } from '@apps/redux/features/CommonSlice'
import { ContentContext } from '@apps/contexts/ContentContext'
import AccountHelpers from '@apps/flows/activation/services/accountHelper'
import { useLocation } from 'react-router'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { currencyFormatter } from '@apps/utils/CurrencyFormatter'
import { getBySlug, getById } from '@apps/utils/contentful'
import Sites from '@sites/index'
import { getTaxDetails } from '@/apps/utils/common'

let prevOption = ''

const PaymentSummary = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { lang, prov } = useLocale()
  const { getTax } = ProxyOperations('public')
  const { pageContent } = useSelector((state) => state.common.content)
  const { userType, activeStepNumber, currentProvinceId } = useSelector((state) => state.common)
  const { chevronUpWhite, chevronDownWhite } = useContext(AssetsContext)
  const plansPageState = useSelector((state) => state.plansPage)
  const { localizedPlanItem } = plansPageState
  const {
    [PLANS_STATE.PLAN]: plansInfo,
    [PLANS_STATE.PROMO]: planPromo,
    [PLANS_STATE.PRE_SELECTED_PLAN]: planPreSelected
  } = plansPageState
  const [showPaymentCard, setShowPaymentCard] = useState(false)
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const paymentState = useSelector((state) => state.payAndReviewPage)

  const { 
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList
  } = useContext(ApplicationContext)

  const {
    [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: addedVouchers,
    [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: selectedPaymentOption,
    [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: promotionCodeInfo,
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo,
    [PAYMENT_STATE.SUMMARY.AMOUNT_DUE]: amountDue,
    [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: amountOwing,
    [PAYMENT_STATE.SUMMARY.AMOUNT_FUNDS]: amountFunds,
    [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: taxAmount,
    [PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]: amountTotalVoucher,
    [PAYMENT_STATE.SUMMARY.TAX_INFO]: taxInfo
  } = paymentState

  const { [ACCOUNT_SETUP_STATE.BAN]: accountBan, [ACCOUNT_SETUP_STATE.PROVINCE]: provinceInfo } =
    useSelector((state) => state.accountSetupPage)

  const {
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: selectedPhoneNumber,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: phoneNumber,
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption,
    [PHONE_NUMBER_STATE.PROVINCE_NAME]: provinceName,
    [PHONE_NUMBER_STATE.PROVINCE_ID]: provID
  } = phoneNumberState

  const simSelectionState = useSelector((state) => state.simSelectionPage)
  const { simType: simSelection, cost: simSelectionCost } = simSelectionState.selectSimType

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region
  } = useContext(ApplicationContext)

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const getContentType = () => {
    return userType === Sites.dealer ? 'Dealer' : 'Consumer'
  }
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const { calculateTax } = brandServices

  const { dynamicContent } = useContext(ContentContext)

  const paymentPageContent = useMemo(() => {
    if (!pageContent || !language) return {}
    const page = getById(pageContent, `paymentPage${getContentType()}`) || {}
    return page[language]
  }, [pageContent, language, userType])

  const plansPageContent = useMemo(() => {
    if (!pageContent || !language) return {}
    const page = getById(pageContent, `plansPage${getContentType()}`) || {}
    return page[language]
  }, [pageContent, language, userType])

  const { shortTextFields: paymentPageShortTextFields } = paymentPageContent
  const { shortTextFields: plansPageShortTextFields } = plansPageContent

  const textContent = useMemo(() => {
    if (!paymentPageShortTextFields && !plansPageShortTextFields) return {}
    return {
      subTotalTitle: getBySlug(paymentPageShortTextFields, 'subTotalTitle')?.value,
      tax: getBySlug(paymentPageShortTextFields, 'tax')?.value,
      showTitle: getBySlug(paymentPageShortTextFields, 'showTitle')?.value,
      hideTitle: getBySlug(paymentPageShortTextFields, 'hideTitle')?.value,
      dueTodayTitle: getBySlug(paymentPageShortTextFields, 'dueTodayTitle')?.value,
      finalPriceToBeDisplayed: getBySlug(paymentPageShortTextFields, 'finalPriceToBeDisplayed')
        ?.value,
      changePlan: getBySlug(plansPageShortTextFields, 'changePlan')?.value,
      planTitle: getBySlug(plansPageShortTextFields, 'planTitle')?.value,
      plansTitle: getBySlug(plansPageShortTextFields, 'plansTitle')?.value,
      promos: getBySlug(plansPageShortTextFields, 'promos')?.value,
      voucherTotal: getBySlug(plansPageShortTextFields, 'voucherTotal')?.value,
      fundsAddedToTheAccount: getBySlug(plansPageShortTextFields, 'fundsAddedToTheAccount')?.value,
      noPlanSelected: getBySlug(plansPageShortTextFields, 'noPlanSelected')?.value
    }
  }, [paymentPageShortTextFields, plansPageShortTextFields])

  const prevPlansInfoRef = useRef()
  const prevProvinceInfoRef = useRef()

  useEffect(() => {
    prevPlansInfoRef.current = plansInfo
    prevProvinceInfoRef.current = provinceInfo
  }, [])
  const prevPlansInfo = prevPlansInfoRef.current
  const prevProvinceInfo = prevProvinceInfoRef.current

  useEffect(() => {
    updatePaymentStore({ [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: [] })
  }, [selectedPaymentOption])

  /**
   * Return the promo by priority. (1. Manual 2. Plan ). No stacking promos for activation.
   *
   * @return {} || null
   */
  const getPromoBundle = () => {
    // manual promo priority 1
    if (promotionCodeInfo) {
      return promotionCodeInfo
    }
    if (
      dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo &&
      multiPromotionCodeInfo?.length > 0
    ) {
      return multiPromotionCodeInfo
    }

    // plan promo priority 2
    if (planPromo) {
      return planPromo
    }

    return null
  }

  const calculateSubTotal = (plnData, promoCodes, simCost) => {
    if ((plnData && plnData.value) || simCost) {
      let result = Number(plnData?.value ?? 0)

      if (promoCodes && promoCodes.bundles) {
        promoCodes.bundles.forEach((bundle) => {
          if (bundle.fee && !Number.isNaN(bundle.fee) && Math.abs(bundle.fee) !== 0) {
            result += Number(bundle.fee)
          }
        })
      } else if (dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo && promoCodes) {
        promoCodes.forEach((promoCode) => {
          if (promoCode.bundles) {
            promoCode.bundles.forEach((bundle) => {
              if (bundle.fee && !Number.isNaN(bundle.fee) && Math.abs(bundle.fee) !== 0) {
                result += Number(bundle.fee)
              }
            })
          }
        })
      }

      if (simCost) {
        result += Number(simCost)
      }

      return result.toFixed(2)
    }
    return (0.0).toFixed(2)
  }

  const currentPlan = useMemo(() => {
    if (!planPreSelected && activeStepNumber < PAGE_NUMBER.CHOOSE_NUMBER) return null
    return plansInfo
  }, [planPreSelected, activeStepNumber, plansInfo])

  const planSelected = () => {
    return !!currentPlan && !!currentPlan?.id
  }

  const eSimSelected = () => {
    return simSelection === SIM_OPTIONS.E_SIM
  }

  useEffect(() => {
    return history.listen((location, action) => {
      if ((action === 'pop' || action === 'POP') && !planSelected()) {
        if (userType === Sites.consumer) {
          history.push(consumerActivationPaths.Plans)
        } else {
          history.push(dealerActivationPaths.Plans)
        }
      }
    })
  }, [history, plansInfo])

  useEffect(() => {
    const subTotal = calculateSubTotal(currentPlan, getPromoBundle(), simSelectionCost)
    let updatePaymentState = {}
    if (prevProvinceInfo !== provinceInfo || prevPlansInfo !== plansInfo) {
      // reset promo if plan or province changed
      updatePaymentState = {
        ...updatePaymentState,
        ...promoCodeInitState
      }
    }

    if (selectedPaymentOption !== prevOption && prevOption !== '') {
      // reset
      updatePaymentState = {
        ...paymentVoucher,
        ...paymentSummary,
        ...ccInfo,
        [PAYMENT_STATE.TERMS_OF_SERVICE]: false,
        [PAYMENT_STATE.AUTO_TOP_UPS]: true,
        [PAYMENT_STATE.IS_COMPLETE]: false
      }
    }
    prevOption = selectedPaymentOption
    updatePaymentState = {
      ...updatePaymentState,
      [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: subTotal,
      [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: 0
    }
    if (
      (addedVouchers.length > 0 || addedVouchers.length === 0) &&
      selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER
    ) {
      const totalVoucherAmount = addedVouchers.reduce((prev, curr) => {
        return (Number(prev) || 0) + (Number(curr.totalAmount) || 0)
      }, 0)
      updatePaymentState = {
        ...updatePaymentState,
        [PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]: totalVoucherAmount.toFixed(2)
      }
      const dueAmount = (Number(subTotal) - Number(totalVoucherAmount)).toFixed(2)
      if (Number(dueAmount) > 0) {
        updatePaymentState = {
          ...updatePaymentState,
          [PAYMENT_STATE.SUMMARY.AMOUNT_DUE]: dueAmount,
          [PAYMENT_STATE.SUMMARY.AMOUNT_FUNDS]: (0.0).toFixed(2)
        }
      } else {
        updatePaymentState = {
          ...updatePaymentState,
          [PAYMENT_STATE.SUMMARY.AMOUNT_DUE]: (0.0).toFixed(2),
          [PAYMENT_STATE.SUMMARY.AMOUNT_FUNDS]: Math.abs(dueAmount).toFixed(2)
        }
      }
    }

    if (planSelected() && (selectedPhoneNumber || phoneNumber)) {
      if (selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER) {
        const total = subTotal
        updatePaymentState = {
          ...updatePaymentState,
          [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: total,
          [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: (0.0).toFixed(2)
        }
      } else {
        const province = provinceName ? provinceName : prov
        const selectedProv =
          phoneNumberState?.selectedPhoneNumberOption?.toUpperCase() === 'TRANSFER_NUMBER'
            ? prov
            : province
        const correctedProvince =
          selectedProv.toUpperCase() === 'NL' ? 'NF' : selectedProv.toUpperCase()
        getTax(subTotal, correctedProvince, selectedPhoneNumber).then((responseTax) => {
          const taxDetails = getTaxDetails(responseTax, language)
          const taxVal = taxDetails
            ?.map((current) => current.value)
            .reduce((prev, current) => Number(prev) + Number(current), 0)
          const taxCalc = taxVal.toFixed(2) || (0.0).toFixed(2)

          const total = (Number(subTotal) + Number(taxCalc)).toFixed(2)
          // commented code for further testing
          updatePaymentStore({
            // ...paymentState,
            ...updatePaymentState,
            [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: total,
            [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: taxCalc,
            [PAYMENT_STATE.SUMMARY.TAX_INFO]: taxDetails
          })
        })
      }
    }

    updatePaymentStore({
      // ...paymentState,
      ...updatePaymentState
    })

    // setPaymentState({
    //   ...paymentState,
    //   ...updatePaymentState
    // })

    // if (
    //   dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo &&
    //   multiPromotionCodeInfo?.length > 0
    // ) {
    //   updatePaymentStore({
    //     ...paymentState,
    //     ...updatePaymentState,
    //     ...promoCodeInitState
    //   })
    //   // setPaymentState({
    //   //   ...paymentState,
    //   //   ...updatePaymentState,
    //   //   ...promoCodeInitState
    //   // })
    // }
  }, [
    addedVouchers,
    promotionCodeInfo,
    multiPromotionCodeInfo?.length,
    currentPlan,
    selectedPhoneNumber,
    selectedPhoneNumberOption,
    selectedPaymentOption,
    provinceInfo,
    simSelection,
    simSelectionCost,
    prov,
    language
  ])

  const location = useLocation()
  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
    dispatch(recordUserInfo({ referrerUrl: location?.pathname }))
  }, [])

  const localizedPlans = useMemo(() => {
    const usePhoneNumberProvince =
    selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER && provID
    const selectedProv = usePhoneNumberProvince
      ? mappedProvinceList?.find((pr) => pr.value === provID)?.shortName
      : mappedProvinceList?.find((pr) => pr.value === currentProvinceId)?.shortName
    const plansObject = localizedPlanItem?.[language]?.find(Boolean)
    const { plans: portalPlanList } = plansObject || {}
    return portalPlanList?.filter((pl) => !pl.province || containsProvince(pl.province, selectedProv))
  }, [
    mappedProvinceList,
    provID,
    currentProvinceId,
    language,
    localizedPlanItem,
    selectedPhoneNumberOption
  ])

  useEffect(() => {
    dispatch(loadAllPlans(localizedPlans))
  }, [localizedPlans])

  const getPromotionInfo = (promo) => {
    return (
      <>
        {promo && promo.bundles && (
          <div className={`${showPaymentCard ? 'block' : 'hidden md:block lg:block'}`}>
            {promo.bundles.map((bundle) => {
              const { fee = 0, id, name } = bundle
              return (
                <div className="flex flex-row justify-between" key={id}>
                  <BodySmall className="text-copy-inverted">{name}</BodySmall>
                  {Math.abs(fee) > 0 && (
                    <BodySmall className="text-copy-inverted">
                      {`- $${Math.abs(bundle.fee).toFixed(2)}`}
                    </BodySmall>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }

  const toggleSection = () => {
    setShowPaymentCard(!showPaymentCard)
  }

  const getPlansInfo = () => {
    const selectedPlan = SelectedPlan(plansPageState)

    return (
      <>
        {selectedPlan && selectedPlan.id ? (
          <div className={`pb-3 pl-2 ${showPaymentCard ? 'block' : 'hidden md:block lg:block'}`}>
            <ul className="ml-4 list-disc list-outside">
              {selectedPlan.features.map((feature) => {
                return (
                  <li key={feature}>
                    <BodySmall className="text-copy-inverted">{feature}</BodySmall>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
          <>
            <BodySmall className="italic text-copy-inverted">
              {textContent?.noPlanSelected}
            </BodySmall>
          </>
        )}
      </>
    )
  }

  const taxData = (taxInfo ?? []).map((data, index) => {
    const { label, value } = data
    return {
      id: index,
      label,
      value: currencyFormatter(value, language)
    }
  })

  const getPaymentBreakdown = () => {
    const paymentBreakdown = [
      {
        title: textContent?.subTotalTitle,
        amount: `${currencyFormatter(
          calculateSubTotal(currentPlan, getPromoBundle(), simSelectionCost),
          language
        )}`
      }
    ]
    if (selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD) {
      taxData.map(({ label, value }) =>
        paymentBreakdown.push({
          title: label,
          amount: value
        })
      )
    }

    if (selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER) {
      if (addedVouchers && addedVouchers.length > 0) {
        paymentBreakdown.push({
          title: textContent?.voucherTotal,
          amount: `- ${currencyFormatter(amountTotalVoucher, language)}`
        })
      }
    }
    return paymentBreakdown
  }

  const getPaymentBreakdownSection = () => {
    let paymentComponents = []
    let taxToBeCalculated = false

    if (parseFloat(taxAmount) === 0 && selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD) {
      taxToBeCalculated = true
    } else {
      paymentComponents = getPaymentBreakdown()
    }

    return (
      <>
        {(planSelected() || eSimSelected()) && (
          <>
            <ul className="ml-0 list-disc list-outside">
              {paymentComponents.map((payment) => {
                return (
                  <li key={payment.title} className="flex flex-row justify-between mb-1">
                    <div className="flex flex-col">
                      <div className="text-sm text-copy-inverted">{`${payment.title}:`}</div>
                    </div>
                    <div className="flex flex-col ml-2">
                      <div className="text-sm text-copy-inverted font-h4">{payment.amount}</div>
                    </div>
                  </li>
                )
              })}
              {showPaymentCard &&
                addedVouchers.map((voucher) => {
                  return (
                    <li key={voucher.id} className="flex flex-row justify-between mb-1">
                      <div>
                        <BodySmall className="text-copy-inverted">{voucher.id}</BodySmall>
                      </div>
                      <div>
                        <BodySmall className="text-copy-inverted">{`- ${currencyFormatter(
                          voucher.totalAmount,
                          language
                        )}`}</BodySmall>
                      </div>
                    </li>
                  )
                })}
              {taxToBeCalculated && (
                <>
                  <li className="flex flex-row justify-between">
                    <div className="text-base font-body text-copy-inverted font-normal">
                      {textContent?.subTotalTitle}
                    </div>

                    {userType === Sites.consumer && (
                      <div className="flex flex-col ml-2">
                        <div className="text-sm text-copy-inverted font-h4 font-bold">
                          {currencyFormatter(amountOwing, language)}
                        </div>
                      </div>
                    )}
                    {userType === Sites.dealer && activeStepNumber === PAGE_NUMBER.PAYMENT && (
                      <div className="flex flex-col ml-2">
                        <div className="text-sm text-copy-inverted font-h4 font-bold">
                          {currencyFormatter(amountOwing, language)}
                        </div>
                      </div>
                    )}
                  </li>
                  {userType === Sites.dealer && activeStepNumber < PAGE_NUMBER.PAYMENT && (
                    <li className="flex flex-row justify-between mt-2">
                      <div className="text-xs font-normal">
                        {textContent?.finalPriceToBeDisplayed}
                      </div>
                    </li>
                  )}
                </>
              )}
            </ul>
          </>
        )}
      </>
    )
  }

  const getAmountParts = () => {
    if (selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD) {
      return amountOwing.toString().split('.')
    }
    if (addedVouchers.length <= 0) {
      return amountOwing.toString().split('.')
    }
    return amountDue.toString().split('.')
  }

  const getAmountOwingSection = () => {
    const [part1, part2] = getAmountParts()

    return (
      <div className="flex flex-col items-end justify-between md:items-start lg:items-start">
        <div className="text-xs text-copy-inverted">{textContent?.dueTodayTitle}</div>
        <span className="text-black">
          {language === 'en' && <span className="text-lg text-copy-inverted font-h4">$</span>}
          <span className="text-6xl text-copy-inverted font-h1">{part1}</span>
          <span className="text-2xl text-copy-inverted font-h1">
            {language === 'en' ? `.${part2}` : `,${part2}`}
          </span>
          {language === 'fr' && <span className="ml-1 text-lg text-copy-inverted font-h4">$</span>}
        </span>
        {amountFunds > 0 && Array.isArray(addedVouchers) && addedVouchers.length > 0 && (
          <div className="flex flex-row justify-between">
            <BodySmall className="text-base text-copy-inverted">
              {textContent?.fundsAddedToTheAccount}
            </BodySmall>
            <div className="text-copy-inverted">{`+ ${currencyFormatter(
              amountFunds,
              language
            )}`}</div>
          </div>
        )}
      </div>
    )
  }

  const promoDiscount = (promo) => {
    let result = 0

    if (multiPromotionCodeInfo?.length > 0 && promo) {
      promo.forEach((promoBundle) => {
        const { bundles = [] } = promoBundle || {}
        bundles.forEach((bundle) => {
          const { fee = 0 } = bundle
          result += Number(fee)
        })
      })
    } else {
      const { bundles = [] } = promo || {}
      bundles.forEach((bundle) => {
        const { fee = 0 } = bundle
        result += Number(fee)
      })
    }
    return result
  }

  const getChangePlanLink = () => {
    return consumerActivationPaths.ChangeExistingPlan.replace(':planName', plansInfo.sluggedName)
  }

  const totalDiscount = promoDiscount(getPromoBundle())

  if (!planSelected() && !eSimSelected()) return null
  const getMultiPromoInfo = (multiPromoInfo) => {
    return (
      <>
        {multiPromoInfo && (
          <>
            {multiPromoInfo.map((promoInfo) => {
              return (
                <>
                  {promoInfo && promoInfo?.bundles && (
                    <>
                      {promoInfo?.bundles?.map((bundle) => {
                        const { fee = 0, id, name } = bundle
                        return (
                          <div className="flex flex-row justify-between" key={id}>
                            <div className="text-xs text-copy  font-body block w-2/3 text-copy-inverted">
                              {name}
                            </div>
                            <div className="text-xs text-copy  font-body text-copy-inverted">
                              {Math.abs(Number(fee)) > 0 &&
                                currencyFormatter(Number(fee), language)}
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </>
              )
            })}
          </>
        )}
      </>
    )
  }

  return (
    <>
      {!accountBan && (
        <div className="sticky bottom-0 z-50 w-full bg-cut1">
          <div className="bg-white">
            <div className="bg-background">
              <div className="w-20 ml-2 md:relative md:left-44 lg:left-72 xl:left-96">
                <button
                  className="flex flex-row items-center py-2 px-4 bg-cut1"
                  onClick={() => toggleSection()}
                  type="button"
                >
                  <BodySmall className="text-copy-inverted">
                    {showPaymentCard ? textContent?.hideTitle : textContent?.showTitle}
                  </BodySmall>
                  <img
                    className="w-3 h-3 ml-1"
                    src={showPaymentCard ? chevronDownWhite : chevronUpWhite}
                    alt={showPaymentCard ? chevronDownWhite : chevronUpWhite}
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col max-w-5xl px-4 m-auto bg-white shadow md:flex-row lg:flex-row bg-cut1">
            <div
              className={`flex flex-col self-stretch justify-end flex-1 h-auto py-4 md:py-0 lg-py-0 px-0 md:px-4 lg:px-4 my-2 md:my-6 lg:my-6 space-y-2 ${
                showPaymentCard ? 'block' : 'hidden md:block lg:block'
              }`}
            >
              <div>
                {planSelected() && (
                  <>
                    <div className="flex flex-row justify-between">
                      <div className="font-body text-xs">{textContent?.planTitle}</div>
                      <div className="text-sm font-h4 font-bold">
                        {currencyFormatter(currentPlan.value, language)}
                      </div>
                    </div>
                    {planPreSelected && getContentType() === 'Consumer' && (
                      <div className="flex flex-row justify-between mt-0 mb-1">
                        <div className="font-body text-xs text-yellow cursor-pointer underline">
                          <Link
                            to={{
                              pathname: getChangePlanLink(),
                              state: { from: location.pathname }
                            }}
                          >
                            {textContent?.changePlan}
                          </Link>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {showPaymentCard && getPlansInfo()}
              {!dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo &&
                multiPromotionCodeInfo?.length === 0 &&
                getPromoBundle() && (
                  <div className="flex flex-row justify-between">
                    <span className="text-sm tracking-wider text-copy-inverted">
                      {textContent?.promos}
                    </span>
                    <div className="text-sm font-h4">
                      {Math.abs(totalDiscount) > 0 && currencyFormatter(totalDiscount, language)}
                    </div>
                  </div>
                )}
              {dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo &&
                multiPromotionCodeInfo?.length > 0 &&
                getPromoBundle() && (
                  <>
                    <div className="flex flex-row justify-between">
                      <span
                        className={`text-sm ${showPaymentCard ? 'font-h1 text-copy-inverted' : ''}`}
                      >
                        {`${multiPromotionCodeInfo?.length}x ${textContent?.promos.toLowerCase()}`}
                      </span>
                      <div
                        className={`text-sm ${
                          showPaymentCard ? 'hidden' : 'hidden md:block lg:block'
                        }`}
                      >
                        {Math.abs(promoDiscount(multiPromotionCodeInfo)) > 0 &&
                          currencyFormatter(promoDiscount(multiPromotionCodeInfo), language)}
                      </div>
                    </div>
                    {showPaymentCard && getMultiPromoInfo(multiPromotionCodeInfo)}
                  </>
                )}
              {dynamicContent?.accountSetupPage?.featureFlags?.enableMultiPromo &&
                multiPromotionCodeInfo?.length === 0 &&
                getPromoBundle() && (
                  <div className="flex flex-row justify-between">
                    <span className="text-sm tracking-wider text-copy-inverted">
                      {textContent?.promos}
                    </span>
                    <div className="text-sm font-h4">
                      {Math.abs(totalDiscount) > 0 && currencyFormatter(totalDiscount, language)}
                    </div>
                  </div>
                )}
              {showPaymentCard && getPromotionInfo(getPromoBundle())}

              {eSimSelected() && (
                <div className="flex flex-row justify-between">
                  <div className="text-xs">{simSelection}</div>
                  <div className="text-sm font-h4 font-bold">
                    {currencyFormatter(simSelectionCost, language)}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`flex flex-col self-stretch flex-1 h-auto py-4 md:py-0 lg-py-0  px-0 md:px-4 lg:px-4 my-2 md:my-6 lg:my-6 md:border-l-2 lg:border-l-2 border-white ${
                showPaymentCard
                  ? 'lg:border-t-0 md:border-t-0 block border-t-2'
                  : 'border-t-0 hidden md:block lg:block'
              }`}
            >
              <div>{getPaymentBreakdownSection()}</div>
            </div>
            <div
              className={`flex flex-col self-stretch flex-1 h-auto py-4 md:py-0 lg-py-0 px-0 md:px-4 lg:px-4 my-2 md:my-6 lg:my-6 border-white md:border-l-2 lg:border-l-2 items-end md:items-start lg:items-start ${
                showPaymentCard ? 'lg:border-t-0 md:border-t-0 border-t-2' : 'border-t-0'
              }`}
            >
              <div>{getAmountOwingSection()}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentSummary
