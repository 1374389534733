import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  formattedPhoneNumber,
  isDigitOrEmpty,
  isPostalCode,
  MAX_INPUT_LENGTH,
  sanitizeAccountNumber,
  sanitizeNameField,
  sanitizeTextField
} from '@apps/utils/formHelpers'
import { PHONE_NUMBER_OPTIONS } from '@apps/utils/constants'

/**
 * @type {React.Context<function(): {}>} PhoneNumberContext
 *
 * Initialized with phone number defaults and can be modified via the ModifyPhoneNumberContext
 */
export const PhoneNumberContext = createContext(undefined)

/**
 * @type {React.Context<function(): {}>} ModifyPhoneNumberContext
 *
 * Initialized with phone number state setter function to modify key/val pairs
 */
export const ModifyPhoneNumberContext = createContext(undefined)

/**
 * @enum PHONE_NUMBER_STATE - supported phone number state elements throughout the platform
 *
 * @type {{CREATE_NEW_NUMBER: object}} - SIM Number (19 digits)
 *
 * @type {{TRANSFER_A_NUMBER: object}} - SIM Number (19 digits)
 *
 */
export const PHONE_NUMBER_STATE = {
  SELECTED_PHONE_NUMBER_OPTION: 'selectedPhoneNumberOption',
  SELECTED_PHONE_NUMBER: 'selectedPhoneNumber',
  CREATE_NEW_NUMBER: {
    PHONE_NUMBER: 'phoneNumber',
    PHONE_NUMBER_ERROR: 'phoneNumberError',
    PROVINCE_GROUP_ID: 'provinceGroupId',
    PROVINCE_GROUP_ID_ERROR: 'provinceGroupIdError'
  },
  TRANSFER_A_NUMBER: {
    PORT_IN_AREA_CODE: 'portInAreaCode',
    PORT_IN_THREE_DIGITS: 'portInThreeDigits',
    PORT_IN_FOUR_DIGITS: 'portInFourDigits',
    PREV_PORT_IN_PHONE_NUMBER: 'prevPortInPhoneNumber',
    PORT_IN_PHONE_NUMBER: 'portInPhoneNumber',
    PORT_IN_PHONE_NUMBER_HAS_ERROR: 'portInPhoneNumberHasError',
    PORT_IN_PHONE_NUMBER_ERROR_MESSAGE: 'portInPhoneNumberErroMessage',
    PORT_IN_PHONE_NUMBER_IS_LOADING: 'portInPhoneNumberIsLoading',
    IS_PORT_IN_NUMBER_ELIGIBLE: 'isPortInNumberEligible',
    IS_PREV_PORT_IN_NUMBER_ELIGIBLE: 'isPrevPortInNumberEligible',
    IS_PORT_IN_AGREEMENT_CHECKED: 'isPortInAgreementChecked',
    IS_PORT_IN_HAS_AGREEMENT_ERROR: 'isPortInHasAgreementError',
    IS_PORT_IN_WIRELESS: 'isPortInWireless',
    ALTERNATE_CONTACT_NUMBER: 'alternateContactNumber',
    ALTERNATE_CONTACT_NUMBER_ERROR: 'alternateContactNumberError',
    WIRELINE: {
      FIRST_NAME: 'firstNameLandline',
      LAST_NAME: 'lastNameLandline',
      STREET_NUMBER: 'streetNumber',
      STREET_NAME: 'streetName',
      CITY_NAME: 'cityName',
      SELECTED_PROVINCE_LANDLINE: 'selectedProvinceLandline',
      POSTAL_CODE_LANDLINE: 'postalCodeLandline',
      INTERNET_AND_TV_SERVICES: 'internetAndTvServices',
      FIRST_NAME_ERROR: 'firstNameLandlineError',
      LAST_NAME_ERROR: 'lastNameLandlineError',
      STREET_NUMBER_ERROR: 'streetNumberError',
      STREET_NAME_ERROR: 'streetNameError',
      CITY_NAME_ERROR: 'cityNameError',
      SELECTED_PROVINCE_LANDLINE_ERROR: 'selectedProvinceLandlineError',
      POSTAL_CODE_LANDLINE_ERROR: 'postalCodeLandlineError',
      INTERNET_AND_TV_SERVICES_ERROR: 'internetAndTvServicesError'
    },
    WIRELESS: {
      SELECTED_SPID: 'selectedSpid',
      SELECTED_SPID_ERROR: 'selectedSpidError',
      IMEI: 'selectedImei',
      IMEI_ERROR: 'selectedImeiError',
      PREVIOUS_PROVIDER_ACCOUNT_NUMBER: 'previousProviderAccountNumber',
      PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR: 'previousProviderAccountNumberError'
    }
  },
  IS_COMPLETE: 'isComplete',
  PROVINCE_ID: 'provinceId',
  PROVINCE_ID_ERROR: 'provinceIdError'
}

// setup our initial state for our context

export const createNewNumberState = {
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER_ERROR]: '',
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: '',
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
}

export const transferANumberCommonState = {
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]: undefined,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]: undefined,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_HAS_AGREEMENT_ERROR]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: undefined,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]: ''
}

export const transferANumberWirelessState = {
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]: null,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR]: ''
}

export const transferANumberWirelineState = {
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]: ''
}

const initialState = {
  [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER,
  [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.IS_COMPLETE]: false,
  ...createNewNumberState,
  ...transferANumberCommonState,
  ...transferANumberWirelessState,
  ...transferANumberWirelineState,
  [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
  [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
}

let state
let setState

/**
 * @typedef {object} ActivationContextAPI
 *
 * @property {object} state - current app state, ie: current language
 * @property {React.Dispatch<any>} setState - the set state method for the current app state
 *
 */
const PhoneNumberProvider = ({ children }) => {
  // eslint-disable-next-line
  ;[state, setState] = useState({
    ...initialState
  })

  return (
    <PhoneNumberContext.Provider value={state}>
      <ModifyPhoneNumberContext.Provider value={setState}>
        {children}
      </ModifyPhoneNumberContext.Provider>
    </PhoneNumberContext.Provider>
  )
}

// TODO: Copy Pasta (move into a util)

/**
 * @typedef function UpdatePhoneNumberState
 *
 * Updates the provided key,val in Phone Number State, without destroying other values
 *
 * @param key Target element
 * @param value Value for target element
 */

export const UpdatePhoneNumberState = (key, value) => {
  setState((previousState) => {
    Object.assign(previousState, { [key]: value })
    const isComplete = PhoneNumberComplete(previousState)
    return {
      ...previousState,
      [PHONE_NUMBER_STATE.IS_COMPLETE]: isComplete
    }
  })
}

export const UpdatePhoneNumberStates = (newStates) => {
  setState((previousState) => {
    Object.assign(previousState, newStates)
    const isComplete = PhoneNumberComplete(previousState)
    return {
      ...previousState,
      [PHONE_NUMBER_STATE.IS_COMPLETE]: isComplete
    }
  })
}

const requiredFields = {
  [PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER]: [
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER],
    [PHONE_NUMBER_STATE.PROVINCE_ID],
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]
  ],
  [PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER]: {
    wireless: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]
    ],
    wireline: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]
    ]
  }
}

const errorFields = {
  [PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER]: [
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER_ERROR],
    [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR],
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]
  ],
  [PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER]: {
    wireless: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]
    ],
    wireline: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]
    ]
  }
}

const isNullOrEmpty = (value) => {
  if (value === '' || value === null) {
    return true
  }
  return false
}

export const PhoneNumberComplete = (newState) => {
  const flow = newState[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]
  let fields = requiredFields[newState[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]]
  let errors = errorFields[newState[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]]
  const isWireless = newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]

  if (flow === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER) {
    if (isWireless) {
      // if no account num or imei
      if (!isNullOrEmpty(newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI])) {
        if (!isNullOrEmpty(newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR])) {
          return false
        }
      } else if (
        !isNullOrEmpty(
          newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]
        )
      ) {
        if (
          !isNullOrEmpty(
            newState[
              PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR
            ]
          )
        ) {
          return false
        }
      } else {
        return false
      }
    }

    fields = isWireless
      ? requiredFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireless
      : requiredFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireline

    errors = isWireless
      ? errorFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireless
      : errorFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireline
  }

  if (fields && errors) {
    const emptyRequiredField = fields.find((field) => isNullOrEmpty(newState[field]))
    if (emptyRequiredField) {
      return false
    }

    const hasError = errors.find((error) => !isNullOrEmpty(newState[error]))
    if (hasError) {
      return false
    }

    return true
  }
}

/*
 * Event Functions
 */

export const PHONE_NUMBER_EVENTS = {
  PROVINCE: 'event.target.phoneNumberSetup.province',
  PROVINCE_LANDLINE: 'event.target.phoneNumberSetup.provinceLandline',
  PROVINCE_GROUP: 'event.target.phoneNumberSetup.provinceGroup',
  SPID: 'event.target.phoneNumberSetup.spid',
  ACCOUNT_NUMBER: 'event.target.phoneNumberSetup.accountNumber',
  ALTERNATE_CONTACT_NUMBER: 'event.target.phoneNumberSetup.alternateContactNumber',
  POSTAL_CODE: 'event.target.phoneNumberSetup.postalCode',
  INPUT: 'event.target.phoneNumberSetup.input',
  IMEI: 'event.target.phoneNumberSetup.imei',
  INTERNET_TV: 'event.target.phoneNumberSetup.internetAndTv',
  PHONE_NUMBER: 'event.target.phoneNumberSetup.phoneNumber'
}

const formatNumberToPort = (rawNumber) => {
  let number

  if (/^\d+$/.test(rawNumber)) {
    if (rawNumber && rawNumber.length === 11 && rawNumber.startsWith('1')) {
      number = rawNumber
    } else if (rawNumber && rawNumber.length === 10) {
      number = `1${rawNumber}`
    }
  }

  return number
}

PhoneNumberProvider.Common = {
  [PHONE_NUMBER_EVENTS.PHONE_NUMBER]: {
    onBlur: async (phoneRequired, phoneInvalid, isEligibleForPort, validCanadianNumber) => {
      const phoneNumber = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]
      const prevPhoneNumber = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]
      const isPrevPortInNumberEligible =
        state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]

      if (phoneNumber.length === 0) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneRequired
        })
        return true
      }
      if (phoneNumber.length > 0 && phoneNumber.length < 10) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneInvalid
        })
        return true
      }
      if (phoneNumber.length === 10) {
        if (prevPhoneNumber !== phoneNumber || isPrevPortInNumberEligible) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
              .PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: validCanadianNumber,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false
          })
          return true
        }
      }

      if (phoneNumber.length === 10 && prevPhoneNumber !== phoneNumber) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: phoneNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: true
        })
        const { data: checkPortResp } = await isEligibleForPort(formatNumberToPort(phoneNumber))
        // Uncomment below line to make number eligible
        // checkPortResp.isEligible = true
        let updateActivationState = {}
        if (checkPortResp) {
          updateActivationState = {
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: false,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]:
              checkPortResp.isEligible,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: checkPortResp.isWireless,
            [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: checkPortResp.isEligible
              ? formatNumberToPort(phoneNumber)
              : '',
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: phoneNumber,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]:
              checkPortResp.isEligible
          }
          if (checkPortResp.isEligible) {
            UpdatePhoneNumberStates({
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: ''
            })
            // Load spids
          } else {
            UpdatePhoneNumberStates({
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
                .PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: validCanadianNumber,
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false
            })
            return true
          }

          UpdatePhoneNumberStates({
            ...updateActivationState,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false
          })
          return false
        }
      }
    }
  },
  [PHONE_NUMBER_EVENTS.PROVINCE]: {
    onBlur: (provinces, errorCopy, value = '') => {
      if (value === '') {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceRequired
        })
        return true
      }

      const matchedProv = provinces && provinces.find((prov) => prov.name === value)
      if (!matchedProv) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceInvalid
        })
        return true
      }

      if (!state[PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]) {
        if (!state[PHONE_NUMBER_STATE.PROVINCE_ID]) {
          UpdatePhoneNumberState(PHONE_NUMBER_STATE.PROVINCE_ID_ERROR, errorCopy.provinceRequired)
          return true
        }
        if (
          !provinces.find((province) => province.value === state[PHONE_NUMBER_STATE.PROVINCE_ID])
        ) {
          UpdatePhoneNumberState(PHONE_NUMBER_STATE.PROVINCE_ID_ERROR, errorCopy.provinceInvalid)
          return true
        }
        UpdatePhoneNumberState(PHONE_NUMBER_STATE.PROVINCE_ID_ERROR, '')
        return false
      }
    },
    onChange: (value, provinces, errorCopy) => {
      if (value) {
        const matchedProv = provinces && provinces.find((prov) => prov.name === value)
        if (matchedProv) {
          const id = matchedProv.value
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.PROVINCE_ID]: id,
            [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceRequired
        })
      }
    },
    onFocus: () => {
      UpdatePhoneNumberStates({
        [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
      })
    }
  },
  [PHONE_NUMBER_EVENTS.PROVINCE_LANDLINE]: {
    onBlur: (provinces, errorCopy) => {
      if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]) {
        if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]) {
          UpdatePhoneNumberState(
            PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR,
            errorCopy.provinceRequired
          )
          return true
        }
        if (
          !provinces.find(
            (province) =>
              province.value ===
              state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]
          )
        ) {
          UpdatePhoneNumberState(
            PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR,
            errorCopy.provinceInvalid
          )
          return true
        }
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR,
          ''
        )
        return false
      }
    },
    onChange: (value, provinces, errorCopy) => {
      if (value) {
        const matchedProv = provinces.find((prov) => prov.name === value)
        if (matchedProv) {
          const id = matchedProv.value
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: id,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]:
              errorCopy.provinceInvalid
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]:
            errorCopy.provinceRequired
        })
      }
    }
  },
  [PHONE_NUMBER_EVENTS.PROVINCE_GROUP]: {
    onBlur: (cities, errorCopy) => {
      const value = state[PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]
      if (!state[PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]) {
        if (isNullOrEmpty(value)) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: errorCopy.cityRequired
          })
          return true
        } else if (!cities.find((city) => city.value === value)) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: errorCopy.cityInvalid
          })
          return true
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
          })
          return false
        }
      }
      return false
    },
    onChange: (value, cities, errorCopy) => {
      if (value !== '') {
        const matchedCity = cities.find((city) => city.name === value)

        if (matchedCity) {
          const id = matchedCity.value
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: id,
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: '',
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: errorCopy.cityInvalid
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: '',
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: errorCopy.cityRequired
        })
      }
    },
    onFocus: () => {
      UpdatePhoneNumberStates({
        [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
      })
    }
  },
  [PHONE_NUMBER_EVENTS.SPID]: {
    onBlur: (spids, errors) => {
      const spid = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID
      const spidError = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR

      if (isNullOrEmpty(state[spidError])) {
        if (state[spid] === null) {
          UpdatePhoneNumberState(spidError, errors.requiredError)
          return true
        }
        if (!spids.find((province) => province.value === state[spid])) {
          UpdatePhoneNumberState(spidError, errors.invalidError)
          return true
        }
        UpdatePhoneNumberState(spidError, null)
        return false
      }
    },
    onChange: (e, errors, spids) => {
      const spid = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID
      const spidError = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR
      if (e.target.value !== '') {
        const matchedProv = spids.find((prov) => prov.name === e.target.value)
        if (matchedProv) {
          const id = matchedProv.value
          UpdatePhoneNumberStates({ [spid]: id, [spidError]: null })
        } else {
          UpdatePhoneNumberStates({ [spid]: null, [spidError]: errors.invalidError })
        }
      } else {
        UpdatePhoneNumberStates({ [spid]: null, [spidError]: errors.requiredError })
      }
    }
  },
  [PHONE_NUMBER_EVENTS.ACCOUNT_NUMBER]: {
    onChange: (e) => {
      const { value } = e.target
      const updateKey =
        PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER
      let sanitizedValue = sanitizeTextField(value)
      sanitizedValue = sanitizeAccountNumber(sanitizedValue)
      UpdatePhoneNumberState(updateKey, sanitizedValue)
    },
    onBlur: async (errors, maxLength) => {
      const value =
        state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]
      const errorKey =
        PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR
      if (value === '') {
        UpdatePhoneNumberState(errorKey, errors.requiredErrorMessage)
        return true
      }
      if (value.length !== maxLength) {
        UpdatePhoneNumberState(errorKey, errors.invalidErrorMessage)
        return true
      }
      UpdatePhoneNumberState(errorKey, null)
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.ALTERNATE_CONTACT_NUMBER]: {
    onChange: (e) => {
      const { value } = e.target
      const updateKey = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER
      const sanitizedValue = value.replace(/\s|-/g, '').substring(0, 12)
      if (isNaN(sanitizedValue)) return
      const formattedValue = formattedPhoneNumber(sanitizedValue)
      UpdatePhoneNumberState(updateKey, formattedValue)
    },
    onBlur: async (errors, maxLength) => {
      const value = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]
      const errorKey = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR
      if (value === '') {
        UpdatePhoneNumberState(errorKey, errors.requiredErrorMessage)
        return true
      }
      if (value.length !== maxLength) {
        UpdatePhoneNumberState(errorKey, errors.invalidErrorMessage)
        return true
      }
      UpdatePhoneNumberState(errorKey, null)
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.POSTAL_CODE]: {
    onChange: (value) => {
      const firstLetterMatch = new RegExp(/([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])/)
      const letterMatch = new RegExp(/([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])/)
      const digitMatch = new RegExp(/\d/)

      const stringArray = value.split('')

      if (stringArray.length > 3 && stringArray.indexOf(' ') === -1) {
        stringArray.splice(3, 0, ' ')
      }
      const letters = [0, 2, 5]
      const numbers = [1, 4, 6]

      let matches = true

      stringArray.forEach((char, i) => {
        if (letters.indexOf(i) !== -1) {
          if (i === 0 && !char.match(firstLetterMatch)) {
            matches = false
          } else if (!char.match(letterMatch)) {
            matches = false
          }
        } else if (numbers.indexOf(i) !== -1) {
          if (!char.match(digitMatch)) {
            matches = false
          }
        }
      })
      if (matches) {
        const result = stringArray.join('')
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE,
          result
        )
      }
    },
    onBlur: (errors) => {
      const value = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]
      if (value === '') {
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR,
          errors.postalCodeRequired
        )
        return true
      }
      const postalMatch = new RegExp(
        /([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])(.?)\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])\d/g
      )
      if (!value.match(postalMatch)) {
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR,
          errors.postalCodeInvalid
        )
        return true
      }
      UpdatePhoneNumberState(
        PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR,
        null
      )
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.INPUT]: {
    onChange: (e, updateKey) => {
      const { value } = e.target
      const sanitizedValue = sanitizeTextField(value)
      if (isDigitOrEmpty(sanitizedValue)) {
        UpdatePhoneNumberState(updateKey, sanitizedValue)
      }
    },
    onChangeName: (e, updateKey) => {
      const { value } = e.target
      let sanitizedValue = value
      if (/^([\s]).*/.test(value)) {
        sanitizedValue = sanitizedValue.trim()
      }
      UpdatePhoneNumberState(updateKey, sanitizeNameField(sanitizedValue))
    },
    onBlur: (
      value,
      errorKey,
      requiredErrorMessage,
      invalidErrorMessage,
      maxLength = MAX_INPUT_LENGTH
    ) => {
      if (value === '') {
        UpdatePhoneNumberState(errorKey, requiredErrorMessage)
        return true
      }
      if (value.length !== maxLength) {
        UpdatePhoneNumberState(errorKey, invalidErrorMessage)
        return true
      }
      UpdatePhoneNumberState(errorKey, null)
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.IMEI]: {
    onChange: (e) => {
      const { value } = e.target
      const sanitizedValue = sanitizeTextField(value)
      if (isDigitOrEmpty(sanitizedValue)) {
        UpdatePhoneNumberState(PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI, sanitizedValue)
      }
    },
    onBlur: async (errors) => {
      const imeiLength = new RegExp(/^(\d{14,15})$/)
      const value = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]
      if (value === '') {
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR,
          errors.requiredErrorMessage
        )
        return true
      }
      if (!value.match(imeiLength)) {
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR,
          errors.invalidErrorMessage
        )
        return true
      }
      UpdatePhoneNumberState(PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR, null)
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.INTERNET_TV]: {
    onBlur: (options, errorCopy) => {
      if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]) {
        if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]) {
          UpdatePhoneNumberState(
            PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR,
            errorCopy.requiredErrorMessage
          )
          return true
        }
        if (
          !options.find(
            (opt) =>
              opt.value ===
              state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]
          )
        ) {
          UpdatePhoneNumberState(
            PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR,
            errorCopy.invalidErrorMessage
          )
          return true
        }
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR,
          ''
        )
        return false
      }
      return true
    },
    onChange: (value, options, errorCopy) => {
      if (value) {
        const matchedOpt = options.find((opt) => opt.name === value)
        if (matchedOpt) {
          const id = matchedOpt.value
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: id,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: '',
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]:
              errorCopy.invalidErrorMessage
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: '',
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]:
            errorCopy.requiredErrorMessage
        })
      }
    }
  }
}

export const getPhoneNumber = () => {
  return state[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION] ===
    PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER
    ? state[PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER].label
    : `(${state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]}) 
    ${state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]}
     - 
    ${state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]}`
}
// TODO: phone number formatter util

PhoneNumberProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default PhoneNumberProvider
