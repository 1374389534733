import Logo from './logo.svg'
import ChevronDown from './chevron-down.svg'
import ChevronDownCircle from './chevron-down-circle.svg'
import ChevronUpCircle from './chevron-up-circle.svg'
import ChevronDownCircleInverted from './chevron-down-circle-inverted.svg'
import ChevronUpCircleInverted from './chevron-up-circle-inverted.svg'
import CircleCheck from './circle-check.svg'
import Cut1 from './cut1.svg'
import Cut2 from './cut2.svg'
import Error from './error.svg'
import Check from './check.svg'
import CircleQuestionMark from './icon-tooltip.png'
import Print from './print.svg'
import VerifiedCheckMark from './icon-verified.svg'
import Close from './button-purple-close.svg'
import KoodoCutLeft from './koodo-cuts-l-left-med.svg'
import Hamburger from './icon_hamburger.svg'
import CutRightThick from './koodo-cuts-r-thick@2x.png'
import CreditCards from './credit-cards-intropage.png'
import NumberChange from './number-change-intropage.png'
import Plans from './plans-intro-page.png'
import SimCard from './sim-card-intropage.png'
import ChevronDownWhite from './chevron-down-white.svg'
import ChevronUpWhite from './chevron-up.svg'
import KoodoCuts from './R-large.png'
import ChevronBack from './chevron-back.svg'
import SIMCardBelow from './sim-card-below.png'
import SIMCardPrinted from './sim-card-printed.png'
import KoodoRSmallCuts from './koodo-cuts-r-small.svg'
import ErrorImage from './ice-cream.png'
import Balloon from './balloon.png'
import Donut from './donut.png'

export default {
  logo: Logo,
  chevronDown: ChevronDown,
  chevronUpCircle: ChevronUpCircle,
  chevronDownCircle: ChevronDownCircle,
  chevronDownCircleInverted: ChevronDownCircleInverted,
  chevronUpCircleInverted: ChevronUpCircleInverted,
  circleCheck: CircleCheck,
  cut1: Cut1,
  cut2: Cut2,
  exclamation: Error,
  check: Check,
  print: Print,
  verifiedCheckMark: VerifiedCheckMark,
  close: Close,
  circleQuestionMark: CircleQuestionMark,
  koodoCutLeft: KoodoCutLeft,
  hamburger: Hamburger,
  cutRightThick: CutRightThick,
  creditCards: CreditCards,
  numberChange: NumberChange,
  plans: Plans,
  simCard: SimCard,
  chevronDownWhite: ChevronDownWhite,
  chevronUpWhite: ChevronUpWhite,
  koodoCuts: KoodoCuts,
  chevronBack: ChevronBack,
  simCardBelow: SIMCardBelow,
  simCardPrinted: SIMCardPrinted,
  koodoRSmallCuts: KoodoRSmallCuts,
  errorImage: ErrorImage,
  balloon: Balloon,
  donut: Donut
}
