import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PlansSection from '@apps/flows/activation/sections/PlansSection'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { ContentContext } from '@apps/contexts/ContentContext'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import Button from '@apps/components/Button'
import BodyBold from '@apps/components/Typography/BodyBold'
import { useLocation } from 'react-router'
import Sites from '@sites/index'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { APPLICATION_STATE, ApplicationContext, setLandingLogicLoader } from '@apps/contexts/ApplicationContext'
import { useSelector } from 'react-redux'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'

const PlansPage = () => {
  const {
    userType,
    user: { email }
  } = useSelector((state) => state.common)
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()
  const history = useHistory()

  const { step, phoneNumber, payment, dynamicContent } = useContext(ContentContext)

  if (dynamicContent && dynamicContent.accountSetupPage.featureFlags.showSplashing) {
    history.push(consumerActivationPaths.Splash)
  }

  const { [PLANS_STATE.PLAN]: plan } = useSelector((state) => state.plansPage)

  const { chevronBack } = useContext(AssetsContext)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region,
    [APPLICATION_STATE.SERVICES]: { setPatchQuote }
  } = useContext(ApplicationContext)

  const location = useLocation()
  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [])

  const [showPlanError, setShowPlanError] = useState(false)
  const planErrorRef = useRef(null)

  const updateQuote = async () => {
    try {
      const input = { email, pricePlanId: plan.id, pricePlanGuid: plan.guid }
      const response = await setPatchQuote(input)
      return response
    } catch (err) {
      console.log('UPDATE QUOTE: PLAN SELECTION ERROR', err)
      return err
    }
  }

  const handleNextClick = async () => {
    try {
      setLandingLogicLoader('IN_PROGRESS')
      if (plan?.id) {
        const updateQuoteResponse = await updateQuote()
        let nextPagePath = consumerActivationPaths.Payment
        let splashPagePath = consumerActivationPaths.Splash
  
        if (userType === Sites.dealer) {
          nextPagePath = dealerActivationPaths.Payment
          splashPagePath = dealerActivationPaths.Splash
        }
  
        if (updateQuoteResponse?.data?.accountId) {
          history.push(nextPagePath)
        } else {
          history.push(splashPagePath)
        }
      } else {
        setShowPlanError(true)
        planErrorRef.current.scrollIntoView({
          behavior: 'smooth'
        })
      }
    } finally {
      setLandingLogicLoader(false)
    }
  }

  return (
    <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-4">
      <PlansSection
        planErrorRef={planErrorRef}
        showPlanError={showPlanError}
        setShowPlanError={() => setShowPlanError()}
      />
      <div className="flex justify-between pt-16">
        <Link className="flex items-center justify-start" to={consumerActivationPaths.ChooseNumber}>
          <img alt="back button" src={chevronBack} />
          <BodyBold className="pl-2 text-h1 underline">{`${step} 3: ${phoneNumber.sectionTitle}`}</BodyBold>
        </Link>
        <Button
          onClick={() => handleNextClick()}
          label={`${step} 5: ${payment.sectionTitle}`}
          testId="plans-nextStep"
        />
      </div>
    </div>
  )
}

export default PlansPage
