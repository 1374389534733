import React, { useContext } from 'react'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import BodySmallBold from '../Typography/BodySmallBold'

const ValidationCard = ({
  children,
  title,
  visibility,
  customImageStyles
}) => {
  const { koodoRSmallCuts } = useContext(AssetsContext)
  return (
    <div className={`relative w-full ${visibility}`}>
      <div className="p-3 text-sm text-black rounded bg-card-background lg:p-6">
        {title && <BodySmallBold styles="mb-1">{title}</BodySmallBold>}
        {children}
      </div>
      {koodoRSmallCuts && (
      <img
        className={`${customImageStyles} absolute bottom-0 right-0 mx-auto my-0`}
        src={koodoRSmallCuts}
        alt="Koodo cuts"
      />
      )}
    </div>
  )
}

export default ValidationCard
