import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import CustomTab from '@apps/components/CustomTab'
import CustomTabItem from '@apps/components/CustomTab/CustomTabItem'
import { PLAN_DURATION } from '@apps/utils/constants'
import { ContentContext } from '@apps/contexts/ContentContext'

const ChoosePlanSection = ({ onSelect, selectedOption }) => {
  const {
    plans: { planDuration30days, planDuration360days }
  } = useContext(ContentContext)

  const onSelectHandler = (e) => {
    onSelect(e)
  }

  return (
    <CustomTab onSelect={onSelectHandler}>
      <CustomTabItem
        tabindex="0"
        role="tabpanel"
        id={PLAN_DURATION.SLOT_30}
        name={PLAN_DURATION.SLOT_30}
        selected={selectedOption === PLAN_DURATION.SLOT_30}
        data-testid="planDuration-30days"
      >
        <div
          className={`text-center text-sm md:text-base lg:text-medium ${
            selectedOption === PLAN_DURATION.SLOT_30 && 'font-bold'
          }`}
        >
          {planDuration30days}
        </div>
      </CustomTabItem>
      <CustomTabItem
        tabindex="0"
        role="tabpanel"
        id={PLAN_DURATION.SLOT_360}
        name={PLAN_DURATION.SLOT_360}
        selected={selectedOption === PLAN_DURATION.SLOT_360}
        data-testid="planDuration-360days"
      >
        <div
          className={`text-center text-sm md:text-base lg:text-medium ${
            selectedOption === PLAN_DURATION.SLOT_360 && 'font-bold'
          }`}
        >
          {planDuration360days}
        </div>
      </CustomTabItem>
    </CustomTab>
  )
}

ChoosePlanSection.propTypes = {
  onSelect: PropTypes.func,
  selectedOption: PropTypes.string
}

export default ChoosePlanSection
