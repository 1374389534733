import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from '@apps/assets/error.svg'

const Alert = ({ header, message }) => {
  return (
    <div className="bg-alert-background text-black py-4 px-6 my-6 w-full">
      <div className="flex items-start mb-3">
        <img className="h-6 mr-3" src={ErrorIcon} alt="Error Icon" />
        <p className="text-sm font-h3 leading-6">{header}</p>
      </div>
      <p className="text-sm font-body leading-6">{message}</p>
    </div>
  )
}

Alert.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default Alert
