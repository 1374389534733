import React, { useRef, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Error from '@apps/assets/error.svg'
import { PaymentContext,PAYMENT_STATE } from '@apps/redux/features/PayAndReviewSlice'


const CreditCardFormEPS = ({
    cardErrorMessage,
    cardNumberLabel,
    postalCodeLabel,
    postalErrorMessage,
    onChangePostal,
    onBlurPostal,
    valuePostal,
    expiryDateLabel,
    expiryErrorMessage,
    labelSecurityCode,
    cvvErrorMessage,
    onFocusPostal
}) => {

    const paymentState = useSelector((state) => state.payAndReviewPage)

    const {
        [PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD]: paymentScrollToErrField
    } = paymentState

    const ccRef = useRef(null)
    const expiryRef = useRef(null)
    const cvvRef = useRef(null)
    const postalRef = useRef(null)

    useEffect(() => {
    
        if(paymentScrollToErrField) {
            switch(paymentScrollToErrField) {
                case 'cardNumber':
                    ccRef.current.scrollIntoView({
                        behavior: "smooth"
                      })
                break
                case 'expiryDate':
                    expiryRef.current.scrollIntoView({
                        behavior: "smooth"
                      })
                break
                case 'cvv':
                    cvvRef.current.scrollIntoView({
                        behavior: "smooth"
                      })
                break
                case 'postal':
                    postalRef.current.scrollIntoView({
                        behavior: "smooth"
                      })
                break
                default:
                    postalRef.current.scrollIntoView({
                        behavior: "smooth"
                      })
                break
            }
    
        }

    }, [paymentScrollToErrField])


    return (
        <form>
            <div className="flex flex-row justify-between">
                <label
                ref={ccRef}
                className="block text-unselected-text">
                    <h4>{cardNumberLabel}</h4>
                </label>
            </div>
            <div id="CardNumber" 
            className="bg-white border-primary border-solid border h-11">
            </div>
            {
                cardErrorMessage
                &&
                <div className='flex mt-1'>
                    <img className="h-4" src={Error} alt="" />
                    <p className="text-error text-xs ml-1 mt-0.5 leading-4">
                        {cardErrorMessage}
                    </p>
                </div>
            }

            <div className="flex mt-6">
                <div className="w-1/2">
                    <label className="block text-unselected-text"
                    ref={expiryRef}
                    >
                        <h4>{expiryDateLabel}</h4>
                    </label>
                    <div id="ExpiryDate"
                    className="bg-white border-primary border-solid border h-11">
                    </div>
                    {
                        expiryErrorMessage
                        &&
                        <div className='flex mt-1'>
                            <img className="h-4" src={Error} alt="" />
                            <p className="text-error text-xs ml-1 mt-0.5 leading-4">
                                {expiryErrorMessage}
                            </p>
                        </div>
                    }
                </div>
                <div className="w-1/2 ml-5">
                    <label className="block text-unselected-text"
                    ref={cvvRef}
                    >
                        <h4>{labelSecurityCode}</h4>
                    </label>
                    <div id="Cvv" 
                    className="bg-white border-primary border-solid border h-11">
                    </div>
                    <div className="flex items-center" data-testid="payment-creditSecurity-error">
                        {
                            cvvErrorMessage
                            &&
                            <div className='flex mt-1'>
                                <img className="h-4" src={Error} alt="" />
                                <p className="text-error text-xs ml-1 mt-0.5 leading-4">
                                    {cvvErrorMessage}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            
            <div className='mt-6'>
                <label
                ref={postalRef}
                className="block text-unselected-text" htmlFor="postalCode">
                    <h4>{postalCodeLabel}</h4>
                </label>
                <input type="text" 
                    id="postalCode" 
                    value={valuePostal}
                    onBlur={onBlurPostal}
                    autoComplete="off"
                    maxLength="7"
                    onChange={onChangePostal}
                    onFocus={onFocusPostal}
                />
                {
                    postalErrorMessage
                    &&
                    <div className='flex mt-1'>
                        <img className="h-4" src={Error} alt="" />
                        <p className="text-error text-xs ml-1 mt-0.5 leading-4">
                            {postalErrorMessage}
                        </p>
                    </div>
                }
            </div>
        </form>
    )
}

export default CreditCardFormEPS

