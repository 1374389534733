/* eslint-disable react/require-default-props */
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { ContentContext } from '@apps/contexts/ContentContext'
import PropTypes from 'prop-types'
import Alert from '@apps/components/Alert'
import { getById, getBySlug } from '@apps/utils/contentful'
import PhoneCompatibility from '@apps/components/PhoneCompatibility'
import RoundedButton from '@apps/components/RoundedButton'

const PhoneDetails = ({
  phoneImage,
  manufacturer,
  model,
  imei,
  eSimCompatible,
  onChoosePSimClick,
  lteCompatible = false,
  showIncompatibleMessage = true
}) => {
  const { pageContent } = useSelector((state) => state.common.content)
  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)
  const haveSimPageContent = getById(pageContent, `haveSimPage`)[language]
  const { shortTextFields } = haveSimPageContent

  const { dynamicContent } = useContext(ContentContext)

  const { haveSimPage = {} } = dynamicContent || {}
  const { content } = haveSimPage

  const pageTextContent = useMemo(() => {
    if (!content && !shortTextFields) return {}
    return {
      successIcon: getById(content, 'success-icon')?.image?.url,
      failIcon: getById(content, 'fail-icon')?.image?.url,
      eSim: getBySlug(shortTextFields, 'eSIM')?.value,
      lteNetwork: getBySlug(shortTextFields, 'lteNetwork')?.value,
      manufacturer: getBySlug(shortTextFields, 'manufacturer')?.value,
      model: getBySlug(shortTextFields, 'model')?.value,
      imei: getBySlug(shortTextFields, 'imei')?.value,
      eSimIncompatible: getBySlug(shortTextFields, 'eSimIncompatible')?.value,
      eSimNotCompatibleMessage: getBySlug(shortTextFields, 'eSimNotCompatibleMessage')?.value,
      purchaseESIM: getBySlug(shortTextFields, 'purchaseESIM')?.value,
      purchaseESIMWarning: getBySlug(shortTextFields, 'purchaseESIMWarning')?.value,
      physicalSIMHere: getBySlug(shortTextFields, 'physicalSIMHere')?.value,
      getNewPhysicalSIM: getBySlug(shortTextFields, 'getNewPhysicalSIM')?.value,
      instead: getBySlug(shortTextFields, 'instead')?.value
    }
  }, [content, shortTextFields])

  return (
    <div className="flex flex-col w-full">
      <div className="w-full grid grid-cols-12 pb-6">
        <div className="col-start-1 col-end-12 sm:col-end-3 pl-6 flex flex-row justify-center">
          <img alt="Phone" src={phoneImage} className="w-min min-w-min" />
        </div>
        <div className="col-start-1 col-end-13 sm:col-start-4 sm:col-end-10 flex flex-col justify-center">
          <div className="mb-6">
            <PhoneCompatibility feature={pageTextContent?.eSim} compatible={eSimCompatible} />
            <PhoneCompatibility feature={pageTextContent?.lteNetwork} compatible={lteCompatible} />
          </div>
          <div className="grid grid-cols-2 grid-flow-row gap-3 h-24 w-72">
            <div className="font-bodySmall font-semibold">{pageTextContent.manufacturer}</div>
            <div className="text-sm font-body">{manufacturer}</div>
            <div className="font-bodySmall font-semibold">{pageTextContent.model}</div>
            <div className="text-sm font-body">{model}</div>
            <div className="font-bodySmall font-semibold">{pageTextContent.imei}</div>
            <div className="text-sm font-body">{imei}</div>
          </div>
        </div>
      </div>
      {!eSimCompatible && showIncompatibleMessage && (
        <div>
          <Alert
            header={pageTextContent.eSimIncompatible}
            message={pageTextContent?.eSimNotCompatibleMessage}
          />
          <div className="w-full">
            <RoundedButton onClick={onChoosePSimClick} label={pageTextContent?.getNewPhysicalSIM} />
          </div>
        </div>
      )}
    </div>
  )
}

PhoneDetails.propTypes = {
  eSimCompatible: PropTypes.bool.isRequired,
  manufacturer: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  imei: PropTypes.string.isRequired,
  phoneImage: PropTypes.string.isRequired,
  lteCompatible: PropTypes.bool,
  showIncompatibleMessage: PropTypes.bool
}

export default PhoneDetails
