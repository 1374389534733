import React, { useContext } from 'react'
import { AssetsContext } from '@apps/contexts/AssetsContext'

const BulletCard = ({ children }) => {
  const { cutRightThick } = useContext(AssetsContext)

  return (
    <div style={{ 
      backgroundImage: `url("${cutRightThick}")`,
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '64px 100%'
      }} className="rounded bg-card-background pl-4 pr-16 py-42">
      {children}
    </div>
  )
}

export default BulletCard