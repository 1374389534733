/* eslint-disable react/require-default-props */
import React, { useContext, useMemo, useState } from 'react'
import BodySmall from '@apps/components/Typography/BodySmall'
import { AssetsContext } from '@apps/contexts/AssetsContext'
import { ContentContext } from '@apps/contexts/ContentContext'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import Body from '@apps/components/Typography/Body'
import Heading4 from '@apps/components/Typography/Heading4'
import PropTypes from 'prop-types'
import { getBySlug, getById, getImgByTitle, getShortTextFromPage } from '@apps/utils/contentful'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import { useSelector } from 'react-redux'

const SIMCardNumber = ({ className = '' }) => {
  const { dynamicContent } = useContext(ContentContext)
  const { haveSimPage = {} } = dynamicContent || {}
  const { shortTextFields, content, featureFlags } = haveSimPage

  const newESimFlow = featureFlags?.newESimFlow

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)
  const { pageContent: currentPageContent } = useSelector((state) => state.common.content)
  const haveSimPageContent = getById(currentPageContent, `haveSimPage`)[language]

  const pageContent = useMemo(() => {
    if (!shortTextFields && !content) return {}
    return {
      findSimCardNumber: getBySlug(shortTextFields, 'findSimCardNumber')?.value,
      simCardBelowImg: getImgByTitle(haveSimPageContent, 'your-sim-card')?.url,
      simCardBelowImgText: getImgByTitle(haveSimPageContent, 'your-sim-card')?.altText,
      simCardNumberLocation: getShortTextFromPage(haveSimPageContent, 'sim-card-number-location'),
      simCardNumberDetails: getShortTextFromPage(haveSimPageContent, 'sim-card-number-details')
    }
  }, [shortTextFields, content, language])

  const { chevronUpCircle, chevronDownCircle } = useContext(AssetsContext)

  const [toggleStatus, setToggleStatus] = useState(false)

  const toggleSection = () => {
    setToggleStatus(!toggleStatus)
  }

  const spacing = newESimFlow ? 'justify-between p-4' : 'justify-around p-8'
  const view = (
    <div
      className={`flex flex-col lg:flex-row ${spacing} items-center lg:items-start space-y-1 lg:space-y-0 bg-customCard`}
    >
      <div className="flex flex-col items-center mt-5">
        <img src={pageContent?.simCardBelowImg} alt={pageContent?.simCardBelowImgText}  />
      </div>
      <div>
        <BodySmall className="mb-1 sm:mb-0 block"></BodySmall>
      </div>
      <div className="flex flex-col max-w-sm">
         <Heading4 className='text-medium font-bold leading-7 font-h3 my-6 text-primary'>{pageContent?.simCardNumberLocation}</Heading4>
         <Body className='mb-8'>{pageContent?.simCardNumberDetails}</Body>
      </div>
    </div>
  )

  if (newESimFlow) {
    return <div className={className}>{view}</div>
  }

  return (
    <div className={className}>
      <div
        onClick={() => toggleSection()}
        className="flex justify-between cursor-pointer lg:hidden"
      >
        <BodySmallBold>{pageContent?.findSimCardNumber}</BodySmallBold>
        <img src={toggleStatus === false ? chevronDownCircle : chevronUpCircle} alt="chevron" />
      </div>
      <div
        className={`${toggleStatus} ${
          toggleStatus === true ? 'block lg:block' : 'hidden lg:block'
        }`}
      >
        {view}
      </div>
    </div>
  )
}

SIMCardNumber.propTypes = {
  className: PropTypes.string
}

export default SIMCardNumber
