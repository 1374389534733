import Logo from './pm-logo.png'
import ChevronDown from './chevron-down.svg'
import ChevronDownCircle from './down_arrow.png'
import ChevronUpCircle from './up_arrow.png'
import ChevronDownCircleInverted from './chevron-down-circle-inverted.svg'
import ChevronUpCircleInverted from './chevron-up-circle-inverted.svg'
import Cut1 from './cut1.svg'
import Cut2 from './cut2.svg'
import CreditCards from './payment.png'
import NumberChange from './switch_numbers.png'
import Plans from './pick_plan.png'
import SimCard from './sim_card.png'
import Error from '../error.svg'
import Check from './check.svg'
import SIMCardBelow from './sim_back.png'
import SIMCardPrinted from './sim_front.png'
import CircleCheck from './circle_checkmark.svg'
import ChevronBack from './chevron-back.svg'
import circleQuestionMark from './tooltip@3x.png'

export default {
  logo: Logo,
  chevronDown: ChevronDown,
  chevronUpCircle: ChevronUpCircle,
  chevronDownCircle: ChevronDownCircle,
  chevronDownCircleInverted: ChevronDownCircleInverted,
  chevronUpCircleInverted: ChevronUpCircleInverted,
  cut1: '',
  cut2: '',
  koodoCuts: '',
  koodoRSmallCuts: '',
  creditCards: CreditCards,
  numberChange: NumberChange,
  plans: Plans,
  simCard: SimCard,
  exclamation: Error,
  check: Check,
  simCardBelow: SIMCardPrinted,
  simCardPrinted: SIMCardBelow,
  circleCheck: CircleCheck,
  chevronBack: ChevronBack,
  circleQuestionMark: circleQuestionMark
}
